import { SET_ITEMS } from "../actions/home.action";


let HomeState = {
    loading: false,
    items: {}
}

export default function homereducer(state = HomeState, action: any) {
    if (action.type === SET_ITEMS) {
        return {
            ...state,
            items: action.items
        }
    }
    return state;
}



