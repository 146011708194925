
import React, { Component } from 'react';
import './QuizRedeem.css';
import { connect } from 'react-redux';
import TemplateTutorialPage from '../TemplateTutorialPage/TemplateTutorialPage';
import banner from '../../../assets/onboard3.png';

class QuizRedeem extends Component<{ index: number, onNext: any, close: any }, {}> {
    componentDidMount() {
    }

    renderTopComponent() {
        return (
            <div className="collectpoints-container">
                <img src={banner} className="collecpoints-banner" />
            </div>
        )
    }
    render() {
        return (
            <TemplateTutorialPage
                index={2} title="Punkte einlösen im Lädele" subtitle="Hol dir für deine Punkte einen Sofortpreis oder ein Hauptpreis-Gewinnlos. Jedes Los erhöht deine Gewinnchance!"
                forwardUrl="/redeempoints"
                backUrl=""
                top={this.renderTopComponent()}
                onNext={() => this.props.onNext()}
                closeModal={()=>{this.props.close()}}
            />
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuizRedeem);