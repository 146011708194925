import React, { Component } from "react";
import Slider from "react-slick";

import EventCard from "../../pages/Events/Events-Card/EventCard";

class EventsCarousel extends Component<{ items: any[], cardstyle?: any, hideLocation?: boolean }, {}> {
    render() {
        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1.2,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
        const self = this;
        return (
            <Slider {...settings}>
                {
                    this.props.items && this.props.items.map(function (item, index) {

                        return (
                            <div key={index} className="shop-item-overlapping-card">
                                <EventCard key={index} big={false} imageFullscreen={true} item={item} style={self.props.cardstyle} hideLocation={self.props.hideLocation} />
                            </div>
                        );
                    })
                }
            </Slider>
        );
    }
}

export default EventsCarousel;