import { getEvents } from "../../services/HttpHelper.service";
import { logout } from "./user.action";

export const LOADING_EVENTS = 'LOADING_EVENTS';
export const loadingEvents = () => {
    return {
        type: LOADING_EVENTS
    }
}

export const EVENTS_LOADED = 'EVENTS_LOADED';
export const eventsLoaded = (events: any) => {
    return {
        type: EVENTS_LOADED,
        events: events
    }
}

export const loadEvents = () => {
    return (dispatch: any) => {
        dispatch(loadingEvents());
        getEvents().then((events) => {
            if (events.data.status ===  "Token is Invalid") {
                dispatch(logout());
            } else {
                dispatch(eventsLoaded(events.data));
            }
        }).catch(() => {});
    }
}
