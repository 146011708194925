import React, { Component } from 'react';
import './App.css';
import { Provider } from 'react-redux'

//Router for navigation
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router' // react-router v4

//pages
import LoginPage from './pages/LoginPage/Login.page';

import configureStore, { history } from './redux/store'
import Home from './pages/Home/HomePage';
import Navbar from './components/navbar/Navbar';
import Events from './pages/Events/Events';
import Shop from './pages/Shop/Shop';
import Profil from './pages/Profil/Profil';
import PersonalSpace from './pages/Profil/PersonalSpacePage/PersonalSpace';
import LeaderBoard from './pages/Profil/LeaderBoard/LeaderBoard';
import Transaktion from './pages/Profil/Transaktion/Transaktion';
import ShopDetail from './pages/Shop/Shop-Detail/ShopDetail';
import ShopDetailBuy from './pages/Shop/Shop-Detail-Buy/Shop-Detail-Buy';
import ShopDetailConfirmation from './pages/Shop/Shop-Detail-Confirmation/Shop-Detail-Confirmation';
import Quiz from './pages/Pfiffikus-Quiz/Quiz';
import Question from './pages/Pfiffikus-Quiz/Question/Question';
import UsernamePage from './pages/LoginPage/Username/Username.page';
import CollectPoints from './pages/OnBoarding/CollectPoints/CollectPoints';
import CodeEingabe from './pages/CodeEingabe/CodeEingabe';
import ShopItemList from './pages/Shop/Shop-Item-List/Shop-Item-List';
import CodeInputDone from './pages/CodeEingabe/CodeInputDone/CodeInputDone';
import EventDetails from './pages/EventDetails/EventDetails';
import TransactionDetail from './pages/Profil/TransactionDetail/TransactionDetail';
import Maps from './pages/Maps/Maps';
import AccountSetup from './pages/Home/AccountSetup/AccountSetup';
import QuizAlreadyAnswered from './pages/QuizAlreadyAnswered/QuizAlreadyAnswered';
import EmailRegistration from './pages/EmailRegistration/EmailRegistration';
import EmailRegistrationDone from './pages/EmailRegistration/EmailRegistrationDone';
import EmailVerification from './pages/EmailRegistration/EmailVerification';
import EmailLogin from './pages/EmailRegistration/EmailLogin';
import FAQ from './pages/FAQ/FAQ';
import InstantPrices from './pages/Profil/InstantPrices/InstantPrices';
import ContestPrices from './pages/Profil/ContestPrices/ContestPrices';
import Datenschutz from './pages/Datenschutz/Datenschutz';
import EventList from './pages/Events/Event-List/Event-List';
import Impressum from './pages/Impressum/Impressum';
import PullRefresh from 'react-pullrefresh';
import { isIOS, isBrowser } from 'react-device-detect';
import EmailForgotPassword from './pages/EmailRegistration/EmailForgotPassword';
import Teilnahmebedingung from './pages/Teilnahmebedingung/Teilnahmebedingung';

const store = configureStore("")


class App extends Component {

  componentDidMount() {
    document.addEventListener('gesturestart', function (event: any) {
      //  event.preventDefault();
    }, false);
  }

  renderRouting() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <div className="app-inner-container">
              <Route path="/home" component={Home} />
              <Route path="/events" component={Events} />
              <Route path="/eventdetails" component={EventDetails} />
              <Route path="/store" component={Shop} />
              <Route path="/storeitem" component={ShopDetail} />
              <Route path="/storeitemconfirmation" component={ShopDetailConfirmation} />
              <Route path="/shopdetailbuy" component={ShopDetailBuy} />
              <Route path="/emailregistration" component={EmailRegistration} />
              <Route path="/emailregistrationdone" component={EmailRegistrationDone} />
              <Route path="/verifyemail" component={EmailVerification} />
              <Route path="/profil" component={Profil} />
              <Route path="/emaillogin" component={EmailLogin} />
              <Route path="/personalspace" component={PersonalSpace} />
              <Route path="/leaderboard" component={LeaderBoard} />
              <Route path="/transaction" component={Transaktion} />
              <Route path="/quiz" component={Quiz} />
              <Route path="/quizalreadyanswered" component={QuizAlreadyAnswered} />
              <Route path="/question" component={Question} />
              <Route path="/username" component={UsernamePage} />
              <Route path="/collectpoints" component={CollectPoints} />
              <Route path="/codeinput" component={CodeEingabe} />
              <Route path="/shopitemlist" component={ShopItemList} />
              <Route path="/codeinputdone" component={CodeInputDone} />
              <Route path="/transactiondetail" component={TransactionDetail} />
              <Route path="/maps" component={Maps} />
              <Route path="/accountsetup" component={AccountSetup} />
              <Route path="/faq" component={FAQ} />
              <Route path="/instantprices" component={InstantPrices} />
              <Route path="/lotteryprices" component={ContestPrices} />
              <Route path="/datenschutz" component={Datenschutz} />
              <Route path="/eventlist" component={EventList} />
              <Route path="/impressum" component={Impressum} />
              <Route path="/forgotpw" component={EmailForgotPassword} />
              <Route path="/teilnahmebedingung" component={Teilnahmebedingung} />
            </div>
          </Switch>
        </ConnectedRouter>
        <CodeEingabe />
        <Navbar></Navbar>
      </Provider>

    )
  }

  render() {
    return (

      <div className="app-main-container">
        {isIOS && <PullRefresh zIndex={9999} onRefresh={() => { window.location.reload() }}>
          {this.renderRouting()}
        </PullRefresh>}
        {!isIOS && <div>
          {this.renderRouting()}
        </div>
        }
      </div>

    );
  }
}


export default App;
