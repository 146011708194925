import React, { Component } from 'react'
import './QuizAlreadyAnswered.css';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import { push } from 'react-router-redux';
import add from '../../assets/profil-add.png';
import { logout, me, isNotTutorial, stopLogin } from '../../redux/actions/user.action';

//import icons
import imgprofil from '../../assets/profil/profil.png';
import imgleaderboard from '../../assets/profil/leaderboard.png';
import imgtransaction from '../../assets/profil/transaction.png';
import imglogout from '../../assets/profil/logout.png';
import AuthGuard from '../../services/UserAuth.service';
import placeholder from '../../assets/profilbild_placeholder.png';
import FullWidthButton from '../../components/full-width-button/Full-Width-Button';
import Moment from 'react-moment';

import questionmark from '../../assets/doted_questionmark.png';

class QuizAlreadyAnswered extends Component<{ home: any, push: any, checkAuth: any, me: any, }, {}> {
    componentWillMount() {
        this.props.checkAuth();
        this.props.me();
        window.scrollTo(0, 0);
    }
    componentDidMount() {
    }
    nextWeekdayDate(date, day_in_week) {
        var ret = new Date(date || new Date());
        ret.setDate(ret.getDate() + (day_in_week - 1 - ret.getDay() + 7) % 7 + 1);
        return ret;
    }

    render() {
        return (
            <div style={{ height: '100%', overflow: 'hidden', paddingTop: 70}}>
                <div style={{ display: 'flex', alignItems: 'center', margin: 20,   flexDirection: 'column', height: '100vh' }}>
                    <img src={questionmark} style={{ width: 58, marginBottom: 25 }} />
                    <div className="agecheck-title">Du hast die Quizfrage schon beantwortet!</div>
                    <div className="agecheck-subtitle" style={{ marginBottom: 30 }}>Am Dienstag, <Moment format={"DD. MMMM"}>{this.nextWeekdayDate(new Date(), 2).toISOString()}</Moment> startet das nächste Piffikus-Quiz.</div>
                    <FullWidthButton label="Okay" backgroundcolor="#ffd42e" fontcolor="black" action={() => { this.props.home() }} uppercase={true} />
                </div>
                <Header withIconAndBack={true} backUrl={'/home'} />


            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
        currentUser: state.user.user
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        checkAuth() {
            AuthGuard(dispatch);
        },
        home() {
            dispatch(push('/home'));
        },
        me() {
            dispatch(me());
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuizAlreadyAnswered);