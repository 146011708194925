import React, { Component } from 'react'
import './LeaderBoard.css';
import { connect } from 'react-redux';

// Dropdown
import Dropdown from 'react-dropdown'
import Header from '../../../components/header/Header';
import LeaderBoardEntry from '../../../components/LeaderBoardEntry/LeaderBoardEntry';
import AuthGuard from '../../../services/UserAuth.service';
import { setLeaderBoard, getLeaderBoard } from '../../../redux/actions/user.action';
import BounceLoader from 'react-spinners/BounceLoader';
import { updateLeaderboard } from '../../../redux/actions/leaderboard.action';
import Loading from '../../../components/loading/Loading';
var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;
const FILTER = ['Gesamtrangliste', 'Rangliste Bezirk', 'Rangliste Gemeinde'];

class LeaderBoard extends Component<{ leaderboard: any, checkAuth: any, getEntries: any, loading: boolean }, { selectedFilter: string, sticky: string }> {

    constructor(props: any) {
        super(props);
        this.state = {
            selectedFilter: FILTER[0],
            sticky: 'none'
        }
    }
    componentWillMount() {
        this.props.checkAuth();
        this.props.getEntries("global");
        window.scrollTo(0, 0);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        if (this.getMyOffset().start > (window.innerHeight - 79) ) {
            this.setState({
                sticky: 'bottom'
            })
        }

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    lastScrollY = 0;
    ticking = false;
    handleScroll = () => {
        this.lastScrollY = window.scrollY;

        if (!this.ticking) {
            window.requestAnimationFrame(() => {
                this.meIsVisble(this.lastScrollY);
                this.ticking = false;
            });

            this.ticking = true;
        }
    };


    loadNewRank(filter: string) {
        if (filter != this.state.selectedFilter) {
            if (filter === FILTER[1]) {
                this.props.getEntries('district');
            } else if (filter === FILTER[2]) {
                this.props.getEntries('local');
            } else {
                this.props.getEntries('global');
            }
        }
    }

    getMyOffset() {
        const position = this.props.leaderboard.user ? this.props.leaderboard.rank : -1;
        const topOffset = 180;
        const itemHeight = 79;
        return { start: itemHeight * position + topOffset - 60, end: itemHeight * position + topOffset };

    }

    meIsVisble(scroll: number) {
        const myOffset = this.getMyOffset();
        if ((innerHeight - 79) + scroll > myOffset.start && (scroll + 79) < myOffset.end) {
            this.setState({ sticky: 'none' })
        } else if ((scroll + 110) > myOffset.start) {
            this.setState({ sticky: 'top' })
        } else {
            this.setState({ sticky: 'bottom' })
        }

    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <div className="leaderboard-space">
                    <div className="leaderboard-dropdown">
                        <Dropdown options={FILTER} onChange={(result) => { this.loadNewRank(result.value); this.setState({ selectedFilter: result.value }) }} value={this.state.selectedFilter} placeholder="" />
                    </div>
                    <div style={{ position: 'relative', overlay: 'auto' }} onScroll={(event) => {}}>
                        {this.props.leaderboard && this.props.leaderboard.top_rankings && this.props.leaderboard.top_rankings.map(function (item, i) {
                            return (
                                <LeaderBoardEntry item={item} rank={i} key={i} />
                            )
                        })}
                        <div style={{ paddingBottom: 150, backgroundColor: 'white' }}></div>
                    </div>
                    {this.props.loading && <div className="leaderboard-overlay"><div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.6)' }}></div> <BounceLoader color={'#007540'} /></div>}
                </div>
                {(this.props.leaderboard && this.props.leaderboard.rank >= 0 && this.state.sticky == 'bottom') && <div style={{ position: 'fixed', bottom: 55, left: 0, right: 0 }}>
                    <div className="desktop-friendly-row">
                        <LeaderBoardEntry rank={(this.props.leaderboard.rank - 1)} isMe={true} item={this.props.leaderboard.user ? this.props.leaderboard.user : {}} />
                    </div>
                </div>}
                {(this.props.leaderboard && this.props.leaderboard.rank >= 0 && this.state.sticky == 'top') && <div style={{ position: 'fixed', top: 70, left: 0, right: 0 }}>
                    <div className="desktop-friendly-row">
                        <LeaderBoardEntry rank={(this.props.leaderboard.rank - 1)} isMe={true} item={this.props.leaderboard.user ? this.props.leaderboard.user : {}} />
                    </div>
                </div>}
                <Loading isBGWhite={true} show={this.props.loading}/>
                <Header title="Rangliste"></Header>
            </div >)
    }
}


const mapStateToProps = (state: any) => {
    return {
        leaderboard: state.user.leaderboard,
        loading: state.user.loading
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        checkAuth() {
            AuthGuard(dispatch)
        },
        getEntries(filter: string) {
            dispatch(getLeaderBoard(filter));
        },
        updateStuff() {
            dispatch(updateLeaderboard())
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderBoard);