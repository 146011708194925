import React, { Component } from 'react'
import './Shop-Item-List.css';
import Header from '../../../components/header/Header';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { push } from 'connected-react-router';
import { TitleHeaderWhite } from '../../../components/title-header-white/TitleHeaderWhite';
import ShopCardFullWidth from '../../../components/shop-card-full-width/ShopCardFullWidth';
import AuthGuard from '../../../services/UserAuth.service';
import ShopCard from '../../../components/shop-card/ShopCard';
import { TitleHeaderGreen } from '../../../components/title-header-green/TitleHeaderGreen';

class ShopItemList extends Component<{ forward: any, type: string, instantPrice: any, ticketPrice: any, checkAuth: any, home: any }, {}> {
    componentWillMount() {
        this.props.checkAuth();
        window.scrollTo(0, 0);
    }
    componentDidMount() {
        window.moveTo(0, 0)
        if (this.props.instantPrice.length <= 0 && this.props.ticketPrice.length <= 0) {
            this.props.home();
        }
    }

    private renderItems() {
        if (this.props.type == "contest") {
            return (
                <div style={{}}>
                    {this.props.ticketPrice.map((item, index) => {
                        return (
                            <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                                <ShopCardFullWidth item={item} key={index} style={{paddingBottom: '65%'}}/>
                            </div>
                        )
                    })}
                </div>
            );
        } else if (this.props.type == "instantprice") {
            return (
                <div className="shop-item-list-instantprice">
                    {this.props.instantPrice.map((item, index) => {
                        return (
                            <div className="shop-card-item-container">
                                <ShopCard item={item} key={index} />
                            </div>
                        )
                    })}
                </div>
            );
        }
    }

    private renderHeader() {
        if (this.props.type == "contest") {
            return (
                <TitleHeaderGreen title={"Lospreis"} subtitle={"Für diese Preise erwirbst du mit deinen Punkten ein Gewinnlos. Je mehr Gewinnlose du hast, desto höher ist deine Gewinnchance. Beachte bitte die Infos zu Laufzeit und Verlosung."} />
            );
        } else if (this.props.type == "instantprice") {
            return (
                <TitleHeaderGreen title={"Sofortpreise"} subtitle={"Deinen Sofortpreis kannst du im Mohren Lädele in Dornbirn abholen. Dazu brauchst du den Abholcode, den du beim Einlösen bekommst. Nimm also dein Handy bitte mit!"} />
            );
        }
    }
    
    render() {
        return (
            <div className="shop-list-container">
                {this.renderHeader()}
                <div style={{ marginTop: 30, paddingBottom: 100 }}>
                    {this.renderItems()}
                </div>

                <Header title="Pfiff-Shop" withIconAndBack={true} backUrl={'/store'}></Header>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        type: state.shop.shoplistType,
        instantPrice: state.shop.instantPrices,
        ticketPrice: state.shop.ticketPrice
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        checkAuth() {
            AuthGuard(dispatch);
        },
        home() {
            dispatch(push('/home'));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopItemList);