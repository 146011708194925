import { Action } from 'redux';
import plzList from '../../assets/PLZDistrict';
import { START_EDIT, STOP_EDIT, SET_PLZ, UPDATE_USER_LOCAL, SET_USER, REMOVE_USER, SET_NAME, SET_EMAIL, SET_PHONENUMBER, START_LOGIN, LOGIN_DONE, CHECK_AGB, SET_AVATAR, ADD_POINTS, USER_WATCHED_TUTORIAL, UPDATE_POINTS, SET_LEADERBOARD, UPDATE_USER_IMAGE, IS_TUTORIAL, IS_NOT_TUTORIAL, SET_ERROR, SET_EMAIL_SIGNUP } from '../actions/user.action';

let localUser = "{}";
if (window.localStorage.getItem('user')) {
    localUser = window.localStorage.getItem('user')!;
}
let UserState = {
    loading: false,
    isInEdit: false,
    user: JSON.parse(localUser),
    token: "",
    agbChecked: false,
    avatar: "",
    points: JSON.parse(localUser).points,
    leaderboard: {},
    isTutorial: false,
    error: "",
    emailForSignup: ""
}

export default function usereducer(state = UserState, action: any) {
    if (action.type === START_EDIT) {
        return Object.assign({}, state, {
            isInEdit: true
        });
    } else if (action.type === SET_EMAIL_SIGNUP) {
        return Object.assign({}, state, {
            emailForSignup: action.email
        })
    } else if (action.type === STOP_EDIT) {
        return Object.assign({}, state, { isInEdit: false })
    } else if (action.type === SET_PLZ) {
        let userObj = state.user;
        userObj['zip'] = action.plz;

        // Resolve district.
        if (action.plz) {
            let plzToCheck = action.plz.split(" / ")[0];
            plzList.forEach(plz => {
                if (plz[0] === plzToCheck) {
                    userObj['bezirk'] = plz[4];
                    userObj['location'] = plz[1];
                }
            });
        }

        Object.assign({}, state.user, { user: userObj })
        return state;
    } else if (action.type == UPDATE_USER_LOCAL) {
        let user = JSON.stringify(action.user);
        if (user) {
            localStorage.removeItem('user');
            localStorage.setItem('user', user);
        }
        return {
            ...state,
            user: action.user,
            points: action.user.points
        }
    } else if (action.type == SET_USER) {
        return Object.assign({}, state, { user: action.user.user, token: action.user.token, points: action.user.user.points })
    } else if (action.type == REMOVE_USER) {
        window.localStorage.removeItem("user");
        return Object.assign({}, state, { user: null })
    } else if (action.type === SET_NAME) {
        let userObj = state.user;
        userObj['name'] = action.name;
        return Object.assign({}, state, { user: userObj });;
    } else if (action.type === SET_EMAIL) {
        let userObj = state.user;
        userObj['email'] = action.email;
        return Object.assign({}, state, { user: userObj });;
    } else if (action.type === SET_PHONENUMBER) {
        let userObj = state.user;
        userObj['phonenumber'] = action.phonenumber;
        return Object.assign({}, state, { user: userObj });;
    } else if (action.type === START_LOGIN) {
        return Object.assign({}, state, { loading: true });;
    } else if (action.type === LOGIN_DONE) {
        return Object.assign({}, state, { loading: false });;
    } else if (action.type === CHECK_AGB) {
        if (state.agbChecked) {
            return Object.assign({}, state, { agbChecked: false });
        } else {
            return Object.assign({}, state, { agbChecked: true });
        }
    } else if (action.type === SET_AVATAR) {
        return Object.assign({}, state, { avatar: action.avatar });
    } else if (action.type === ADD_POINTS) {
        let userObj = state.user;
        userObj['points'] = action.points;
        state.points = userObj['points'];
        Object.assign({}, state.user, { user: userObj });

        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(state.user));
        return state;
    } else if (action.type === USER_WATCHED_TUTORIAL) {
        let userObj = state.user;
        userObj['show_tutorial'] = false;
        

        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(state.user));
        return Object.assign({}, state.user, { user: userObj });;
    } else if (action.type === UPDATE_POINTS) {
        return Object.assign({}, state, { points: action.points });
    } else if (action.type === SET_LEADERBOARD) {
        return {
            ...state,
            leaderboard: action.leaderboard
        }
    } else if (action.type === UPDATE_USER_IMAGE) {
        let userObj = state.user;
        userObj['image'] = action.image;

        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(state.user));
        return Object.assign({}, state.user, { user: userObj });
    } else if (action.type === IS_TUTORIAL) {
        return {
            ...state,
            isTutorial: true
        }
    } else if (action.type === IS_NOT_TUTORIAL) {
        return {
            ...state,
            isTutorial: false
        }
    } else if(action.type === SET_ERROR) {
        return{
            ...state,
            error: action.error
        }
    }
    return state;
}



