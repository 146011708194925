import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

class DesktopFooter extends Component<{ push: any }, {}> {
    componentDidMount() {
    }
    render() {
        return (<div className="footer-links">
            <div className="link" onClick={() => { this.props.push('/faq'); }}>Hilfe &amp; FAQ</div>
            <div className="link" onClick={() => { this.props.push('/teilnahmebedingung'); }}>Teilnahmebedingungen</div>
            <div className="link" onClick={() => { this.props.push('/datenschutz'); }}>Datenschutz</div>
            <div className="link" onClick={() => { this.props.push('/impressum'); }}>Impressum</div>
        </div>);
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        push: (url: any) => {
            dispatch(push(url));
        },
    };
};

export default connect(
    null,
    mapDispatchToProps
)(DesktopFooter);