
import React, { Component } from 'react';
import './OnBoardRanking.css';
import { connect } from 'react-redux';
import TemplateTutorialPage from '../TemplateTutorialPage/TemplateTutorialPage';
import banner from '../../../assets/onboard4.png';

class OnBoardRanking extends Component<{ index: number, onNext: any, close: any }, {}> {
    componentDidMount() {
    }

    renderTopComponent() {
        return (
            <div className="collectpoints-container">
                <img src={banner} className="collecpoints-banner" />
            </div>
        )
    }
    render() {
        return (
            <TemplateTutorialPage
                index={3} title="Die Rangliste" subtitle="Unter Profil > Ranglisten siehst du, wo du im Gesamtranking stehst. Hier zählen alle jemals gesammelten Punkte, nicht dein aktueller Punktestand."
                forwardUrl="/redeempoints"
                backUrl=""
                top={this.renderTopComponent()}
                onNext={() => this.props.onNext()}
                closeModal={()=>{this.props.close()}}
            />
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnBoardRanking);