import React, { Component } from 'react'
import './HomePage.css'
import Header from '../../components/header/Header';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import OnBoarding from '../OnBoarding/OnBoarding';
import CardHuge from '../../components/card-huge/CardHuge';
import backgroundImage from '../../assets/pfiff-code.png';
import AuthGuard from '../../services/UserAuth.service';
import { loadItems } from '../../redux/actions/home.action';
import ShopCardFullWidth from '../../components/shop-card-full-width/ShopCardFullWidth';
import Card from '../../components/card/Card';
import EventCard from '../Events/Events-Card/EventCard';
import Confetti from 'react-dom-confetti';
import { openCodeInput, setCurrentCodeIndex } from '../../redux/actions/codeinput.action';
import { isIOS } from 'react-device-detect';
import DesktopFooter from '../../components/DesktopFooter/DesktopFooter';
declare var grecaptcha: any;

class Home extends Component<{ forward: any, currentUser: any, checkAuth: any, loadItems: any, items: any, openCodeInput: any  }, { conf: boolean }> {
    componentWillMount() {
       this.props.checkAuth();
        this.props.loadItems();
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        grecaptcha.ready(function() {
            grecaptcha.execute('6LdDNqEUAAAAABH82phBBZchoJO0rQXVJM-SXUpk', {action: 'home_screen'});
        });
    }

    constructor(props: any) {
        super(props);
        this.state = {
            conf: false
        }
    }
    render() {
        return (
            <div className="fullscreen">
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <CardHuge title="Pfiff-Punktecode eingeben" subtitle="Codes findest du in jeder gekennzeichneten Pfiff-Flasche! Achte beim Kauf auf das Etikett."
                        onClick={() => { this.props.openCodeInput() }} backgroundImage={backgroundImage} />

                    {this.props.items && this.props.items.event && <EventCard big={false} imageFullscreen={true} item={this.props.items.event} />}
                    {this.props.items && this.props.items.quiz && <Card text={this.props.items.quiz.question} item={this.props.items.instantprice} backgroundColor="#00381f" headerText="Pfiffikus Frage der Woche" type={'quiz'} fontColor="white" onClick={() => {
                        if (this.props.items.quiz.answered) {
                            this.props.forward('/quizalreadyanswered')
                        } else {
                            this.props.forward('/quiz')
                        }

                    }} />}
                    {this.props.items && this.props.items.lokalrunde && <EventCard big={false} imageFullscreen={true} item={this.props.items.lokalrunde} />}
                    {this.props.items && this.props.items.instantprice && <ShopCardFullWidth item={this.props.items.instantprice} />}
                    {this.props.items && this.props.items.contest && <ShopCardFullWidth item={this.props.items.contest} />}
                </div>
                <DesktopFooter></DesktopFooter>
                {this.props.currentUser.show_tutorial && <OnBoarding></OnBoarding>}
                <Confetti active={this.state.conf} config={{
                    angle: 90,
                    spread: 45,
                    startVelocity: 25,
                    elementCount: 150,
                    dragFriction: 0.04,
                    duration: 3000,
                    delay: 0,
                    width: "10px",
                    height: "10px",
                    colors: ["#ffd42e"],
                    style: {
                        width: '100px'
                    }
                }} />
                <Header withIcon={true}></Header>
            </div>
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
        currentUser: state.user.user,
        items: state.home.items
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        forward(path) {
            dispatch(push(path));
        },
        checkAuth() {
            AuthGuard(dispatch);
        },
        loadItems() {
            dispatch(loadItems());
        },
        openCodeInput() {
            dispatch(openCodeInput(true));
            if(!isIOS) {
                dispatch(setCurrentCodeIndex(0));
            }
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);