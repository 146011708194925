import React, { Component } from 'react';
import './TitleHeaderWhite.css';

export class TitleHeaderWhite extends Component<{ title: any, subtitle: any }, {}> {
    render() {
        return (
            <div className="title-header-white">
                <div className="title-header-white-title">{this.props.title}</div>
                <div className="title-header-white-subtitle">{this.props.subtitle}</div>
            </div>
        );
    }
}

