import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { push } from 'connected-react-router';
import './Shop-Detail-Buy.css';

import Header from '../../../components/header/Header';
import { TitleHeaderWhite } from '../../../components/title-header-white/TitleHeaderWhite';
import { buyItem } from '../../../redux/actions/shop.action';
import Loading from '../../../components/loading/Loading';

class ShopDetailBuy extends Component<{ currentPrice: any, forward: any, currentUser: any, points: number, buy: any, loading: boolean, home: any }, {}> {
    componentWillMount() {
        window.scrollTo(0, 0);
        if (!this.props.currentPrice.id) {
            this.props.home();
        }
    }

    renderText() {
        if (this.props.points - this.props.currentPrice.cost >= 0) {
            return (
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className="shop-detail-buy-points-subtitle-green">Neuer Punktestand</div>
                    <div className="shop-detail-buy-points-points-green">{this.props.points - this.props.currentPrice.cost}</div>
                </div>
            )
        } else {
            return (
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className="shop-detail-buy-points-subtitle-green" style={{ color: '#d0021b' }}>Neuer Punktestand</div>
                    <div className="shop-detail-buy-points-points-green" style={{ color: '#d0021b' }}>{this.props.points - this.props.currentPrice.cost}</div>
                </div>
            )
        }
    }
    render() {
        return (
            <div className="shop-detail-buy-container"> {this.props.loading && <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 99, backgroundColor: 'white' }}>loading</div>}
                <div style={{ marginTop: -10 }}><TitleHeaderWhite title="Übersicht" subtitle="Zusammenfassung der Bestellung zur Bestätigung" /></div>
                <div className="shop-detail-buy-item">
                    <img src={this.props.currentPrice.detail_image ? this.props.currentPrice.detail_image : ""} className="shop-detail-buy-item-img" />
                    <div style={{ marginLeft: 23 }}>
                        <div className="shop-detail-buy-item-title">{this.props.currentPrice.name}</div>
                        <div className="shop-detail-buy-item-subtitle">{this.props.currentPrice.description}</div>
                    </div>
                </div>
                <div className="shop-detail-buy-points">
                    <div className="shop-detail-buy-points-title">Punktestand</div>
                    <div className="shop-detail-buy-points-item">
                        <div className="shop-detail-buy-points-subtitle">Aktueller Punktestand</div>
                        <div className="shop-detail-buy-points-points">{this.props.points}</div>
                    </div>
                    <div className="shop-detail-buy-points-item">
                        <div className="shop-detail-buy-points-subtitle">Kosten des Products</div>
                        <div className="shop-detail-buy-points-points">-{this.props.currentPrice.cost}</div>
                    </div>
                    <div style={{ border: 0.5, borderStyle: 'solid', borderColor: 'rgba(0,0,0, 0.12)', marginTop: 15, marginBottom: 15 }}></div>
                    <div className="shop-detail-buy-points-item">
                        {this.renderText()}
                    </div>
                    <div style={{ border:0.5, borderStyle: 'solid', borderColor: '#007540', marginTop: 15 }}></div>
                </div>
                {this.props.points - this.props.currentPrice.cost >= 0 && <div className="shop-detail-buy-points-btn-yellow" onClick={() => { this.props.buy(this.props.currentPrice.id, this.props.currentPrice.type) }}>Jetzt einlösen</div>}
                <div className="shop-detail-buy-points-btn-outlined" onClick={() => { this.props.forward('/store') }}>Abbrechen</div>
                <div style={{ height: 100 }}></div>
                <Loading show={this.props.loading} isBGWhite={true} />
                <Header title="Pfiff-Shop" withIconAndBack={true}></Header>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentPrice: state.shop.currentItem,
        currentUser: state.user.user,
        points: state.user.points,
        loading: state.shop.loading
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        forward(path: string) {
            dispatch(push(path))
        },
        buy(id: any, itemtype: any) {
            dispatch(buyItem(id, itemtype))
        },
        home() {
            dispatch(push('/home'))
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopDetailBuy);