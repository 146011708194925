import { getTransactions } from "../../services/HttpHelper.service";

export const LOAD_TRANSACTION = 'LOAD_TRANSACTION';
export const loadTransactions = () => {
    return {
        type: LOAD_TRANSACTION
    }
}

export const DONE_LOAD_TRANSCATION = 'DONE_LOAD_TRANSCATION';
export const doneLoadingTransactions = () => {
    return {
        type: DONE_LOAD_TRANSCATION
    }
}

export const SET_PREV_DATE = 'SET_PREV_DATE';
export const setPrevDate = (date: string) => {
    return {
        type: SET_PREV_DATE,
        date
    }
}

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const setTransactions = (transactions: any) => {
    return {
        type: SET_TRANSACTIONS,
        transactions
    }
}

export const loadMyTransaction = () => {
    return (dispatch: any) => {
        dispatch(loadTransactions())
        getTransactions().then(result => {
            dispatch(setTransactions(result.data));
            dispatch(doneLoadingTransactions())
        }).catch(err => { dispatch(doneLoadingTransactions()) });
    }
}

