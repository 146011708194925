import React, { Component } from 'react';
import './TextInput.scss';

class TextInput extends Component<{ placeholder: string, onChange?: any, value?: any, type?: string, autoComplete?: string, inValid?: boolean, onEnter?: any, invalid?: boolean, disabled?: boolean }, { opened: boolean }> {
    private _input: any;

    constructor(props: any) {
        super(props);
        this.state = {
            opened: false
        }
    }

    onEnterPressed(event: any) {
        if (event.key == 'Enter') {
            if (this.props.onEnter) {
                this.props.onEnter();
            }
        }
    }
    
    handleAutoFill(e) {};

    render() {
        if (this.props.disabled) {
            return (
                <div className="field-container">
                    <div className="field-group field-group-active">
                        <label className="field-active">
                            <div className="placeholdeText">{this.props.placeholder}</div>
                        </label>
                        <input
                            ref={(ref) => this._input = ref}
                            className="textinput-gray"
                            type={this.props.type ? this.props.type : 'text'}
                            value={this.props.value}
                            onFocus={() => { this.setState({ opened: true }) }}
                            onBlur={() => { this.setState({ opened: false }) }}
                            onChange={(event) => this.props.onChange(event)}
                            disabled={this.props.disabled}
                            spellCheck={false}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="field-container" onClick={() => { this._input.focus(); this.setState({ opened: true }) }} onKeyPress={(event) => { this.onEnterPressed(event) }}>
                    <div className={(this.state.opened || this.props.value.length > 0) ? "field-group field-group-active" : "field-group"}>
                        <label className={(this.state.opened || this.props.value.length > 0) ? "field-active" : "field-inactive"}>
                            <div className={(this.state.opened || !this.props.inValid) ? "placeholdeText" : "placeholdeText-error"}>{this.props.placeholder}</div>
                        </label>
                        <input
                            ref={(ref) => this._input = ref}
                            className={this.props.inValid ? "textinput-error" : "textinput-gray"}
                            type={this.props.type ? this.props.type : 'text'}
                            value={this.props.value}
                            onFocus={() => { this.setState({ opened: true }) }}
                            onBlur={() => { this.setState({ opened: false }) }}
                            onChange={(event) => this.props.onChange(event)}
                            onAnimationStart={this.handleAutoFill}
                            spellCheck={false}
                        />
                    </div>
                </div>
            );
        }
    }
}

export default TextInput;

