import React, { Component } from 'react'
import './TextSlideDown.css'
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import next from '../../assets/next.png';
import down from '../../assets/down.png';
import Linkify from 'react-linkify';

class TextSlideDown extends Component<{ item: any }, { open: boolean }> {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }
    componentDidMount() {
    }
    formatText(text) {
        return text.split('\n').map((item, i) => {
            return <p key={i}>{item}</p>;
        });
    }
    render() {
        return (
            <div className="textslide-container" style={{ backgroundColor: this.state.open ? '#008a4c' : '' }}>
                <div className="textslide-title-container" onClick={() => { this.setState({ open: !this.state.open }) }}>
                    {!this.state.open && <img src={next} className="textslide-title-img-next" />}
                    {this.state.open && <img src={down} className="textslide-title-img-next" />}
                    <div className="textslide-title" >{this.props.item.question}</div>
                </div>

                <SlideDown closed={!this.state.open} className="text-slidedown">
                    <div className="textslide-text">
                    <Linkify>{this.formatText(this.props.item.answere)}</Linkify>
                    </div>
                </SlideDown>

            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        back: () => {

        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TextSlideDown);