import React, { Component } from 'react';
import { connect } from 'react-redux';
import './EmailRegistration.css';

import TextInput from '../../components/TextInput/TextInput';
import headerlogo from '../../assets/mohren-logo.png';
import { emailLogin } from '../../redux/actions/user.action';
import Loading from '../../components/loading/Loading';
import Header from '../../components/header/Header';
import { push } from 'react-router-redux';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

class EmailLogin extends Component<{ login: any, loading: any, navigateTo: any }, {}> {
    private recaptcha: any;
    state = {
        email: "",
        password: ""
    };

    render() {
        return (
            <div className="register-container">

                <div className="content">
                    {
                        this.props.loading ?
                            <Loading />
                            :
                            <div className="register-container-form">
                                <div className="headertitle">Anmelden</div>
                                <TextInput placeholder="Email" type="email" value={this.state.email} onChange={(event: any) => {
                                    this.setState({
                                        email: event.target.value
                                    });
                                }}></TextInput>
                                <TextInput type="password" placeholder="Passwort" value={this.state.password} onChange={(event: any) => {
                                    this.setState({
                                        password: event.target.value
                                    });
                                }} onEnter={() => { this.props.login(this.state.email, this.state.password) }}></TextInput>
                                <div className="register-agb-center">
                                    <div className="link" onClick={()=>{
                                        this.props.navigateTo('/forgotpw')
                                    }}>Passwort vergessen?</div>
                                </div>
                                <div className="primary-button" onClick={() => {
                                    if (this.state.email === "") {
                                        Swal.fire({
                                            text: 'Bitte gib deine Email-Adresse ein!',
                                            type: 'error',
                                            confirmButtonColor: '#ffd42e',
                                            confirmButtonText: 'Ok'
                                        });
                                        return;
                                    }
                                    if (this.state.password === "") {
                                        Swal.fire({
                                            text: 'Bitte gib ein Passwort ein!',
                                            type: 'error',
                                            confirmButtonColor: '#ffd42e',
                                            confirmButtonText: 'Ok'
                                        });
                                        return;
                                    }
                                    //  this.checkCaptcha();
                                    this.props.login(this.state.email, this.state.password);
                                }}>
                                    <div className="primary-button-text">Login</div>
                                </div>
                            </div>
                    }
                </div>
                <Header withIconBackNoPoints={true} />
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.user.loading
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        login: (email: string, password: string) => {
            dispatch(emailLogin(email, password));
        },
        navigateTo(path: string) {
            dispatch(push(path));
        }
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailLogin);