import React, { Component } from 'react'
import './ErrorPageTransactions.css';
import { connect } from 'react-redux';
import FullWidthButton from '../../../components/full-width-button/Full-Width-Button';
import transactions from '../../../assets/transactions.png';
import { push } from 'connected-react-router';
class ErrorPageTransactions extends Component<{ navigateTo: any, heigth?: any }, {}> {
    componentWillMount() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
    }

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className="transaction-error-page" style={{height: this.props.heigth}}>
                <img src={transactions} className="transaction-error-img" />
                <div className="transaction-error-title">Keine Transaktionen vorhanden</div>
                <div className="transaction-error-btn-container"><FullWidthButton backgroundcolor="#ffd42e" fontcolor="black" label="Jetzt Punkte einlösen" uppercase={true} action={() => { this.props.navigateTo('/store') }} /></div>
            </div>
        );
    }
}


const mapStateToProps = (state: any) => {
    return {

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        navigateTo(path: string) {
            dispatch(push(path))
        }

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorPageTransactions);