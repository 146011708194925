import React, { Component } from 'react'
import './Maps.css'
import Header from '../../components/header/Header';
import { connect } from 'react-redux';
import AuthGuard from '../../services/UserAuth.service';
import Modal from 'react-modal';
import GoogleMapReact from 'google-map-react';
import GoogleMapsPin from '../../assets/googlemapspin.png';
import cross from '../../assets/cross.png';
import correctGreen from '../../assets/correct_green.png';

const customStyles = {
    content: {
        backgroundColor: '#007234',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'transparent'
    }
};

const AnyReactComponent = ({ text, lat, lng }) => {
    return (<img src={GoogleMapsPin} style={{ width: 30, objectFit: 'contain', position: 'absolute', top: -30, left: -15 }} />)
};

const ME = ({ lat, lng }) => {
    return (<div style={{ backgroundColor: '#007540', width: 10, height: 10, borderRadius: '50%', position: 'absolute', top: -10, left: -5 }}></div>)
};

const defaultMapOptions = {
    fullscreenControl: false,
};
const DISTANCE_IN_M = 5;

class Maps extends Component<{ close: any, callback: any, isVisible: boolean, currEvent: any, currPosition: any }, {}> {
    constructor(props: any) {
        super(props);
    }


    getDistanceFromLatLonInM(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = this.deg2rad(lon2 - lon1);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d * 1000;
    }

    deg2rad(deg) {
        return deg * (Math.PI / 180)
    }

    /**
     * Main renderer.
     */
    render() {
        let isInRange = (this.getDistanceFromLatLonInM(this.props.currPosition.lat, this.props.currPosition.lng, this.props.currEvent.lat, this.props.currEvent.lng) < this.props.currEvent.radius);
       
        return (
            <Modal
                isOpen={this.props.isVisible}
                style={customStyles}
                contentLabel="Modal"
            ><div style={{
                height: '100vh', width: '100%', display: 'flex',
                justifyContent: 'center', position: 'relative'
            }}>
                    <div style={{ position: "fixed", top: 24, right: 24, backgroundColor: '#d8d8d8', borderRadius: '50%', width: 42, height: 42, zIndex: 199, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                        this.props.close();
                    }}>
                        <img src={cross} style={{ width: 18, height: 18 }} />
                    </div>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyDq46SXlOGo3aHp5wz2Po7iuYDaB8nTkZg" }}
                        defaultCenter={{ lat: this.props.currPosition.lat, lng: this.props.currPosition.lng }}
                        defaultZoom={13}
                        fullscreen={false}
                        noIWantToUseGoogleMapApiInternals
                        defaultOptions={defaultMapOptions}
                        options={{
                            streetViewControl: false,
                            scaleControl: false,
                            mapTypeControl: false,
                            panControl: false,
                            zoomControl: false,
                            rotateControl: false,
                            fullscreenControl: false
                        }}
                    >
                        <AnyReactComponent
                            lat={this.props.currEvent.lat}
                            lng={this.props.currEvent.lng}
                            text="Marker"
                        />
                        <ME
                            lat={this.props.currPosition.lat}
                            lng={this.props.currPosition.lng}
                        />
                    </GoogleMapReact>
                    {!isInRange && <div className="maps-boxed-container"><div className="maps-boxed-background"></div><div className="maps-boxed-text">Du bist zu weit weg!</div></div>}
                    {isInRange && <div className="maps-boxed-container"><img style={{ width: 70, height: 70 }} src={correctGreen} /></div>}
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currEvent: state.codeinput.currEvent,
        currPosition: state.codeinput.currPosition
    };
};

export default connect(
    mapStateToProps,
    null
)(Maps);