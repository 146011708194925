import React, { Component } from 'react'
import './InputComponent.css'
import CodeInputField from './CodeInputField';
import { connect } from 'react-redux';
import { sendCode, setVisibility, clearCode, startSendCode, stopSendCode, startCountPointsAnimation, animateClear, setCurrentCodeIndex, clearCodeError, displayMap, SET_CORDS, resendWithCords, fetchCoordinates } from '../../redux/actions/codeinput.action';
import posed from 'react-pose';
import BounceLoader from 'react-spinners/BounceLoader';
import iconCross from '../../assets/icon-cross-error.png';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

declare var grecaptcha: any;

const Box = posed.div({
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
});
const Box2 = posed.div({
    left: { x: 0 },
    right: { x: -200 }
});

const FROMRIGHT = posed.div({
    fr_left: { x: 0 },
    fr_right: { x: +200 }
})
const FROMRIGHT_OP = posed.div({
    fr_hidden: { opacity: 0 },
    fr_visible: { opacity: 1 }
})

class InputComponent extends Component<{ codeModalOpen: boolean, currAddedPoints: number, fetchCoordinates: any, geolocationEnabled: boolean, showMap: any, resendStop: any, sendWithCode: any, cords: any, resetCodeInput: any, fillinDone: boolean, sendCode: any, code: any, isVisible: boolean, loading: boolean, codeWrong: boolean, mapError: any, resend: boolean }, { isOpen: boolean, fromRight: boolean }> {
    private geolocationInterval: any = null;
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            fromRight: false
        }
    }
    componentDidMount() {
        grecaptcha.ready(function() {
            grecaptcha.execute('6LdDNqEUAAAAABH82phBBZchoJO0rQXVJM-SXUpk', {action: 'code_input'});
        });
    }
    componentWillUnmount() {
        clearInterval(this.geolocationInterval);
    }

    private updateGeolocation() {
        this.geolocationInterval = setInterval(() => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.props.fetchCoordinates(this.props.code, position.coords.latitude, position.coords.longitude);
                }, (error) => {
                    Swal.fire({
                        text: 'Dein Standort konnte nicht ermittelt werden. Bitte überprüfe die Datenschutzeinstellungen deines Browsers und erlaube den Zugriff auf deinen Standort!',
                        type: 'error',
                        confirmButtonColor: '#ffd42e',
                        confirmButtonText: 'Ok'
                    });
                }, { maximumAge: 600000, timeout: 10000 });
            } else {
                Swal.fire({
                    text: 'Dein Browser unterstützt leider keine Standortabfrage.',
                    type: 'error',
                    confirmButtonColor: '#ffd42e',
                    confirmButtonText: 'Ok'
                });
            }
        }, 1000)
    }

 

    render() {
        /*if (this.props.resend) {
            if (this.props.cords && this.props.geolocationEnabled) {
                this.props.sendWithCode(this.props.code, { lat: this.props.cords.latitude, lng: this.props.cords.longitude });
                this.props.resendStop();
            } else {
                this.props.resendStop();
            }
        }*/

        let rowStyle = 'inputcomponent-row';
        if (this.props.mapError == "code_incorrect") {
            rowStyle = 'inputcomponent-row-red';
        } else if (this.props.mapError == "recaptcha_incorrect") {
            rowStyle = 'inputcomponent-row-red';
        } else if (this.props.mapError == "no_error") {
            rowStyle = 'inputcomponent-row-green';
        }
        if (!this.props.codeModalOpen) {
            clearInterval(this.geolocationInterval);
        }

        return (
            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                {this.props.loading && <div style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <BounceLoader color={'#007540'} />
                </div>}
                <div>
                    <div style={{ position: 'relative' }}>
                        <Box2 style={{ zIndex: 300, }}
                            pose={this.state.isOpen ? 'left' : 'right'} >
                            <Box style={{ zIndex: 300 }}
                                pose={this.props.isVisible ? 'visible' : 'hidden'} >
                                <FROMRIGHT style={{ zIndex: 300, }}
                                    pose={this.state.isOpen ? 'fr_left' : 'fr_right'} >
                                    <div className={rowStyle}>
                                        <CodeInputField index={0} />
                                        <CodeInputField index={1} />
                                        <CodeInputField index={2} />
                                    </div>
                                    <div className={rowStyle}>
                                        <CodeInputField index={3} />
                                        <CodeInputField index={4} />
                                        <CodeInputField index={5} />
                                    </div>
                                    <div className={rowStyle}>
                                        <CodeInputField index={6} />
                                        <CodeInputField index={7} />
                                        <CodeInputField index={8} />
                                    </div>
                                </FROMRIGHT>
                                {this.props.currAddedPoints > 0 && <div className="input-component-oval">
                                    <div className="input-component-oval-text">{this.props.currAddedPoints}</div>
                                </div>}
                            </Box>
                        </Box2>
                    </div>
                    {this.props.mapError == 'code_incorrect' && <div className="errormessage-container">
                        <div className="errormessage-container-background"></div>
                        <img src={iconCross} className="errormessage-img" />
                        <div className="errormessage-text">Dieser Code ist ungültig</div>
                    </div>}
                    {this.props.mapError == 'recaptcha_incorrect' && <div className="errormessage-container">
                        <div className="errormessage-container-background"></div>
                        <img src={iconCross} className="errormessage-img" />
                        <div className="errormessage-text">reCaptcha ungültig!</div>
                    </div>}
                    {this.props.mapError == 'already_checked_in' && <div className="errormessage-container">
                        <div className="errormessage-container-background"></div>
                        <img src={iconCross} className="errormessage-img" />
                        <div className="errormessage-text">Dieser Code wurde bereits eingelöst</div>
                    </div>}
                    {this.props.mapError == 'out_of_range' && <div >
                        <div className="errormessage-container">
                            <div className="errormessage-container-background"></div>
                            <img src={iconCross} className="errormessage-img" />
                            <div className="errormessage-text">Überprüfe deinen Standort</div>
                        </div>
                        <div onClick={() => { this.props.showMap(this.props.cords.latitude, this.props.cords.longitude) }} className="error_out_of_range_text">Standort anzeigen</div>
                        <div className="inputcomponent-btn" onClick={() => { this.props.sendCode(this.props.code); this.setState({ isOpen: true }) }}><div className="inputcomponent-btn-text">Code einlösen</div></div>
                    </div>}
                    {!this.props.geolocationEnabled && this.props.mapError == 'location_needed' && <div>
                        <div className="errormessage-container">
                            <div className="errormessage-container-background"></div>
                            <img src={iconCross} className="errormessage-img" />
                            <div className="errormessage-text">Du musst deinen Standort freigeben</div>
                        </div>
                        <div onClick={() => {
                            this.updateGeolocation();
                        }} className="error_out_of_range_text">Standort freigeben</div>
                    </div>}
                    {this.props.fillinDone && this.props.mapError == '' &&
                        <div className="inputcomponent-btn"><div className="inputcomponent-btn-text" onClick={() => {
                            this.props.sendCode(this.props.code, () => {
                            }); this.setState({ isOpen: true })
                        }}>Code einlösen</div></div>}
                    {this.props.fillinDone && this.props.mapError == 'code_incorrect' &&
                        <div className="inputcomponent-btn" onClick={() => { this.props.sendCode(this.props.code); this.setState({ isOpen: true }) }}><div className="inputcomponent-btn-text">Code einlösen</div></div>}
                </div>
            </div >)
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.codeinput.loading,
        fillinDone: state.codeinput.done,
        code: state.codeinput.code,
        isVisible: state.codeinput.isVisible,
        codeWrong: state.codeinput.codeWrong,
        mapError: state.codeinput.mapError,
        resend: state.codeinput.resendWithCords,
        cords: state.codeinput.cords,
        geolocationEnabled: state.codeinput.geolocationEnabled,
        currAddedPoints: state.codeinput.currAddedPoints,
        codeModalOpen: state.codeinput.codeModalOpen,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        sendCode(code: any) {
            dispatch(startSendCode());
            dispatch(sendCode(code));
        },
        sendWithCode(code: any, coords: any) {
            dispatch(startSendCode());
            dispatch(sendCode(code, coords));
        },
        resetCodeInput() {
            dispatch(setCurrentCodeIndex(0));
            dispatch(clearCodeError());
            dispatch(clearCode())
            dispatch(setVisibility(true))
        },
        fetchCoordinates(code: any, lat: any, lng: any) {
            dispatch(fetchCoordinates(code, lat, lng));
        },
        resendStop() {
            dispatch(resendWithCords(false));
        },
        showMap(lat, lng) {
            ////////// dispatch(setCurrPos(lat, lng))
            dispatch(displayMap(true));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InputComponent);