
import React, { Component } from 'react'
import './CodeInputDone.css'
import Header from '../../../components/header/Header';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import logo from '../../../assets/mohren-logo.png'
import bottlecap from '../../../assets/bottlecap.png';
import { push } from 'connected-react-router';
import Confetti from 'react-dom-confetti';
import FullWidthButton from '../../../components/full-width-button/Full-Width-Button';
import { clearCollectedPoints, animatePointInputDone, openCodeInput } from '../../../redux/actions/codeinput.action';
import { animatePoints } from '../../../redux/actions/user.action';

const customStyles = {
    content: {
        backgroundColor: '#007540',
        position: 'absolute',
        top: 30,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: 0,
        borderRadius: 10,
        borderWidth: 0
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#004d2a'
    }
};

class CodeInputDone extends Component<{ startPoints: any, back: any, currentUser: any, collectedPoints: number, animate: boolean, mo }, {}> {

    componentDidMount() {
        if (this.props.collectedPoints == 0) {
            this.props.back();
        }
    }
    render() {
        return (
            <Modal
                isOpen={true}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center' }}><Confetti active={this.props.animate} config={{
                    angle: 90,
                    spread: 45,
                    startVelocity: 25,
                    elementCount: 100,
                    dragFriction: 0.04,
                    duration: 1500,
                    delay: 0,
                    width: "10px",
                    height: "10px",
                    colors: ["#ffd42e"]
                }} /></div>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <div className="codeinput-header">
                        <div style={{ width: 30, marginLeft: 13 }}></div>
                        <img src={logo} className="codinput-header-img" />
                        <div style={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', marginRight: 13 }}>
                            <img src={bottlecap} style={{ position: 'absolute', width: '100%', height: '100%' }} />
                            <div className="header-score" style={{ zIndex: 1 }}>{this.props.currentUser.points}</div>
                        </div>
                    </div>

                    <div className="codeinputdone-container">
                        <div className="codeinputdone-text">Gut gemacht! Du hast in Summe {this.props.collectedPoints} Punkte ergattert.</div>
                        <div className="codeinputdone-points"><div className="codeinputdone-points-text">+{this.props.collectedPoints}</div></div>
                        <div style={{ marginTop: 50, width: '70%' }}>
                            <FullWidthButton label="ALLES KLAR" backgroundcolor="#ffd42e" fontcolor="#1a1a1a" action={this.props.back} />
                        </div>
                    </div>
                </div>

            </Modal >
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
        currentUser: state.user.user,
        collectedPoints: state.codeinput.collectedPoints,
        animate: state.codeinput.animate,
        startPoints: state.codeinput.startPoints
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        back() {
            dispatch(animatePointInputDone())

            setTimeout(() => {
                dispatch(openCodeInput(false));
                dispatch(clearCollectedPoints());
                dispatch(push('/'))
            }, 1500)
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CodeInputDone);