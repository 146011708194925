import React, { Component } from 'react'
import './Card.css'
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import backgroundImage from '../../assets/tmp/verlauf.png';
import tshirt from '../../assets/tmp/bild_feuer.png';
import { setCurrentItem } from '../../redux/actions/shop.action';
import FullWidthButton from '../full-width-button/Full-Width-Button';

import question_mark from '../../assets/question_mark.png'
import questionMark from '../../assets/fragezeichen.png'

class Card extends Component<{ item: any, text: string, headerText: string, type: string, backgroundColor?: string, fontColor?: string, onClick: any }, {}> {
    componentDidMount() {
    }

    renderImage() {
        if (this.props.type === 'quiz') {
            return (
                <img src={question_mark} style={{ width: 18, height: 18 }} />
            )
        } else if (this.props.type === 'quiz') {
            return (
                <img src={question_mark} style={{ width: 18, height: 18 }} />
            )
        }
    }
    render() {
        return (
            <div className="card-container" style={{ backgroundColor: this.props.backgroundColor, color: this.props.fontColor }} onClick={() => this.props.onClick()}>
                <div className="card-header">
                    {this.renderImage()}
                    <div className="card-header-title">{this.props.headerText}</div>
                </div>
                <div className="card-body">
                    <div className="card-body-text">
                        {this.props.text}
                    </div>
                    
                </div>
                <div className="card-body-image">
                        <img src={questionMark} className="card-body-image image"/>
                    </div>
            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {

    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        forward(item: any) {
            dispatch(setCurrentItem(item));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Card);