import React, { Component } from 'react'
import { connect } from 'react-redux';
import { TitleHeaderGreen } from '../../components/title-header-green/TitleHeaderGreen';
import Header from '../../components/header/Header';
import './Quiz.css';
import FullWidthButton from '../../components/full-width-button/Full-Width-Button';
import { push } from 'react-router-redux';
import AuthGuard from '../../services/UserAuth.service';

class Quiz extends Component<{ forward: any, checkAuth: any, question: any, home: any }, {}> {
    componentWillMount() {
        this.props.checkAuth();
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="quiz-container">
                <div style={{ marginLeft: 16, marginRight: 16, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TitleHeaderGreen title="Pffikus-Quiz" subtitle="Beantworte die Frage richtig und hol dir 10 Punkte." />
                    <div style={{ marginTop: 87, width: '80%', display: 'flex', justifyContent: 'center' }} onClick={() => { this.props.forward() }}>
                        <FullWidthButton label="Quiz starten" backgroundcolor="#ffd42e" fontcolor="#1a1a1a" />
                    </div>
                </div>

                <Header withIcon={true} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        question: state.question.currentQuestion,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        forward() {
            dispatch(push('/question'));
        },
        checkAuth() {
            AuthGuard(dispatch)
        },
        home() {
            dispatch(push('/home'))
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Quiz);