import React, { Component } from 'react'
import './Transaktion.css';
import { connect } from 'react-redux';

// Dropdown
import Dropdown from 'react-dropdown'

import Header from '../../../components/header/Header';
import LeaderBoardEntry from '../../../components/LeaderBoardEntry/LeaderBoardEntry';
import TransaktionEntry from '../../../components/TransaktionEntry/TransaktionEntry';
import AuthGuard from '../../../services/UserAuth.service';
import Moment from 'react-moment';
import { loadMyTransaction } from '../../../redux/actions/transaction.action';
import ErrorPageTransactions from '../ErrorPageTransactions/ErrorPageTransactions';
import Loading from '../../../components/loading/Loading';

const FILTER = ['Alle Transaktionen', 'Alle Ausgaben', 'Alle Einnahmen', 'Alle Sofortpreise', 'Alle Gewinnlose'];

class TransactionPage extends Component<{ transactions: any, checkAuth: any, loadTransactions: any, loading: boolean }, { filter: string }> {
    private prevDate: any;
    private filteredArray = new Array();

    componentWillMount() {
        this.props.checkAuth();
        this.props.loadTransactions();
        window.scrollTo(0, 0);
    }

    componentDidMount() {
    }
    constructor(props) {
        super(props);
        this.state = {
            filter: "Alle Transaktionen"
        }
    }
    parseDate(input, format) {
        format = format || 'yyyy-mm-dd'; // default format
        var parts = input.match(/(\d+)/g),
            i = 0, fmt = {};
        // extract date-part indexes from the format
        format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++; });

        return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
    }

    checkDate(date1: string, date2: string) {

        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);

        if (dateObj1.getDate() == dateObj2.getDate()) {
            if (dateObj1.getMonth() == dateObj2.getMonth()) {
                if (dateObj1.getFullYear() == dateObj2.getFullYear()) {
                    return false;
                }
            }
        }
        return true;
    }

    checkYesterday(date1: string, date2: string) {
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);
        if (dateObj1.getDate() + 1 == dateObj2.getDate()) {
            if (dateObj1.getMonth() == dateObj2.getMonth()) {
                if (dateObj1.getFullYear() == dateObj2.getFullYear()) {
                    return false;
                }
            }
        }
        return true;
    }

    private renderHeader(item: any) {
        return (
            <div className="transactions-title-container">
                {!this.checkDate(item.created_at.replace(/-/g, "/"), new Date().toUTCString()) && <div className="transactions-title">Heute</div>}
                {!this.checkYesterday(item.created_at.replace(/-/g, "/"), new Date().toDateString()) && <div className="transactions-title">Gestern</div>}
                {this.checkDate(item.created_at.replace(/-/g, "/"), new Date().toDateString()) && this.checkYesterday(item.created_at.replace(/-/g, "/"), new Date().toDateString()) && <Moment format="DD.MM.YYYY" className="transactions-title">{item.created_at}</Moment>}
            </div>
        )
    }
    private getFilter() {
        let filter;
        if (this.state.filter == 'Alle Transaktionen') {
            filter = (item) => {
                return item
            }
        } else if (this.state.filter == 'Alle Ausgaben') {
            filter = (item) => {
                return item.points < 0
            }
        } else if (this.state.filter == 'Alle Einnahmen') {
            filter = (item) => {
                return item.points >= 0
            }
        } else if (this.state.filter == 'Alle Sofortpreise') {
            filter = (item) => {
                return item.type == 'instantprice'
            }
        } else if (this.state.filter == 'Alle Gewinnlose') {
            filter = (item) => {
                return item.type == 'lotteryticket'
            }
        }
        return filter;
    }
    private renderTransactions() {
        let self = this;
        let lastItem = {
            created_at: ""
        };

        return this.props.transactions.filter(this.getFilter()).map(function (item, index) {
            let showHeader = false;
            if (self.checkDate(item.created_at.replace(/-/g, "/"), lastItem.created_at.replace(/-/g, "/"))) {
                showHeader = true;
            }
            lastItem = item;
            return (
                <div key={index}>
                    {showHeader && self.renderHeader(item)}
                    <TransaktionEntry item={item} index={index + 1} />
                </div>
            )
        })

    }

    filterArray(filter: string) {
        this.setState({ filter: filter })
    }

    render() {

        return (
            <div className="transaction-space">

                {this.props.transactions.length > 0 && <div className="transaction-dropdown">
                    <Dropdown options={FILTER} onChange={(result) => { this.filterArray(result.value) }} value={this.state.filter} placeholder="" />
                </div>}
                <div className="transaction-content">
                    {this.renderTransactions()}
                </div>
                {this.props.transactions.filter(this.getFilter()).length == 0 && <ErrorPageTransactions heigth={'calc(100vh - 282px)'} />}
                <div style={{ marginBottom: 150 }}></div>
                <Loading show={this.props.loading} isBGWhite={true} />
                <Header title="Punktekonto" backUrl="/profil"></Header>
            </div>
        );
    }
}


const mapStateToProps = (state: any) => {
    return {
        transactions: state.transaction.transactions,
        loading: state.transaction.loading
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        checkAuth() {
            AuthGuard(dispatch);
        },
        loadTransactions() {
            dispatch(loadMyTransaction())
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionPage);