import React, { Component } from 'react'
import './CodeInputField.css'
import { connect } from 'react-redux';
import { appendCode, removeCodeSnipped, focusCode, setCurrentCodeIndex } from '../../redux/actions/codeinput.action';
import { isIOS } from 'react-device-detect';


class CodeInputField extends Component<{ done: boolean, value: any, index: number, currActive: number, append: any, removeLastInput: any, setAsActive: any, setFocus: any, codeModalOpen: boolean }, {}> {
    private _input: any;

    componentDidMount() {
        window.addEventListener('resize', this.handleKeyboardAvoiding);
       
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleKeyboardAvoiding);

    }

    handleKeyboardAvoiding = () => {
        const focusedElement: any = document.activeElement;
        if (focusedElement.tagName.toLowerCase() === 'input') {
            focusedElement.scrollIntoView(true, { behavior: 'smooth', block: "center" });
        }
    }

    focus() {
        this.props.setAsActive(this.props.index);
    }

    render() {
        if (this.props.codeModalOpen) {
            if (this.props.value[this.props.index] && this.props.value[this.props.index] != "") {
                if (this.props.done && this.props.currActive - 1 == this.props.index) {
                    return (
                        <div className="codeinputfield-container" onClick={() => { this.focus() }}>
                            <div className="codeinputfield-text">{this.props.value[this.props.index]}</div>
                        </div>)
                } else {
                    return (
                        <div className="codeinputfield-container-filled" onClick={() => { this.focus() }}>
                            <div className="codeinputfield-text-inaktiv">{this.props.value[this.props.index]}</div>
                        </div>)
                }
            } else {

                if (this.props.currActive == this.props.index) {
                    return (
                        <div className="codeinputfield-container" onClick={() => { this.focus() }}>
                            <div className="codeinput-dot-container"><div className="codeinput-dot"></div></div>
                            <input ref={input => {
                                input && input.focus();
                                this._input = input;
                                
                            }
                            } className="codeinputfield-input" onChange={(event) => {
                                if (this.props.index == 8 && this._input) {
                                    this._input.blur();
                                }
                                this.props.append(event.target.value, this.props.index);
                            }} onKeyDown={(event: any) => {
                                if (event.keyCode === 8) {
                                    this.props.removeLastInput();
                                }
                            }} onLoad={()=>{console.log("CALLED")}}/>
                        </div>)
                } else {
                    return (
                        <div className="codeinputfield-container-inactiv" onClick={() => { this.focus() }}>
                            <div className="codeinputfield-input-inaktiv" />
                        </div>)
                }
            }
        } else {
            return (<div></div>)
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        currActive: state.codeinput.current,
        value: state.codeinput.code,
        done: state.codeinput.done,
        codeModalOpen: state.codeinput.codeModalOpen
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        append(code: string, index: number) {
            dispatch(appendCode(code, index));
        },
        removeLastInput() {
            dispatch(removeCodeSnipped());
        },
        setAsActive(index: number) {
            dispatch(focusCode(index));
        },
        setFocus(index: number) {
            dispatch(setCurrentCodeIndex(index));
        }

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CodeInputField);