import { Action } from 'redux';
import { LOAD_TRANSACTION, DONE_LOAD_TRANSCATION, SET_PREV_DATE, SET_TRANSACTIONS } from '../actions/transaction.action';

let LeaderBoardState = {
    loading: false,
    isInEdit: false,
    transactions: [],
    prevDate: ""
}

export default function transactionreducer(state = LeaderBoardState, action: any) {
    if (action.type === LOAD_TRANSACTION) {
        return {
            ...state,
            loading: true
        }
    } else if (action.type === DONE_LOAD_TRANSCATION) {
        return {
            ...state,
            loading: false
        }
    } else if (action.type === SET_PREV_DATE) {
        return {
            ...state,
            prevDate: action.date
        }
    } else if (action.type === SET_TRANSACTIONS) {
        return  {
            ... state,
            transactions: action.transactions
        }
    }
    return state;
}



