import React, { Component } from 'react'
import { TitleHeaderGreen } from '../../components/title-header-green/TitleHeaderGreen';
import Header from '../../components/header/Header';
import { connect } from 'react-redux';
import AuthGuard from '../../services/UserAuth.service';
import { loadEvents } from '../../redux/actions/events.action';
import Moment from 'react-moment';
import './EventDetails.css'
import FullWidthButton from '../../components/full-width-button/Full-Width-Button';
import eventImg from '../../assets/events/event.png';
import infoImg from '../../assets/events/info.png';
import calendarImg from '../../assets/events/calendar.png';
import { push } from 'react-router-redux';
import { Carousel } from 'react-responsive-carousel';
import { openCodeInput } from '../../redux/actions/codeinput.action';
import points from '../../assets/15-Punkte.png';
var momentLib = require('moment');

class EventDetails extends Component<{ checkAuth: any, location: any, push: any, home: any, type: string, event: any, openCodeInput: any }, {}> {

    componentWillMount() {
        this.props.checkAuth();
        window.scrollTo(0, 0);

    }

    componentDidMount() {
        if (!this.props.event.name) {
            this.props.push('/events')
        }
    }

    renderBadge() {
        if (this.props.event.type === "event") {
            return (
                <div className="shop-detail-badge" style={{ backgroundColor: '#ffd42e', marginLeft: 0 }}>
                    <div className="shop-detail-badge-text" style={{ color: '#000000' }}>Pfiff-Event</div>
                </div>
            );
        } else {
            return (
                <div className="shop-detail-badge" style={{ backgroundColor: '#ffd42e', marginLeft: 0 }}>
                    <div className="shop-detail-badge-text" style={{ color: '#000000' }}>Lokalrunde</div>
                </div>
            );
        }
    }

    renderEventDetails() {
        return (
            <div className="event-details-container-wrapper">
                <div className="shop-detail-ticket-detail">
                    <img src={eventImg} className="shop-detail-image" style={{ width: 15, height: 18 }} />
                    <div className="event-detail-text">{this.props.event.subtitle}</div>
                </div>
                <div className="shop-detail-ticket-detail">
                    <img src={infoImg} className="shop-detail-image" style={{ width: 18, height: 18 }} />
                    <div className="shop-detail-text">
                        Dieser Code ist bis zum Ende des Veranstaltungszeitraums einmalig pro Teilnehmer einlösbar
                    </div>
                </div>
            </div>
        );
    }

    renderEventButton() {
        // Sonderpunkte!!!
        if (this.props.event.start) {
            return (<div></div>);
        }

        // Keine!
        let now = momentLib();
        let startDate = momentLib(this.props.event.date_from);
        let isActive = false;
        if (now.isAfter(startDate)) {
            isActive = true;
        }

        return (<div>
            { !isActive && <div className="event-not-yet-started">
                Dieses Event startet am <Moment date={this.props.event.date_from} format={'DD.MM.YYYY'} /> um <Moment date={this.props.event.date_from} format={'HH:mm'} />.
            </div> }
            { isActive && <div className="mb-60" onClick={() => {
                this.props.openCodeInput()
            }}>
                <FullWidthButton backgroundcolor="#ffd42e" fontcolor="#1a1a1a" label="Code eingeben" />
            </div> }
        </div>);
    }

    /**
     * Main render methode
     */
    render() {
        return (
            <div style={{ width: '100%', paddingTop: 70 }}>
                <div className="event-details-container">
                    <img src={points} className="event-details-price" />
                    <div className="event-details-price-img-container">
                        <img src={this.props.event.detail_image} />
                    </div>
                    <div className="event-details-container-content">
                        <div className="event-details-container-title">
                            {this.props.event.name}
                        </div>
                        {this.props.event.type && this.renderBadge()}
                        {this.props.event.subtitle && this.renderEventDetails()}
                        <div className="event-details-container-description">
                            {this.props.event.description && this.props.event.description.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br /></span>
                            })}
                        </div>
                        { this.renderEventButton() }
                    </div>
                </div>
                <Header withIconAndBack={true} backUrl={'/events'} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        event: state.location.currEvent
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        checkAuth() {
            AuthGuard(dispatch);
        },
        loadEvents() {
            dispatch(loadEvents());
        },
        push(url) {
            dispatch(push(url))
        },
        home() {
            dispatch(push('home'));
        },
        openCodeInput() {
            dispatch(openCodeInput(true));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventDetails);