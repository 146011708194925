import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import CountTo from 'react-count-to';
import posed from 'react-pose';
import { push } from 'connected-react-router';
import './Header.css';

import { stopCountPointsAnimation, openCodeInput } from '../../redux/actions/codeinput.action';
import logo from '../../assets/mohren-logo.svg';
import back from '../../assets/back.png';
import shop from '../../assets/desktopicons/shop.png';
import events from '../../assets/desktopicons/events.png';
import profile from '../../assets/desktopicons/profile.png';
import bottlecap from '../../assets/bottlecap.png';
import imgcross from '../../assets/navbar/cross.png';

import imgeventsaktiv from '../../assets/navbar/event-aktiv.png';
import imgstoreaktiv from '../../assets/navbar/store-aktiv.png';
import imgprofilaktiv from '../../assets/navbar/profil-aktiv.png';

const Box = posed.div({
    visible: { scale: 1 },
    hidden: { scale: 1.3 }
});

class Header extends Component<{ goToLeaderboard: any, openCodeInput: any, push: any, currentRoute: string, stopCountAnimation: any, backWithUrl: any, countAnimationPoints: number, countAnimation: boolean, withIconBackNoPoints?: boolean, points: number, title?: string, back: any, backUrl?: any, withIcon?: boolean; withIconAndBack?: boolean, logoOnly?: boolean, withIconAndPoints?: boolean, currentUser: any }, { oldPoints: number, isVisible: boolean }> {
    constructor(props) {
        super(props);
        this.state = {
            oldPoints: 0,
            isVisible: true
        }
    }

    renderCountItem() {
        return (
            <div style={{ width: 36, height: 36, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }} onClick={() => this.props.goToLeaderboard()}>
                <img src={bottlecap} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: -1 }} />
                <CountTo to={this.props.points} speed={1234} onComplete={() => {
                    this.setState({ oldPoints: this.props.points });
                }}>{(value) => { return (<div className="header-score">{value}</div>) }}</CountTo>
            </div>
        );
    }

    renderDesktopHeaderLogoOnly() {
        return (
            <div className="desktop-header">
                <div className="inner-container desktop-header-inner-center">
                    <img src={logo} style={{ height: 26 }} onClick={() => { this.props.push('/home') }} />
                </div>
            </div>
        );
    }

    isEventsRouteActive() {
        if (this.props.currentRoute === '/events') {
            return true;
        }
        if (this.props.currentRoute === '/eventlist') {
            return true;
        }
        if (this.props.currentRoute === '/eventdetails') {
            return true;
        }
        return false;
    }

    isStoreRouteActive() {
        if (this.props.currentRoute === '/store') {
            return true;
        }
        if (this.props.currentRoute === '/shopitemlist') {
            return true;
        }
        if (this.props.currentRoute === '/storeitem') {
            return true;
        }
        return false;
    }

    isProfileRouteActive() {
        if (this.props.currentRoute === '/profil') {
            return true;
        }
        if (this.props.currentRoute === '/personalspace') {
            return true;
        }
        if (this.props.currentRoute === '/instantprices') {
            return true;
        }
        if (this.props.currentRoute === '/lotteryprices') {
            return true;
        }
        if (this.props.currentRoute === '/transaction') {
            return true;
        }
        if (this.props.currentRoute === '/faq') {
            return true;
        }
        if (this.props.currentRoute === '/impressum') {
            return true;
        }
        if (this.props.currentRoute === '/datenschutz') {
            return true;
        }
        return false;
    }

    renderDesktopHeader() {
        return (
            <div className="desktop-header">
                <div className="inner-container">
                    <img src={logo} style={{ height: 26 }} onClick={() => { this.props.push('/home') }} />
                    <div className="nav-items">
                        <div onClick={() => { this.props.push('/events') }} className={"nav-items-item " + (this.isEventsRouteActive() ? 'nav-items-item-active' : '')}>
                            { this.isEventsRouteActive() ? <img src={ imgeventsaktiv } /> : <img src={ events } /> }
                            <div className="nav-items-item-text">Events</div>
                        </div>
                        <div onClick={() => { this.props.push('/store') }} className={"nav-items-item " + (this.isStoreRouteActive() ? 'nav-items-item-active' : '')}>
                            { this.isStoreRouteActive() ? <img src={ imgstoreaktiv } /> : <img src={ shop } /> }
                            <div className="nav-items-item-text">s'Lädele</div>
                        </div>
                        <div onClick={() => { this.props.push('/profil') }} className={"nav-items-item " + (this.isProfileRouteActive() ? 'nav-items-item-active' : '')}>
                            { this.isProfileRouteActive() ? <img src={ imgprofilaktiv } /> : <img src={ profile } /> }
                            <div className="nav-items-item-text">Profil</div>
                        </div>
                        <div onClick={() => { this.props.openCodeInput() }} className="nav-items-item">
                            <div className="header-collect-points">
                                <img src={ imgcross } />
                            </div>
                            <div className="nav-items-item-text">Punkte sammeln</div>
                        </div>
                        { this.renderCountItem() }
                    </div>
                </div>
            </div>
        );
    }

    renderInnerHeader() {
        if (this.props.logoOnly) {
            return (
                <div className="main-header-container">
                    <div className="header-solo header-container" onClick={() => { this.props.push('/home') }}>
                        <img src={logo} style={{ height: 26 }} />
                    </div>
                    { this.renderDesktopHeaderLogoOnly() }
                </div>
            )
        } else if (this.props.withIconAndBack) {
            return (
                <div className="main-header-container">
                    <div className="header-container">
                        <div onClick={() => {
                            if (this.props.backUrl) {
                                this.props.backWithUrl(this.props.backUrl)
                            } else {
                                this.props.back()
                            }

                        }} style={{ width: 30, height: '100%', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><img src={back} style={{ height: 21 }} /></div>
                        <img src={logo} style={{ height: 26 }} />
                        <div style={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }} onClick={() => this.props.goToLeaderboard()}>
                            <img src={bottlecap} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: -1 }} />
                            <div className="header-score">{this.props.points}</div>
                        </div>
                    </div>
                    { this.renderDesktopHeader() }
                </div>
            );
        } else if (this.props.withIcon) {
            return (
                <div className="main-header-container">
                    <div className="header-container">
                        <div style={{width: 30}}></div>
                        <img src={logo} style={{ height: 26 }} />
                        <div style={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }} onClick={() => this.props.goToLeaderboard()}>
                            {this.props.countAnimation && <Box
                                style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                pose={this.state.isVisible ? 'visible' : 'hidden'}>
                                <img src={bottlecap} style={{ width: 30, height: 30 }} />
                            </Box>}
                            {!this.props.countAnimation && <div
                                style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={bottlecap} style={{ width: 30, height: 30 }} />
                            </div>}
                            <div className="header-score" style={{ zIndex: 1 }}>{}</div>
                            <CountTo style={{ zIndex: 1 }} from={this.state.oldPoints} to={this.props.points} speed={1234} onComplete={() => {
                                this.props.stopCountAnimation()
                                this.setState({ oldPoints: this.props.points });
                            }}>{(value) => { return (<div className="header-score" style={{ zIndex: 2 }} >{value}</div>) }}</CountTo>
                        </div>
                    </div>
                    { this.renderDesktopHeader() }
                </div>
            );
        } else if (this.props.withIconBackNoPoints) {
            return (
                <div className="main-header-container">
                    <div className="header-container">
                        <div onClick={() => { this.props.back() }} style={{ width: 30, height: '100%', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><img src={back} style={{ height: 21 }} /></div>
                        {!this.props.title && <img src={logo} style={{ height: 26 }} />}
                        {this.props.title != "" && <div className="header-title">{this.props.title}</div>}
                        <div></div>
                    </div>
                    { this.renderDesktopHeaderLogoOnly() }
                </div>
            )
        } else if (this.props.title) {
            return (
                <div className="main-header-container">
                    <div className="header-container">
                        <div onClick={() => { this.props.back() }} style={{ width: 30, height: '100%', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><img src={back} style={{ height: 21 }} /></div>
                        <div className="header-title">{this.props.title}</div>
                        <div style={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }} onClick={() => this.props.goToLeaderboard()}>
                            <img src={bottlecap} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: -1 }} />
                            <div className="header-score">{this.props.points}</div>
                        </div>
                    </div>
                    { this.renderDesktopHeader() }
                </div>
            )
        } else if (this.props.withIconAndPoints) {
            return (
                <div className="main-header-container">
                    <div className="header-container">
                        <div onClick={() => { this.props.back() }} style={{ width: 30, height: '100%', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><img src={back} style={{ height: 21 }} /></div>
                        <img src={logo} style={{ height: 26 }} />
                        <div style={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }} onClick={() => this.props.goToLeaderboard()}>
                            <img src={bottlecap} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: -1 }} />
                            <CountTo to={this.props.points} speed={1234} onComplete={() => {
                                this.setState({ oldPoints: this.props.points });
                            }}>{(value) => { return (<div className="header-score">{value}</div>) }}</CountTo>
                        </div>
                    </div>
                    { this.renderDesktopHeader() }
                </div>
            )
        } else {
            return (
                <div className="main-header-container">
                    <div className="header-solo header-container">
                        <img src={logo} style={{ height: 26 }} />
                    </div>
                    { this.renderDesktopHeaderLogoOnly() }
                </div>
            )
        }
    }

    render() {
        return (
            <div className="header">
                {this.renderInnerHeader()}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.user.user,
        points: state.user.points,
        countAnimation: state.codeinput.countAmination,
        countAnimationPoints: state.codeinput.countAnimationPoints,
        currentRoute: state.router.location.pathname
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        back: () => {
            dispatch(goBack());
        },
        openCodeInput: () => {
            dispatch(openCodeInput(true));
        },
        backWithUrl(url) {
            dispatch(push(url))
        },
        stopCountAnimation() {
            dispatch(stopCountPointsAnimation())
        },
        goToLeaderboard: () => {
            dispatch(push('/leaderboard'))
        },
        push: (url: any) => {
            dispatch(push(url));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);