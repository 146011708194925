
import React, { Component } from 'react';
import './CollectPoints.css';
import { connect } from 'react-redux';
import TemplateTutorialPage from '../TemplateTutorialPage/TemplateTutorialPage';
import banner from '../../../assets/onboard1.png';

class CollectPoints extends Component<{ index: number, onNext: any, close: any }, {}> {
    renderTopComponent() {
        return (
            <div className="collectpoints-container">
                <img src={banner} className="collecpoints-banner" />
            </div>
        )
    }
    
    render() {
        return (
            <TemplateTutorialPage
                index={0} title="Pfiff-Gewinncodes" subtitle="findest du in den Pfiff-Kronkorken. PET- und Einwegflaschen haben keine Gewinncodes. Achte beim Kauf auf den Hinweis am Etikett."
                forwardUrl="/redeempoints"
                backUrl=""
                top={this.renderTopComponent()}
                onNext={() => { this.props.onNext() }}
                closeModal={() => { this.props.close() }}
            />
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CollectPoints);