import React, { Component } from 'react'
import './Profil.css';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import { push } from 'react-router-redux';
import add from '../../assets/profil-add.png';
import { logout, me, isNotTutorial, stopLogin, uploadImage } from '../../redux/actions/user.action';

//import icons
import imgprofil from '../../assets/profil/profil.png';
import imgleaderboard from '../../assets/leaderboard.png';
import imgtransaction from '../../assets/profil/transaction.png';
import imglogout from '../../assets/profil/logout.png';
import AuthGuard from '../../services/UserAuth.service';
import placeholder from '../../assets/profilbild_placeholder.png';
import shop from '../../assets/icon_shop.png';
import ticket from '../../assets/icon_gewinnlos.png';
import plus from '../../assets/plus-green.png';
import Loading from '../../components/loading/Loading';
declare var grecaptcha: any;

class Profil extends Component<{ currentUser: any, logout: any, push: any, checkAuth: any, me: any, openUpdatePhoto: any, uploadImage: any }, {}> {
    componentWillMount() {
        this.props.checkAuth();
        this.props.me();
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        grecaptcha.ready(function() {
            grecaptcha.execute('6LdDNqEUAAAAABH82phBBZchoJO0rQXVJM-SXUpk', {action: 'shop_screen'});
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {};
    }
    handleImageLoaded(result) {
        
    }

    render() {
        return (
            <div className="profile-container">
                <Header title="Profil" withIcon={true}></Header>
                <div className="profil-header">
                    <div className="personal-space-image-container">
                        <div style={{ position: 'relative' }}>
                            <img src={this.props.currentUser.image ? this.props.currentUser.image : placeholder} className="personal-space-image" style={{ borderRadius: 100 }} onLoad={this.handleImageLoaded.bind(this)} />
                            <div className="upload-container">
                                <img src={plus} className="personal-space-image-btn" onClick={() => { }} />
                                <input type="file" name="pic" accept="image/png,image/x-png,image/jpeg,image/jpg" className="personal-space-input" style={{ position: 'absolute', width: 29, backgroundColor: 'rgba(0,0,0,0)' }} onChange={(result: any) => {
                                    this.getBase64(result.target.files[0], (result) => {
                                        this.props.uploadImage(result);
                                    })
                                }}></input>
                            </div>
                        </div>
                    </div>
                    <div className="profil-header-text-block">
                        <div className="profile-username">{this.props.currentUser.name}</div>
                        <div className="profile-address">{this.props.currentUser.zip}</div>
                    </div>
                </div>
                <div className="profil-links">
                    <div className="profil-spacer" style={{ marginBottom: 20 }}></div>
                    <div className="profil-link" onClick={() => { this.props.push("/personalspace") }}><img src={imgprofil} className="profil-link-icon" style={{ height: 19, width: 15 }} />Persönliche Daten</div>
                    <div className="profil-link" onClick={() => { this.props.push("/leaderboard") }}><img src={imgleaderboard} className="profil-link-icon" style={{ height: 20, width: 18 }} />Ranglisten</div>
                    <div className="profil-link" onClick={() => { this.props.push("/instantprices") }}><img src={shop} className="profil-link-icon" style={{ height: 20, width: 18 }} />Sofortpreise</div>
                    <div className="profil-link" onClick={() => { this.props.push("/lotteryprices") }}><img src={ticket} className="profil-link-icon" style={{ height: 20, width: 18 }} />Meine Gewinnlose</div>
                    <div className="profil-link" onClick={() => { this.props.push("/transaction") }}><img src={imgtransaction} className="profil-link-icon" style={{ height: 15, width: 17 }} />Punktekonto {"\&"} Gewinne</div>
                    <div className="profil-spacer" style={{ marginBottom: 20 }}></div>
                    <div className="profil-link" onClick={() => { this.props.logout(); }}><img src={imglogout} className="profil-link-icon" style={{ height: 17, width: 15 }} />Abmelden</div>
                    <div className="profil-spacer" style={{ marginBottom: 20 }}></div>
                    <div className="profile-link-agb" onClick={() => { this.props.push("/faq") }}>Hilfe {"\&"} FAQ</div>
                    <div className="profile-link-agb" onClick={()=>{this.props.push("/teilnahmebedingung")}}>Teilnahmebedingungen</div>
                    <div className="profile-link-agb" onClick={() => { this.props.push('/datenschutz') }}>Datenschutz</div>
                    <div className="profile-link-agb" onClick={() => { this.props.push('/impressum') }}>Impressum</div>
                </div>
                <div style={{ height: 50 }}></div>
                <Loading />
            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {
        currentUser: state.user.user
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        logout: () => {
            dispatch(logout());
            dispatch(stopLogin());
        },
        push: (url: string) => {
            dispatch(push(url));
        },
        checkAuth() {
            AuthGuard(dispatch);
        },
        me() {
            dispatch(me());
        },
        openUpdatePhoto() {
            dispatch(isNotTutorial());
            dispatch(stopLogin());
            dispatch(push('/imagepicker'));
        },
        uploadImage(image: string) {
            dispatch(uploadImage(image));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profil);