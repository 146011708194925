import React, { Component } from 'react'
import './TransaktionEntry.css'
import { connect } from 'react-redux';
import { string } from 'prop-types';
import Moment from 'react-moment';


//import image icons

import pfiff from '../../assets/icon-pfiff.png';
import event from '../../assets/icon-lokalrunde.png';
import shop from '../../assets/icon-shop.png';
import ticket from '../../assets/icon-ticket.png';
import quiz from '../../assets/icon-quiz.png';
import { setPrevDate } from '../../redux/actions/transaction.action';
import { push } from 'react-router-redux';
import { setCurrentItem, getTicket } from '../../redux/actions/shop.action';
import nextGray from '../../assets/next_gray.png';

class TransacionEntry extends Component<{ item: any, index: number, prevDate: string, setDate: any, openPrice: any }, {}> {
    componentDidMount() {
        this.props.setDate(this.props.item.created_at);
    }
    private chooseImageAvatar() {
        if (this.props.item.type === 'crowncap') {
            return (<img src={pfiff} style={{ height: 32 }} />);
        } else if (this.props.item.type === 'event') {
            return (<img src={event} style={{ width: 21 }} />);
        } else if (this.props.item.type === 'shop') {
            return (<img src={shop} style={{ height: 25 }} />);
        } else if (this.props.item.type === 'quiz') {
            return (<img src={quiz} style={{ height: 25 }} />);
        } else if (this.props.item.type === 'instantprice') {
            return (<img src={shop} style={{ height: 25 }} />);
        } else if (this.props.item.type === 'lotteryticket') {
            return (
                <img src={ticket} style={{ height: 25 }} />);
        } else {
            return (<img src={shop} style={{ height: 25 }} />);
        }

    }

    private getDescription() {
        if (this.props.item.type === 'crowncap') {
            return 'Pfiff - Punkte';
        } else if (this.props.item.type === 'event') {
            return 'Pfiff-Event - Punkte';
        } else if (this.props.item.type === 'lokalrunde') {
            return 'Lokalrunde - Punkte';
        } else if (this.props.item.type === 'shop') {
            return 'Sofortpreis - ' + this.props.item.description;
        } else if (this.props.item.type === 'quiz') {
            return 'Pfiffikus - Quiz';
        } else if (this.props.item.type === 'instantprice') {
            return "Sofortpreis - " + this.props.item.description;
        } else if (this.props.item.type === 'lotteryticket') {
            return "Lospreis - " + this.props.item.description;
        } else if (this.props.item.type === 'moderation') {
            return "Moderation - " + this.props.item.description;
        }
    }
    private renderDate() {
        if (this.props.item.type == 'instantprice') {
            return (
                <div className="transaction-date">
                    <Moment format="D MMM, HH:mm" withTitle>{this.props.item.created_at}</Moment>
                    {(this.props.item.status == 'pickedup') && <div className="instantprice_pickedup">Abgeholt</div>}
                </div>
            );
        } else {
            return (
                <div className="transaction-date">
                    <Moment format="D MMM, HH:mm" withTitle>{this.props.item.created_at}</Moment>
                </div>
            );
        }

    }
    private renderItem() {
        return (
            <div className="transaction-item">
                <div className="transcation-icon">
                    {this.chooseImageAvatar()}
                </div>
                <div className="transcation-description">
                    <div className="transaction-descrtiption-text">{this.getDescription()}</div>
                    {this.renderDate()}
                </div>
                {this.props.item.points > 0 && <div className="transaction-points-positiv">+{this.props.item.points}</div>}
                {this.props.item.points <= 0 && <div className="transaction-points-negativ">{this.props.item.points}</div>}
                {(this.props.item.type === 'lotteryticket' || this.props.item.type === 'instantprice') && <div><img src={nextGray} style={{ width: 15, height: 22, marginLeft: 5 }} /></div>}
                {(this.props.item.type !== 'lotteryticket' && this.props.item.type !== 'instantprice') && <div style={{ width: 15 }}></div>}
            </div>
        )
    }
    render() {
        return (
            <div onClick={() => { this.props.openPrice(this.props.item) }}>
                {this.props.index % 2 != 0 &&
                    <div className="isEven" style={{ display: 'flex' }}>
                        {this.renderItem()}
                    </div>}
                {this.props.index % 2 == 0 && <div style={{ display: 'flex', backgroundColor: '#fafcfb' }}>
                    {this.renderItem()}
                </div>}
            </div>)
    }
}
const mapStateToProps = (state: any) => {
    return {
        prevDate: state.transaction.prevDate
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        setDate(date: string) {
            dispatch(setPrevDate(date));
        },
        openPrice(item) {
            if (item.type == 'instantprice' || item.type === 'shop') {
                dispatch(setCurrentItem(item));
                dispatch(push('/transactiondetail'))
            } else if (item.type == 'lotteryticket') {
                //TODO
                dispatch(getTicket(item.itemid));
            }
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransacionEntry);