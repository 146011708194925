import { Action } from 'redux';
import { LOAD_TRANSACTION, DONE_LOAD_TRANSCATION } from '../actions/transaction.action';
import { EVENTS_LOADED } from '../actions/events.action';
import { SET_EVENT_TYPE, SET_CURR_EVENT } from '../actions/location.action';

let LocationState = {
    loading: false,
    pfiffEvents: [],
    lokalrunde: [],
    specialCodes: [],
    specialEvent: null,
    listType: 'none',
    currEvent: {}
}

export default function locationreducer(state = LocationState, action: any) {
    if (action.type === EVENTS_LOADED) {
        return Object.assign({}, state, {
            lokalrunde: action.events.localrunde,
            pfiffEvents: action.events.events,
            specialEvent: action.events.featured,
            specialCodes: action.events.specialcodes
        })
    } else if(action.type === SET_EVENT_TYPE) {
        return {
            ...state,
            listType: action.eventType
        }
    } else if(action.type === SET_CURR_EVENT) {
      
        return{
            ...state,
            currEvent: action.currEvent
        }
    }

    return state;
}