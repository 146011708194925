import React, { Component } from 'react'
import './TransactionDetail.css';
import Header from '../../../components/header/Header';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { push } from 'connected-react-router';
import { TitleHeaderWhite } from '../../../components/title-header-white/TitleHeaderWhite';
import AuthGuard from '../../../services/UserAuth.service';
import { removeItemCode } from '../../../redux/actions/shop.action';
import Moment from 'react-moment';
import FullWidthButton from '../../../components/full-width-button/Full-Width-Button';
var Barcode = require('react-barcode');
const endDate = new Date("2019-07-14T01:00:00+01:00")
class TransactionDetail extends Component<{ currentPrice: any, forward: any, checkAuth: any, itemcode: any, home: any }, {}> {
    componentWillMount() {
        this.props.checkAuth();
        window.scrollTo(0, 0);
        if (!this.props.currentPrice.id) {
            this.props.home();
        }
    }
    
    generateSubTitle() {
        return (
            <div className="shop-detail-confirmation-subtitle">Sofortpreis</div>
        )
    }

    render() {
        return (
            <div className="shop-detail-confirmation-container">
                <div className="shop-detail-confirmation-title">{this.props.currentPrice.description}</div>
                {this.generateSubTitle()}
                <div className="shop-detail-confirmation-points">
                    <div className="shop-detail-confirmation-points-text">{this.props.currentPrice.points}</div>
                </div>
                <div className="shop-detail-confirmation-cattitle">Datum</div>
                <div className="shop-detail-confirmation-text" style={{ marginTop: 0 }}><Moment format="DD.MMMM YYYY, HH:mm" className="shop-detail-confirmation-text" style={{ marginLeft: 0 ,marginRight: 0 }}>{this.props.currentPrice.created_at}</Moment> Uhr</div>
                <div className="shop-detail-confirmation-cattitle">Abholcode</div>
                <div className="shop-detail-confirmation-text-bold">{this.props.currentPrice.abholcode}</div>
                <div className="shop-detail-confirmation-cattitle">Abholfrist</div>
                <div className="shop-detail-confirmation-text" style={{ marginTop: 0 }}>Mittwoch, 28. August 2019</div>
                <div className="shop-detail-confirmation-text" style={{ marginTop: 44, marginBottom: 32 }}>Die Punkte wurden eingelöst. Sie können den Preis im Mohren-Shop unter dem folgenden Abholcode abholen:</div>
                <div className="barcode-container">
                    <Barcode value={this.props.currentPrice.abholcode} format="CODE128" />
                </div>
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 40, marginBottom: 50 }}><FullWidthButton action={() => {
                    window.print();  
                }} label="Code drucken" backgroundcolor="#ffd42e" fontcolor="#1a1a1a" /></div>
                <div style={{ height: 50 }}></div>
                <Header title="Pfiff-Shop" withIconAndBack={true} backUrl="/transaction"></Header>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentPrice: state.shop.currentItem,
        itemcode: state.shop.itemcode
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        forward() {
            dispatch(removeItemCode())
            dispatch(push('/store'))
        },
        checkAuth() {
            AuthGuard(dispatch);
        },
        home() {
            dispatch(push('/home'));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionDetail);