import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ShopCard.css';

import { setCurrentItem } from '../../redux/actions/shop.action';

class ShopCard extends Component<{ item: any, forward: any, paddingTop?: any }, {}> {
    render() {
        return (
            <div className="shop-card-item" style={{ backgroundColor: 'white', width: '100%' }} onClick={() => { this.props.forward(this.props.item) }}>
                {this.props.item && this.props.item.preview_image && <img src={this.props.item.preview_image} className="shop-card-backgrounditem" />}
                <div className="shop-card-item-title">{this.props.item.name}</div>
                <div className="shop-card-top-right">{this.props.item.cost}</div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        forward(item: any) {
            dispatch(setCurrentItem(item));
        }
    };
};

export default connect(
    null,
    mapDispatchToProps
)(ShopCard);