import React, { Component } from 'react';
import { connect } from 'react-redux';
import './EmailRegistration.css';

import TextInput from '../../components/TextInput/TextInput';
import { emailLogin, forgotPassword } from '../../redux/actions/user.action';
import Loading from '../../components/loading/Loading';
import Header from '../../components/header/Header';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

class EmailForgotPassword extends Component<{ resetPassword: any, loading: any }, {}> {
    private recaptcha: any;
    state = {
        email: "",
    };

    render() {
        return (
            <div className="register-container">

                <div className="content">
                    {
                        this.props.loading ?
                            <Loading />
                            :
                            <div className="register-container-form">
                                <div className="headertitle">Passwort vergessen</div>
                                <TextInput placeholder="Email" type="email" value={this.state.email} onChange={(event: any) => {
                                    this.setState({
                                        email: event.target.value
                                    });
                                }}></TextInput>

                                <div className="primary-button" onClick={() => {
                                    if (this.state.email === "") {
                                        Swal.fire({
                                            text: 'Bitte gib deine Email-Adresse ein!',
                                            type: 'error',
                                            confirmButtonColor: '#ffd42e',
                                            confirmButtonText: 'Ok'
                                        });
                                        return;
                                    }
                                    this.props.resetPassword(this.state.email);
                                }}>
                                    <div className="primary-button-text">Passwort zurücksetzten</div>
                                </div>
                            </div>
                    }
                </div>
                <Header withIconBackNoPoints={true} />
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.user.loading
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        resetPassword(email: string) {
            dispatch(forgotPassword(email));
        }
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailForgotPassword);