import { push } from "react-router-redux";
import { fetchTicketPrices, fetchInstantPrices, buyItems, featuredContest, fetchTicketPrice } from "../../services/HttpHelper.service";
import { updateUserLocal } from "./user.action";

export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const setItem = (item: any) => {
    return {
        type: SET_SELECTED_ITEM,
        item
    }
}

export const LOAD_ITEMS_START = 'LOAD_ITEMS_START';
export const loadItemsStart = () => {
    return {
        type: LOAD_ITEMS_START,
    }
}

export const SET_SHOP_LIST_TYPE = 'SET_SHOP_LIST_TYPE';
export const setShopListType = (itemtype: string) => {
    return {
        type: SET_SHOP_LIST_TYPE,
        itemtype
    }
}

export const LOAD_ITEMS_STOP = 'LOAD_ITEMS_STOP';
export const loadItemsStop = () => {
    return {
        type: LOAD_ITEMS_STOP,
    }
}

export const SET_TICKET_PRICES = 'SET_TICKET_PRICES';
export const setTicketPrices = (items: any) => {
    return {
        type: SET_TICKET_PRICES,
        items
    }
}

export const SET_INSTANT_PRICES = 'SET_INSTANT_PRICES';
export const setInstantPrices = (items: any) => {
    return {
        type: SET_INSTANT_PRICES,
        items
    }
}

export const SET_FEATURED_TICKET = 'SET_FEATURED_TICKET';
export const setFeaturedTicketPrices = (items: any) => {
    return {
        type: SET_FEATURED_TICKET,
        items
    }
}

export const SET_ITEM_CODE = 'SET_ITEM_CODE';
export const setItemCode = (code: string) => {
    return {
        type: SET_ITEM_CODE,
        code
    }
}

export const REMOVE_ITEM_CODE = 'REMOVE_ITEM_CODE';
export const removeItemCode = () => {
    return {
        type: REMOVE_ITEM_CODE
    }
}

export const setCurrentItem = (item: any) => {
    return (dispatch: any) => {
        dispatch(setItem(item));
        dispatch(push('/storeitem'));
    }
}

export const buyItem = (id: any, itemtype: any) => {
    return (dispatch: any) => {
        dispatch(loadItemsStart());
        buyItems(id, itemtype).then((result) => {
            dispatch(updateUserLocal(result.data.user));
            dispatch(setItemCode(result.data.pickupCode))
            dispatch(push('/storeitemconfirmation'));
            dispatch(loadItemsStop());
            dispatch(fetchShopItemsForce());
        }).catch(err => dispatch(loadItemsStop()));
    }
}

export const fetchShopItemsForce = () => {
    return (dispatch: any, getState: any) => {
        fetchTicketPrices().then((result) => {
            dispatch(setTicketPrices(result.data));
            return fetchInstantPrices().then((result) => {
                dispatch(setInstantPrices(result.data));
                return featuredContest().then((result) => {
                    dispatch(setFeaturedTicketPrices(result.data));
                    dispatch(loadItemsStop());
                });
            });
        }).catch(() => {
            dispatch(loadItemsStop());
        });
    }
}

export const fetchShopItems = () => {
    return (dispatch: any, getState: any) => {
        dispatch(loadItemsStart());
        if (getState().shop.instantPrices.length == 0) {
            dispatch(fetchShopItemsForce());
        } else {
            dispatch(loadItemsStop());
        }
    }
}

export const getTicket = (priceId) => {
    return (dispatch: any, getState: any) => {
        dispatch(loadItemsStart());
        return fetchTicketPrice(priceId).then((result) => {
            dispatch(setCurrentItem(result.data));
            dispatch(loadItemsStop());
        });
    }
}