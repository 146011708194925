import React, { Component } from 'react'
import './Full-Width-Button-Deactivated.css'

class FullWidthButtonDeactivated extends Component<{ backgroundcolor: any, fontcolor: any, label: string, uppercase?: boolean }, {}> {
    componentDidMount() {
    }
    render() {
        return (
            <div style={{padding: 20}}>
                <div className="btn-basic-deactivated" style={{ color: this.props.fontcolor }} >
                    <div className="btn-basic-deactivated-text">{this.props.label}</div>
                </div>
            </div>
        )
    }
}

export default FullWidthButtonDeactivated; 