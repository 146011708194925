import React, { Component } from 'react'
import './Username.css';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import logo from '../../assets/mohren-pfiff.png';
import headerlogo from '../../assets/mohren-logo.png';
import Loading from '../../../components/loading/Loading';
import Header from '../../../components/header/Header';
import { TitleHeaderGreen } from '../../../components/title-header-green/TitleHeaderGreen';
import FullWidthButton from '../../../components/full-width-button/Full-Width-Button';
import { checkAGB, setName, updateUser, startLogin, stopEdit, setFirstTimeLogin, stopLogin } from '../../../redux/actions/user.action';


const customStyles = {
    content: {
        backgroundColor: '#007234',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: 0
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'transparent'
    }
};
class LoginPage extends Component<{ user: any, updateUser: any, setUsername: any, agbChecked: boolean, loading: boolean, checkAGB: any, username: any }, {}> {
    componentDidMount() {
    }

    private toggleChecked() {
        this.props.checkAGB();
    }
    render() {
        let username = "";
        if(this.props.username) {
            username = this.props.username;
        }
        return (
            <Modal
                isOpen={true}
                style={customStyles}
                contentLabel="FB-Login-Modal"
            >
                <div className="content">
                    <Header logoOnly={true} />
                    <Loading />
                    <div className="username-container">
                        <TitleHeaderGreen title="Anzeigename" subtitle="Bitte geben Sie den Namen an, der Angezeigt werden soll." />
                        <div className="username-input-container">
                            <input className="username-input" placeholder="Name" value={this.props.username} onChange={(event) => { this.props.setUsername(event.target.value) }} />
                        </div>
                        <div className="username-agb-container">
                            <input
                                name=""
                                type="checkbox"
                                checked={this.props.agbChecked}
                                onChange={this.toggleChecked.bind(this)}
                                style={{ padding: 5, margin: 0 }}
                            />
                            <div className="username-agb-text">Ich stimme den Geschäftsbedingungen und den Gewinnspielbedingungen zu.</div>
                        </div>
                        {this.props.agbChecked && username.length > 0 && <div onClick={() => { this.props.updateUser(this.props.user) }} className="username-btn-container"><FullWidthButton backgroundcolor="#ffd42e" fontcolor="#1a1a1a" label="Fertig" /></div>}
                        {(!this.props.agbChecked ||  username.length == 0) && <div className="username-btn-container"><FullWidthButton backgroundcolor="rgba(255, 255, 255, 0.5)" fontcolor="rgba(26, 26, 26, 0.2)" label="Fertig" /></div>}
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.user.loading,
        agbChecked: state.user.agbChecked,
        username: state.user.user.name,
        user: state.user.user
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        checkAGB() {
            dispatch(checkAGB());
        },
        setUsername(name: string) {
            dispatch(setName(name));
        },
        updateUser(user: any) {
            dispatch(startLogin());
            dispatch(setFirstTimeLogin(user));
            dispatch(stopLogin())
        }
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);