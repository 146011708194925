import React, { Component } from 'react'
import './Login.scss';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import logo from '../../assets/login_background.png';
import pfiffdesktop from '../../assets/pfiffdesktop.png';
import headerlogo from '../../assets/mohren-logo.png';

//Login
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { login, startLogin, stopLogin } from '../../redux/actions/user.action';
import Loading from '../../components/loading/Loading';
import Overlay from '../../components/Overlay/Overlay';
import CheckAge from '../CheckAge/CheckAge';
import login1 from '../../assets/onboard1.png';
import login2 from '../../assets/onboard2.png';
import login3 from '../../assets/onboard3.png';
import stoerer from '../../assets/landing/dasbierlemohrenpfiff.png';
import infoImage from '../../assets/landing/infoimage.jpg';
import infoImageMobile from '../../assets/landing/mohren-hell-k.png';
import mohrenLogo from '../../assets/landing/mohren-logo.90606b4f.png';
import LoginItem from './LoginItem';
import ExifOrientationImg from 'react-exif-orientation-img';

class LoginPage extends Component<{ forward: any, accountSetup: any, closeOverlay: any; loading: boolean, routeEmailePhoneRegistration: any, storeUser: any, forwardHome: any, forwardEmailLogin: any, stopLoading: any }, {}> {
    private recaptcha: any;
    constructor(props: any) {
        super(props);
        this.setState.bind(this);
    }

    componentWillMount() {
        window.scrollTo(0, 0)
        const storedUser: any = window.localStorage.getItem('user');
        if (window.localStorage.getItem('user') && window.localStorage.getItem('token')) {
            if (JSON.parse(storedUser).first_login) {
                this.props.accountSetup();
            } else {
                this.props.forwardHome();
            }

        }
    }

    renderLandingPage() {
        return (<div className="main-login-page">
            <div className="header">
                <a href="/"><img src={mohrenLogo} /></a>
            </div>
            <div className="info-block">
                <img className="info-block-image mobile" src={infoImageMobile} />
                <img className="info-block-image desktop" src={infoImage} />
                <div className="info-block-info">
                    <h2 className="text-header">Mitfeiern. Mitfiebern.</h2>
                    <h3 className="text-body mt-15">Das Pfiff-Punktefieber greift um sich. Jetzt anmelden, Punkte sammeln und viele Preise gewinnen.</h3>
                    <div>
                        <FacebookLogin
                            appId="2477252149183246"
                            autoLoad={false}
                            isMobile={true}
                            disableMobileRedirect={true}
                            fields="name,email,picture,address"
                            textButton="Mit Facebook anmelden"
                            callback={(call: any) => {
                                if (call.email) {
                                    this.props.storeUser(call.accessToken, "fb");
                                } else {
                                    this.props.stopLoading();
                                }
                            }}
                            redirectUri={window.location.href}
                            render={(renderProps) => {
                                return (<button className="btn-facebooklogin" onClick={() => { renderProps.onClick(); }}>Mit Facebook anmelden</button>)
                            }}
                        />

                        <button className="btn-mobileemail" onClick={() => {
                            this.props.routeEmailePhoneRegistration();
                        }}>Mit E-Mail anmelden</button>

                        <div className="btn-emaillogin">
                            Du bist bereits registriert?<br /><div className="link-u" onClick={() => { this.props.forwardEmailLogin() }}>Hier anmelden</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-content">
                <img className="stoerer" src={stoerer} />
                <h1>30 Jahre Pfiff. Das feiern wir.</h1>
                <div className="teaser">
                    Das Bierle feiert einen runden Geburtstag und wir laden euch ein, mitzufeiern! Beim Pfiff Punktefieber
                    sammelst du Punkte, die du gegen Hauptpreis-Gewinnlose oder viele Sofortpreise eintauschen kannst.
                </div>
                <div className="sogehts">
                    Und so einfach gehts:
                </div>
                <div className="item-container">
                    <div className="item-container-info mr-15">
                        <div className="item-container-info-title">Pfiff-Gewinncodes</div>
                        <div className="item-container-info-text">findest du in den Pfiff-Kronkorken. PET- und Einwegflaschen haben
                            keine Gewinncodes. Achte beim Kauf auf den Hinweis am Etikett.</div>
                    </div>
                    <img src={login1} className="item-container-image" />
                </div>
                <div className="item-container item-container-reverted-mobile">
                    <img src={login2} className="item-container-image" />
                    <div className="item-container-info ml-15">
                        <div className="item-container-info-title">Quiz- und Eventpunkte</div>
                        <div className="item-container-info-text">Weitere Punkte kannst du im wöchentlichen Pfiffikus-Quiz, in
                            teilnehmenden Lokalen bei der Lokalrunde und bei Pfiff-Events ergattern.</div>
                    </div>
                </div>
                <div className="item-container">
                    <div className="item-container-info mr-15">
                        <div className="item-container-info-title">Punkte einlösen im Lädele</div>
                        <div className="item-container-info-text">Hol dir für deine Punkte einen Sofortpreis oder ein
                            Hauptpreis-Gewinnlos. Jedes Los erhöht deine Gewinnchance!</div>
                    </div>
                    <img src={login3} className="item-container-image" />
                </div>
                <div className="footer-links footer-links-always">
                    <div className="link" onClick={() => {
                        this.props.forward("/faq");
                    }}>Hilfe {"\&"} FAQ</div>
                    <div className="link" onClick={() => {
                        this.props.forward("/teilnahmebedingung");
                    }}>Teilnahmebedingungen</div>
                    <div className="link" onClick={() => {
                        this.props.forward("/datenschutz");
                    }}>Datenschutz</div>
                    <div className="link" onClick={() => {
                        this.props.forward("/impressum");
                    }}>Impressum</div>
                </div>
            </div>
        </div>);
    }

    render() {
        return this.renderLandingPage();
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.user.loading
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        routeEmailePhoneRegistration: () => {
            dispatch(push('/emailregistration'));
        },
        storeUser: (token: any, autmethod: string) => {
            dispatch(startLogin());
            dispatch(login(token, autmethod));
        },
        forwardHome() {
            dispatch(push('/home'));
        },
        forwardEmailLogin() {
            dispatch(push('/emaillogin'));
        },
        accountSetup() {
            dispatch(push('/accountsetup'));
        },
        closeOverlay() {
            dispatch(stopLogin());
        },
        forward(path: string) {
            dispatch(push(path))
        },
        stopLoading() {
            dispatch(stopLogin());
        }
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);