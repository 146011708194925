import React, { Component } from 'react'
import { connect } from 'react-redux';
import './QuestionHeader.css';
import { setAnswere, solveQuestion, questionDone, loadCurrentQuestion } from '../../../redux/actions/question.action';
import posed from 'react-pose';
import rightImg from '../../../assets/check.png';
import wrongImg from '../../../assets/cross_red.png';
const AnswereBox = posed.div({
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
})

class Answere extends Component<{ question: any, solveQuestion: boolean, answere: number, questionDone: any, correctAnswere: number }, {}> {
    componentWillMount() {

    }
    componentDidMount() {

    }
    renderWrong() {
        return (
            <div className="question-header-answered">
                {!this.props.questionDone && <div style={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', borderRadius: 30, marginBottom: 28 }}> <img src={wrongImg} className="question-header-image" style={{ width: 28, height: 28, display: 'flex', justifyContent: 'center', alignItems: 'center ' }} /></div>}
                <div className="question-header-text">Falsch!</div>
                <div className="question-header-answered-subtext">{this.props.question.answertext}</div>
            </div>
        )
    }
    renderRight() {
        return (
            <div className="question-header-answered">
                {!this.props.questionDone && <img src={rightImg} className="question-header-image" style={{marginBottom: 28}}/>}
                {this.props.questionDone && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 60, height: 60, backgroundColor: 'white', borderRadius: 30, marginBottom: 28}}><div className="points-plus">+10</div></div>}
                <div className="question-header-text">Richtig!</div>
                <div className="question-header-answered-subtext">{this.props.question.answertext}</div>
            </div>
        )
    }
    render() {
        return (
            <div>
                {!this.props.solveQuestion &&
                    <div className="question-header">
                        <div className="question-header-subtitle">Die Frage der Woche:</div>
                        <div className="question-header-title">{this.props.question.question}</div>
                    </div>
                }
                {this.props.solveQuestion &&
                    <AnswereBox className="question-header" pose={this.props.solveQuestion ? 'visible' : 'hidden'}>
                        {
                            this.props.answere == this.props.correctAnswere && this.renderRight()
                        }
                        {
                            this.props.answere != this.props.correctAnswere && this.renderWrong()
                        }
                    </AnswereBox>
                }

            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        question: state.question.currentQuestion,
        solveQuestion: state.question.solveQuestion,
        answere: state.question.answere,
        questionDone: state.question.done,
        correctAnswere: state.question.correctAnswere
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectAnswere(index: number) {
            dispatch(setAnswere(index));
            setTimeout(() => {
                dispatch(solveQuestion())
            }, 500)
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Answere);