import React, { Component } from 'react'
import './Datenschutz.css';
import Header from '../../components/header/Header';
import { connect } from 'react-redux';
import { TitleHeaderGreen } from '../../components/title-header-green/TitleHeaderGreen';
import TextSlideDown from '../../components/TextSlideDown/TextSlideDown';
import dateschutz from './datenschutz.json';
import DesktopFooter from '../../components/DesktopFooter/DesktopFooter';


class Datenschutz extends Component<{}, {loggedin: boolean}> {
    constructor(props) {
        super(props);
        this.state = {
            loggedin: false
        }
    }

    componentWillMount() {
        let token = window.localStorage.getItem('token');
        if(token && token.length > 0 && token != null && token != "null") {
            this.setState({loggedin: true});
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    formatText(text) {
        return text.split('\n').map((item, i) => {
            return <p key={i}>{item}</p>;
        });
    }
    render() {
        return (
            <div style={{paddingTop: 110, backgroundColor: 'rgb(0, 117, 64)'}}>

                <TitleHeaderGreen title="Datenschutz" subtitle="" />
                <div className="faq-title">Datenschutzerklärung nach der DSGVO</div>
                <div className="faq-subtitle">{this.formatText("Wir verarbeiten Ihre personenbezogenen Daten ausschließlich im Rahmen der Bestimmungen der EU Datenschutzgrundverordnung (DSGVO), des österreichischen Datenschutzgesetzes (DSG) sowie dem Telekommunikationsgesetz (TKG).\nHinweis: Zur leichteren Lesbarkeit wird idR der Begriff „Daten“ verwendet, wenngleich personenbezogene Daten gemeint sind. Gesetzesbestimmungen ohne Angabe beziehen sich ausschließlich auf jene der DSGVO, soweit nicht anders angegeben.\nDiese Datenschutzerklärung informiert Sie über die Art, den Umfang und die Zwecke der Erhebung und Verwendung Ihrer personenbezogenen Daten durch uns im Zusammenhang mit Ihrem Besuch und Ihrer Nutzung unserer Website www.mohrenbrauerei.at.")}</div>

                {dateschutz.map((item: any, key) => {
                    return (
                        <div key={key} style={{ padding: 20 }}>
                            <div className="faq-question-title">
                                {item.title}
                            </div>
                            {item.questions ? item.questions.map((item, key) => {
                                return (
                                    <div className="textslide-seperator">
                                        <TextSlideDown item={item} key={key} />
                                    </div>
                                )
                            }) : <div className="textslide-text">{this.formatText(item.description)}</div>}
                            <div className="textslide-seperator"></div>
                        </div>
                    )
                })}
                <DesktopFooter></DesktopFooter>

                {this.state.loggedin && <Header title="Pfiff-Shop" withIconAndBack={true} backUrl={"/profil"}></Header>}
                {!this.state.loggedin && <Header title="Pfiff-Shop" logoOnly={ true }></Header>}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Datenschutz);