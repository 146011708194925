import { addPoints, updateUser, updateUserLocal } from "./user.action";
import { getCurrentQuestion, answereQuestion } from "../../services/HttpHelper.service";
import { startCountPointsAnimation } from "./codeinput.action";

export const SET_ANSWERE = 'SET_ANSWERE';
export const setAnswere = (index: number) => {
    return {
        type: SET_ANSWERE,
        index
    }
}

export const LOAD_QUESTION = 'LOAD_QUESTION';
export const loadAnswere = () => {
    return {
        type: LOAD_QUESTION
    }
}

export const LOAD_QUESTION_DONE = 'LOAD_QUESTION_DONE';
export const loadAnswereDone = () => {
    return {
        type: LOAD_QUESTION_DONE
    }
}

export const SET_QUESTION_CORRECT = 'SET_QUESTION_CORRECT';
export const setCorrect = () => {
    return {
        type: SET_QUESTION_CORRECT
    }
}

export const SOLVE_QUESTION = 'SOLVE_QUESTION';
export const solveQuestion = () => {
    return {
        type: SOLVE_QUESTION
    }
}


export const SET_QUESTION_DONE = 'SET_QUESTION_DONE';
export const questionDone = () => {
    return {
        type: SET_QUESTION_DONE
    }
}

export const CLEAR_QUESTION = 'CLEAR_QUESTION';
export const clearQuestion = () => {
    return {
        type: CLEAR_QUESTION
    }
}

export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const setCurrentQuestion = (question: any) => {
    return {
        type: SET_CURRENT_QUESTION,
        question
    }
}

export const SET_CORRECT_ANSWERE = 'SET_CORRECT_ANSWERE';
export const setCorrectAnswere = (correctAnswere: any) => {
    return {
        type: SET_CORRECT_ANSWERE,
        correctAnswere
    }
}


export const validateAnswere = (index: number) => {
    return (dispatch: any) => {
        dispatch(setAnswere(index));
        answereQuestion(index+1).then(result => {
            dispatch(setCorrectAnswere(result.data.correctAnswer));
            if(result.data.user) {
                dispatch(updateUserLocal(result.data.user));
                dispatch(startCountPointsAnimation())
            }
            dispatch(solveQuestion());
            setTimeout(()=> {
                dispatch(questionDone())
            }, 2000);
        }).catch((err) => {});
        //   
    }
}

export const loadCurrentQuestion = () => {
    return (dispatch: any) => {
        dispatch(loadAnswere());
        getCurrentQuestion().then((result) => {
            dispatch(setCurrentQuestion(result.data));
            dispatch(loadAnswereDone());
        })
    }
}
