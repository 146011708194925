import axios from 'axios';

let baseURL = "https://mohren.local:8890";
let lukiP = "http://192.168.6.105:8000"
const remoteURL = "https://mohren-punktefieber.herokuapp.com";

baseURL = remoteURL;

enum LOGINTYPE {
    FACEBOOK_LOGIN, EMAIL, PHONENUMBER
}

export default class HttpHelper {
    public loginToServer(type: LOGINTYPE, token: string) {
        const body = {
            token: token
        }
        return axios.post(baseURL + "/api/auth", body);
    }
}

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
};

export function loginToServer(token: string, authmethod: string) {
    const body = {
        token: token,
        authmethode: authmethod
    }
    return axios.post(baseURL + "/api/auth", body, config);
}

export function emailLoginToServer(email: string, password: string) {
    const body = {
        email: email,
        password: password
    }
    return axios.post(baseURL + "/api/login", body, config);
}

export function emailVerifyToServer(token: string) {
    const body = {
        token: token
    }
    return axios.post(baseURL + "/api/verify", body, config);
}

export function emailRegisterToServer(email: string, password: string, enable_ranking: boolean) {
    const body = {
        email: email,
        password: password,
        enable_ranking: enable_ranking
    }
    return axios.post(baseURL + "/api/register", body, config);
}

export function forgotMyPassword(email: string) {
    const body = {
        email: email
    }
    return axios.post(baseURL + "/api/forgot", body, config);
}


export function httpUpdateUser(user: any) {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.put(baseURL + "/api/users", user, config);
}

export function updateUserImage(image: string) {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    const body = {
        file: image
    }
    return axios.post(baseURL + "/api/imageupload", body, config);
}


export function redeemCode(code: string, gaToken: any) {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.post(baseURL + "/api/code", {
        code: code,
        token: gaToken
    }, config);
}

export function redeemCodeWithLatLng(code: string, location: any, gaToken: any) {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.post(baseURL + "/api/code", {
        code: code,
        lat: location.lat,
        lng: location.lng,
        token: gaToken
    }, config);
}

export function featuredContest() {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.get(baseURL + "/api/contest/shop/featured", config);
}

export function fetchTicketPrices() {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.get(baseURL + "/api/contests", config);
}
export function fetchTicketPrice(id) {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.get(baseURL + "/api/contests/" + id, config);
}

export function fetchInstantPrices() {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.get(baseURL + "/api/instantprices", config);
}

export function buyItems(id: number, type: string) {
    const token = window.localStorage.getItem('token');
    let body = {
        itemType: type,
        itemId: id
    }
    config.headers['Authorization'] = "Bearer " + token;
    return axios.post(baseURL + "/api/shop/buy", body, config);
}

export function answereQuestion(answere: number) {
    const token = window.localStorage.getItem('token');
    let body = {
        answer: '' + answere
    }
    config.headers['Authorization'] = "Bearer " + token;
    return axios.post(baseURL + "/api/quiz/current/answer", body, config);
}

export function getCurrentQuestion() {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.get(baseURL + "/api/quiz/current", config);
}

export function getLeaderBoardByFilter(filter: string) {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.get(baseURL + "/api/users/me/ranking?type=" + filter, config);
}

export function getMe() {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.get(baseURL + "/api/users/me", config);
}

export function getEvents() {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.get(baseURL + "/api/events", config);
}

export function getTransactions() {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.get(baseURL + "/api/users/me/transactions", config);
}
export function getHome() {
    const token = window.localStorage.getItem('token');
    config.headers['Authorization'] = "Bearer " + token;
    return axios.get(baseURL + "/api/home", config);
}
