import { Action } from 'redux';
import { LOAD_LEADERBOARD, DONE_LEADERBOARD } from '../actions/leaderboard.action';
import { TRIGGER_NAVBAR } from '../actions/naviagation.action';

let NavigationState = {
    loading: false,
    isInEdit: false,
    open: false
}

export default function navigationreducer(state = NavigationState, action: any) {
    if(action.type === TRIGGER_NAVBAR) {
        return {
            ... state,
            open: !open
        }
    } 
    return state;
}



