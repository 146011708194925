import React, { Component } from 'react'
import './PersonalSpace.css';
import { connect } from 'react-redux';
import Header from '../../../components/header/Header';
import Select from 'react-select';
import TextInput from '../../../components/TextInput/TextInput';
import plus from '../../../assets/plus-green.png';
import Checkbox from 'rc-checkbox';
import { startEdit, stopEdit, setPLZ, updateUserLocal, setName, setPhonenumber, setEmail, updateUser, isTutorial, uploadImageOnly, stopLogin } from '../../../redux/actions/user.action';
import Modal from 'react-modal';
// Dropdown
import Dropdown from 'react-dropdown'
import './dropdownstyle.css'

import defaultPLZ from '../../../assets/PLZ';
import AuthGuard from '../../../services/UserAuth.service';
import placeholder from '../../../assets/profilbild_placeholder.png';
import { push } from 'react-router-redux';
import Loading from '../../../components/loading/Loading';

class PersonalSpace extends Component<{ uploadImage: any, checkAuth: any, updateUser: any, currentUser: any, startEdit: any, stopEdit: any, saveUser: any, isEditing: boolean, setPLZ: any, setEmail: any, setUsername: any, setPhoneNumber: any, openUpdatePhoto: any }, { img: string, fname: string, lname: string, username: string, location: string, enable_ranking: boolean }> {
    private inputRef;

    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: "",
            username: "",
            location: "",
            img: "",
            enable_ranking: false
        }
    }

    componentWillMount() {
        this.props.checkAuth();
    }

    componentDidMount() {
        this.setState({
            fname: this.props.currentUser.fname?this.props.currentUser.fname:"",
            lname: this.props.currentUser.lname?this.props.currentUser.lname:"",
            username: this.props.currentUser.name,
            location: this.props.currentUser.zip,
            img: this.props.currentUser.image,
            enable_ranking: this.props.currentUser.enable_ranking
        })
    }

    checkEmail() {
        if ((this.props.currentUser.email && this.props.currentUser.email.length <= 0) || !this.validateEmail(this.props.currentUser.email)) {
            return true
        }

        return true;
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    getCity(value) {
        return defaultPLZ.filter(
            function (data) { return data.value == value }
        );
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {};
    }

    render() {
        let plz = "Noch nichts angegeben";
        if (this.props.currentUser.zip && this.props.currentUser.zip != '') {
            plz = this.props.currentUser.zip;
        }
        return (

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="personal-space">
                    <div className="profile-title">Profil vervollständigen</div>
                    <div className="personal-space-image-container">
                        <div style={{ position: 'relative' }}>
                        <img  src={this.props.currentUser.image ? this.props.currentUser.image : placeholder} className="personal-space-image" style={{ borderRadius: 100 }} />
                            <div className="upload-container">
                                <img src={plus} className="personal-space-image-btn" onClick={() => { }} />
                                <input type="file" name="pic" accept="image/png,image/x-png,image/jpeg,image/jpg" className="personal-space-input" style={{ position: 'absolute', width: 29, backgroundColor: 'rgba(0,0,0,0)' }} onChange={(result: any) => {
                                    this.getBase64(result.target.files[0], (result) => {
                                        this.props.uploadImage(result);

                                    })
                                }}></input>
                            </div>
                        </div>
                    </div>
                    <div className="personal-space-input-container">
                        <div style={{ display: 'flex' }}>
                            <TextInput placeholder="Vorname" value={this.state.fname} onChange={(event: any) => {
                                this.setState({
                                    fname: event.target.value
                                });
                            }}></TextInput>
                            <div style={{ width: 10 }}></div>
                            <TextInput placeholder="Nachname" value={this.state.lname} onChange={(event: any) => {
                                this.setState({
                                    lname: event.target.value
                                });
                            }}></TextInput>
                        </div>

                        <TextInput placeholder="Anzeigename (nicht mehr änderbar)" value={this.props.currentUser.name} onChange={(event: any) => {

                        }} disabled={true}></TextInput>
                        <TextInput placeholder="E-Mail-Adresse" value={this.props.currentUser.email ? this.props.currentUser.email : ""} onChange={(event: any) => {

                        }} disabled={true}></TextInput>
                        <Select
                            value={this.getCity(this.props.currentUser.zip)[0]}
                            onChange={(result) => { this.props.setPLZ(result.value), this.setState({ location: result.value }) }}
                            options={defaultPLZ}
                            isSearchable={true}
                            isMulti={false}
                            placeholder={'Ort wählen'}
                            className="register-control"
                            noOptionsMessage={() => {
                                return (
                                    <div>Kein Treffer</div>
                                )
                            }}
                            styles={
                                {
                                    option: (provided, state) => ({
                                        ...provided,
                                    }),
                                    control: () => ({
                                        // none of react-select's styles are passed to <Control />
                                        display: 'flex',
                                        flexDirection: 'row',
                                        height: 50,
                                        color: 'white',
                                        fontSize: 18
                                    }),
                                    noOptionsMessage: (provided, state) => ({
                                        ...provided
                                    }),
                                    singleValue: (provided, state) => ({
                                        color: 'white'
                                    }),
                                    dropdownIndicator: (provided, state) => ({
                                        color: 'white',
                                        paddingLeft: 10,
                                        paddingRight: 10
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: 'white'
                                    }),
                                }
                            }
                        />

                        <div className="register-agb">
                            <label>
                                <Checkbox
                                    checked={ this.state.enable_ranking }
                                    name="agb-checkbox"
                                    onChange={(e: any) => {
                                        this.setState({
                                            enable_ranking: e.target.checked
                                        });
                                    }}
                                /> Ich stimme zu, das mein Name bzw. Anzeigename in der öffentlichen Rangliste angezeigt wird.
                            </label>
                        </div>

                        {this.checkEmail() && (this.state.lname.length > 0 && this.state.fname.length > 0 && this.state.lname.length > 0 && this.state.username.length > 0 && this.state.location.length > 0) && <div className="personal-space-btn-edit" onClick={() => { this.props.updateUser(this.props.currentUser, this.state.fname, this.state.lname, this.state.username, this.state.enable_ranking) }}>
                            <div className="personal-space-btn-edit-text">Speichern</div>
                        </div>}
                        {(!this.checkEmail() || !(this.state.lname.length > 0 && this.state.fname.length > 0 && this.state.lname.length > 0 && this.state.username.length > 0 && this.state.location.length > 0)) && <div className="personal-space-btn-edit-disabled">
                            <div className="personal-space-btn-edit-text">Speichern</div>
                        </div>}
                    </div>
                    <div style={{ height: 100 }}></div>
                </div>
                <Loading />
                <Header title="Dein Profil" withIconAndBack={true} backUrl="/profil"></Header>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        currentUser: state.user.user,
        username: state.user.user.name,
        email: state.user.user.email,
        plz: state.user.user.PLZ,
        phone: state.user.user.phonenumber,
        isEditing: state.user.isInEdit
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        startEdit() {
            dispatch(startEdit());
        },
        stopEdit() {
            dispatch(stopEdit());
        },
        setPLZ(plz: string) {
            dispatch(setPLZ(plz))
        },
        updateUser(user: any, fname: string, lname: string, username: string, enable_ranking: boolean) {
            user = Object.assign({}, user, { name: username, fname: fname, lname: lname, enable_ranking: enable_ranking });

            dispatch(updateUser(user, false));
            dispatch(stopLogin());
            dispatch(push('/profil'))
        },
        setUsername(name: string) {
            dispatch(setName(name));
        },
        setPhoneNumber(number: string) {
            dispatch(setPhonenumber(number));
        },
        setEmail(email: string) {
            dispatch(setEmail((email)));
        },
        checkAuth() {
            AuthGuard(dispatch);
        },
        uploadImage(image: string) {
            dispatch(uploadImageOnly(image));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalSpace);