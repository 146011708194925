import React, { Component } from 'react'
import './Shop.css';
import Header from '../../components/header/Header';
import { connect } from 'react-redux';
import ShopCard from '../../components/shop-card/ShopCard';
import ShopCardFullWidth from '../../components/shop-card-full-width/ShopCardFullWidth';
import { fetchShopItems, setShopListType, setItem, loadItemsStop } from '../../redux/actions/shop.action';
import { push } from 'connected-react-router';
import AuthGuard from '../../services/UserAuth.service';
import Loading from '../../components/loading/Loading';
import TicketPriceCarousel from '../../components/TicketPriceCarousel/TicketPriceCarousel';
import InstantPriceCarousel from '../../components/InstantPriceCarousel/InstantPriceCarousel';
import DesktopFooter from '../../components/DesktopFooter/DesktopFooter';
declare var grecaptcha: any;

class Shop extends Component<{ instantprices: any, featuredTicketPrice: any, ticketPrice: any, fetchShopData: any, loading: boolean, forwardToShopList: any, checkAuth: any }, {}> {
    componentWillMount() {
        this.props.fetchShopData();
        this.props.checkAuth();
        window.scrollTo(0, 0);

    }

    componentDidMount() {
        grecaptcha.ready(function() {
            grecaptcha.execute('6LdDNqEUAAAAABH82phBBZchoJO0rQXVJM-SXUpk', {action: 'shop_screen'});
        });
    }

    render() {
        return (
            <div className="shop-container">
                <div className="shop-title-container">
                    <div className="shop-title">s’Lädele</div>
                    <div className="shop-subtitle">Löse deine Punkte hier gegen</div><div className="shop-subtitle">Sofort- und Lospreise ein.</div>
                </div>
                <div style={{ margin: 15 }}>
                    {this.props.featuredTicketPrice && this.props.featuredTicketPrice.id && <ShopCardFullWidth item={this.props.featuredTicketPrice} style={{ paddingBottom: '65%' }} />}
                </div>

                <div className="shop-divider"></div>

                <div>
                    <div style={{ marginLeft: 18 }}>
                        <div className="shop-section-title">Sofortpreise</div>
                        <div className="shop-section-subtitle" onClick={() => { this.props.forwardToShopList('instantprice') }}>Alle anzeigen {">"}</div>
                    </div>
                </div>
                <div className="shop-slider-container">
                    <InstantPriceCarousel items={this.props.instantprices}></InstantPriceCarousel>
                </div>

                <div className="shop-divider"></div>

                <div style={{ marginLeft: 18 }}>
                    <div className="shop-section-title">Lospreise</div>
                    <div className="shop-section-subtitle" onClick={() => { this.props.forwardToShopList('contest') }}>Alle anzeigen {">"}</div>
                </div>
                <div className="shop-slider-container">
                    <TicketPriceCarousel items={this.props.ticketPrice.slice(0, 3)} cardStyle={{paddingBottom: '65%'}}></TicketPriceCarousel>
                </div>
                <DesktopFooter></DesktopFooter>

                <Header title="Pfiff-Shop" withIcon={true}></Header>
                <Loading show={this.props.loading} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        instantprices: state.shop.instantPrices,
        featuredTicketPrice: state.shop.featuredTicketPrice,
        ticketPrice: state.shop.ticketPrice,
        loading: state.shop.loading
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchShopData() {
            dispatch(fetchShopItems());

        },
        forwardToShopList(type: string) {
            dispatch(setShopListType(type))
            dispatch(loadItemsStop())
            dispatch(push('/shopitemlist'));
        },
        checkAuth() {
            AuthGuard(dispatch);
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Shop);