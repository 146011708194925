import React, { Component } from 'react'
import './AccountSetup.css';
import { connect } from 'react-redux';
import Header from '../../../components/header/Header';
import Modal from 'react-modal';
import plus from '../../../assets/plus-green.png';
import { startEdit, stopEdit, setPLZ, setName, setPhonenumber, setEmail, updateUser, isNotTutorial, isTutorial, uploadImageOnly } from '../../../redux/actions/user.action';
import iconCross from '../../../assets/icon-cross-error.png';
import Select from 'react-select';

import defaultPLZ from '../../../assets/PLZ';
import AuthGuard from '../../../services/UserAuth.service';
import { push } from 'connected-react-router';

import placeholder from '../../../assets/imageplaceholder.png';
import TextInput from '../../../components/TextInput/TextInput';
import { string } from 'prop-types';
import PLZDistrict from '../../../assets/PLZDistrict';
import { loadAnswere } from '../../../redux/actions/question.action';
import Loading from '../../../components/loading/Loading';

const customStyles = {
    content: {
        backgroundColor: '#007540',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: 0
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'transparent'
    }
};

class AccountSetup extends Component<{ error: any, uploadImage: any, checkAuth: any, updateUser: any, currentUser: any, startEdit: any, stopEdit: any, saveUser: any, isEditing: boolean, setPLZ: any, setEmail: any, setUsername: any, setPhoneNumber: any, openUpdatePhoto: any }, { img: string, fname: string, lname: string, username: string, location: any }> {
    private inputRef;
    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: "",
            username: "",
            location: null,
            img: ""
        }
    }
    componentWillMount() {
        this.props.checkAuth();
    }

    componentDidMount() {
    }

    checkEmail() {
        if ((this.props.currentUser.email && this.props.currentUser.email.length <= 0) || !this.validateEmail(this.props.currentUser.email)) {
            return true
        }

        return true;
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    getCity(value) {
        return defaultPLZ.filter(
            function (data) { return data.value == value }
        );
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {};
    }

    render() {
        let plz = "Noch nichts angegeben";
        if (this.props.currentUser.zip && this.props.currentUser.zip != '') {
            plz = this.props.currentUser.zip;
        }

        return (
            <Modal
                isOpen={true}
                style={customStyles}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>

                    <div className="personal-space">
                        <div className="profile-title">Profil vervollständigen</div>
                        <div className="personal-space-image-container">
                            <div style={{ position: 'relative' }}>
                                <img src={this.props.currentUser.image ? this.props.currentUser.image : placeholder} className="personal-space-image" style={{ borderRadius: 100 }} />
                                <div className="upload-container">
                                    <img src={plus} className="personal-space-image-btn" onClick={() => { }} />
                                    <input type="file" name="pic" accept="image/png,image/x-png,image/jpeg,image/jpg" className="personal-space-input" style={{ position: 'absolute', width: 29, backgroundColor: 'rgba(0,0,0,0)' }} onChange={(result: any) => {
                                        this.getBase64(result.target.files[0], (result) => {
                                            this.props.uploadImage(result);
                                        })
                                    }}></input>
                                </div>
                            </div>
                        </div>
                        <div className="personal-space-input-container">
                            <div style={{ display: 'flex' }}>
                                <TextInput placeholder="Vorname" value={this.state.fname} onChange={(event: any) => {
                                    this.setState({
                                        fname: event.target.value
                                    });
                                }}></TextInput>
                                <div style={{ width: 10 }}></div>
                                <TextInput placeholder="Nachname" value={this.state.lname} onChange={(event: any) => {
                                    this.setState({
                                        lname: event.target.value
                                    });
                                }}></TextInput>
                            </div>
                            <TextInput placeholder="Anzeigename (nicht mehr änderbar)" value={this.state.username} onChange={(event: any) => {
                                this.setState({
                                    username: event.target.value
                                });
                            }}></TextInput>
                            <Select
                                value={ this.state.location }
                                onChange={(result) => {
                                    this.setState({ location: result });
                                }}
                                options={defaultPLZ}
                                isSearchable={true}
                                isMulti={false}
                                placeholder={'Ort wählen'}
                                className="register-control"
                                noOptionsMessage={() => {
                                    return (
                                        <div>Kein Treffer</div>
                                    )
                                }}
                                styles={
                                    {
                                        option: (provided, state) => ({
                                            ...provided,
                                        }),
                                        control: () => ({
                                            display: 'flex',
                                            flexDirection: 'row',
                                            height: 50,
                                            color: 'white',
                                            fontSize: 18
                                        }),
                                        noOptionsMessage: (provided, state) => ({
                                            ...provided
                                        }),
                                        singleValue: (provided, state) => ({
                                            color: 'white'
                                        }),
                                        dropdownIndicator: (provided, state) => ({
                                            color: 'white',
                                            paddingLeft: 10,
                                            paddingRight: 10
                                        }),
                                        input: (provided, state) => ({
                                            ...provided,
                                            color: 'white'
                                        }),
                                    }
                                }
                            />
                             {this.props.error && <div className="errormessage-container">
                                <div className="errormessage-container-background"></div>
                                <img src={iconCross} className="errormessage-img" />
                                <div className="errormessage-text">Bitte gib einen anderen Anzeigename an. Dieser ist bereits vergeben.</div>
                            </div>}
                            {this.checkEmail() && (this.state.lname.length > 0 && this.state.fname.length > 0 && this.state.lname.length > 0 && this.state.username.length > 0 && this.state.location != null) && <div className="personal-space-btn-edit" onClick={() => { this.props.updateUser(this.props.currentUser, this.state.lname, this.state.fname, this.state.username, this.state.location.value) }}>
                                <div className="personal-space-btn-edit-text">Jetzt geht´s los</div>
                            </div>}
                            {(!this.checkEmail() || !(this.state.lname.length > 0 && this.state.fname.length > 0 && this.state.lname.length > 0 && this.state.username.length > 0 && this.state.location != null)) && <div className="personal-space-btn-edit-disabled">
                                <div className="personal-space-btn-edit-text">Jetzt geht´s los</div>
                            </div>}
                        </div>

                    </div>
                    <Loading />
                    <Header logoOnly={true} />
                </div>
            </Modal>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        currentUser: state.user.user,
        username: state.user.user.name,
        email: state.user.user.email,
        plz: state.user.user.PLZ,
        phone: state.user.user.phonenumber,
        isEditing: state.user.isInEdit,
        error: state.user.error
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        startEdit() {
            dispatch(startEdit());
        },
        stopEdit() {
            dispatch(stopEdit());
        },
        setPLZ(plz: string) {
            dispatch(setPLZ(plz))
        },
        updateUser(user: any, fname: string, lname: string, username: string, location: string) {
            user.zip = location;
            user.first_login = false;
            user.name = username;
            user = Object.assign({}, user, { fname: fname, lname: lname })
            dispatch(updateUser(user, true));
        },
        setUsername(name: string) {
            dispatch(setName(name));
        },
        setPhoneNumber(number: string) {
            dispatch(setPhonenumber(number));
        },
        setEmail(email: string) {
            dispatch(setEmail((email)));
        },
        checkAuth() {
            AuthGuard(dispatch);
        },
        openUpdatePhoto() {
            dispatch(isTutorial());
            dispatch(push('/imagepicker'));
        },
        uploadImage(image: string) {
            dispatch(uploadImageOnly(image));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountSetup);