import React, { Component } from 'react'
import './CodeEingabe.css'
import Header from '../../components/header/Header';
import Modal from 'react-modal';
import logo from '../../assets/mohren-logo.png'
import InputComponent from '../../components/InputComponent/InputComponent';
import keydown, { Keys } from 'react-keydown';
import { removeCodeSnipped, setStartPoints, stopCountPointsAnimation, displayMap, setCords, setMapError, mapLocationEnabled, clearCode, setCurrentCodeIndex, clearCollectedPoints, openCodeInput } from '../../redux/actions/codeinput.action';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import bottlecap from '../../assets/bottlecap.png';
import AuthGuard from '../../services/UserAuth.service';
import CountTo from 'react-count-to';
import posed from 'react-pose';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { isMobile } from 'react-device-detect';
import Maps from '../Maps/Maps';
const { backspace } = Keys;

const customStyles = {
    content: {
        backgroundColor: '#007540',
        position: 'absolute',
        top: 30,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: 0,
        borderRadius: 10,
        borderWidth: 0
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#004d2a'
    }
};
const Box = posed.div({
    visible: { scale: 1 },
    hidden: { scale: 1.3 }
});



class CodeEingabe extends Component<{ codeModalOpen: boolean, isOpen: boolean, geolocationEnabled: boolean, setMapError: any, stopCountAnimation: any, setCoords: any, setStartPoints: any, checkAuth: any, home: any, removeLastInput: any, back: any, currentUser: any, points: number, collectedPoints: number, countAmination: boolean, mapOpen: boolean, openMap: any, closeMap: any }, { isVisible: boolean, oldPoints: number }> {
    private rodalStyle = {
        maxWidth: '500px',
        width: '100%',
        heigt: '100%',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        margin: 'auto',
        padding: 0,
        top: 'unset',
        bottom: 0,
        height: '97%',
        background: isMobile ? '#007540' : 'transparent',
        boxShadow: 'none',
       
    }

    private maps: any;

    @keydown(backspace) // or specify `which` code directly, in this case 13
    submit(event) {
        if (event.code == 'Backspace') {
            this.props.removeLastInput();
        }
    }

    componentWillMount() {
        this.props.checkAuth();
    }

    constructor(props) {
        super(props);
        this.state = {
            isVisible: true,
            oldPoints: 0
        }
    }

    componentDidMount() {
        this.props.setStartPoints(this.props.currentUser.points);
    }

    renderTHISSHIT() {
        setTimeout(()=>{
           
        }, 500)
    }
    

    render() {
        return (
            <Rodal visible={this.props.codeModalOpen} animation={'slideUp'} duration={500} customStyles={this.rodalStyle} className='slideup-modal' showMask={false} showCloseButton={false} onClose={() => { }}>
                {this.props.codeModalOpen &&<div style={{ display: 'flex', justifyContent: 'center', height: isMobile ? 'unset' : '100%', alignItems: 'center' }}>
                    <div className="code-input-helper">
                        <div className="codeinput-header">
                            <div style={{
                                width: '100%', display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <div className="codinput-header-done" onClick={() => {
                                    this.props.setMapError("");
                                    if (this.props.collectedPoints > 0) {
                                        this.props.back();
                                    } else {
                                        this.props.home();
                                    }
                                }}>Fertig</div>
                                <img src={logo} className="codinput-header-img" />
                                <div style={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                    {this.props.countAmination && <Box
                                        style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        pose={this.state.isVisible ? 'visible' : 'hidden'}>
                                        <img src={bottlecap} style={{ width: 30, height: 30 }} />
                                    </Box>}
                                    {!this.props.countAmination && <div
                                        style={{ position: 'absolute', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={bottlecap} style={{ width: 30, height: 30 }} />
                                    </div>}
                                    <div className="header-score" style={{ zIndex: 1 }}>{}</div>
                                    <CountTo style={{ zIndex: 1 }} from={this.state.oldPoints} to={this.props.points ? this.props.points : 0} speed={1234} onComplete={() => {
                                        this.props.stopCountAnimation();
                                        this.setState({ oldPoints: this.props.points });
                                    }}>{(value) => { return (<div className="header-score" style={{ zIndex: 2 }}>{value}</div>) }}</CountTo>
                                </div>
                            </div>
                        </div>
                        <div className="codeinput-center">
                            <div className="codeinput-center-title">
                                Gewinncode eingeben:
                            </div>
                            <div>
                             <InputComponent/>
                            </div>
                        </div>
                    </div>
                    <Maps close={this.props.closeMap} callback={() => { }} isVisible={this.props.mapOpen} ref={(ref) => this.maps = ref} />
                </div>}
            </Rodal>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentUser: state.user.user,
        points: state.user.user ? state.user.user.points : 0,
        collectedPoints: state.codeinput.collectedPoints,
        countAmination: state.codeinput.countAmination,
        mapOpen: state.codeinput.mapOpen,
        geolocationEnabled: state.codeinput.geolocationEnabled,
        isOpen: state.codeinput.isVisible,
        codeModalOpen: state.codeinput.codeModalOpen
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setStartPoints(points: number) {
            dispatch(setStartPoints(points))
        },
        removeLastInput() {
            dispatch(removeCodeSnipped());
        },
        back() {
            dispatch(push('/codeinputdone'));
            dispatch(clearCode())
            dispatch(setCurrentCodeIndex(-1));
        },
        home() {
            dispatch(clearCode())
            dispatch(setCurrentCodeIndex(-1));
            dispatch(openCodeInput(false))
        },
        checkAuth() {
            AuthGuard(dispatch);
        },
        stopCountAnimation() {
            dispatch(stopCountPointsAnimation())
        },
        closeMap() {
            dispatch(displayMap(false));
        },
        openMap() {
            dispatch(displayMap(true));
        },
        setCoords(cords) {
            dispatch(setCords(cords))
        },
        setMapError(error) {
            dispatch(setMapError(error));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CodeEingabe);