import React, { Component } from 'react'
import './Teilnahmebedingung.css';
import Header from '../../components/header/Header';
import { connect } from 'react-redux';
import { TitleHeaderGreen } from '../../components/title-header-green/TitleHeaderGreen';
import TextSlideDown from '../../components/TextSlideDown/TextSlideDown';
import dateschutz from './teilnahmebedingung.json';
import DesktopFooter from '../../components/DesktopFooter/DesktopFooter';


class Teilnahmebedingung extends Component<{}, { loggedin: boolean }> {
    constructor(props) {
        super(props);
        this.state = {
            loggedin: false
        }
    }

    componentWillMount() {
        let token = window.localStorage.getItem('token');
        if (token && token.length > 0 && token != null && token != "null") {
            this.setState({ loggedin: true });
        }
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    formatText(text) {
        return text.split('\n').map((item, i) => {
            return <p key={i}>{item}</p>;
        });
    }
    render() {
        return (
            <div style={{ paddingTop: 110, backgroundColor: 'rgb(0, 117, 64)' }}>

                <TitleHeaderGreen title={"NUTZUNGS- UND TEILNAHME-"+ '\n'+"BEDINGUNGEN"} subtitle="" />
                {dateschutz.map((item: any, key) => {
                    return (
                        <div key={key} style={{ padding: 20 }}>
                            <div className="faq-question-title">
                                {item.title}
                            </div>
                            {item.questions ? item.questions.map((item, key) => {
                                return (
                                    <div className="textslide-seperator">
                                        <TextSlideDown item={item} key={key} />
                                    </div>
                                )
                            }) : <div className="textslide-text">{this.formatText(item.description)}</div>}
                            <div className="textslide-seperator"></div>
                        </div>
                    )
                })}
                <DesktopFooter></DesktopFooter>

                {this.state.loggedin && <Header title="Pfiff-Shop" withIconAndBack={true} backUrl={"/profil"}></Header>}
                {!this.state.loggedin && <Header title="Pfiff-Shop" logoOnly={true}></Header>}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Teilnahmebedingung);