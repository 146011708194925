import React, { Component } from 'react'
import './FAQ.css';
import Header from '../../components/header/Header';
import { connect } from 'react-redux';
import ShopCard from '../../components/shop-card/ShopCard';
import ShopCardFullWidth from '../../components/shop-card-full-width/ShopCardFullWidth';
import { fetchShopItems, setShopListType, setItem, loadItemsStop } from '../../redux/actions/shop.action';
import { push } from 'connected-react-router';
import AuthGuard from '../../services/UserAuth.service';
import Loading from '../../components/loading/Loading';
import { TitleHeaderGreen } from '../../components/title-header-green/TitleHeaderGreen';
import TextSlideDown from '../../components/TextSlideDown/TextSlideDown';
import DesktopFooter from '../../components/DesktopFooter/DesktopFooter';


const faq = [
    {
        "category": "Allgemeine Fragen",
        "questions": [
            {
                "question": "Was ist das Pfiff Punktefieber?",
                "answere": "Mit dem Pfiff-Punktefieber möchten wir dich für deine Treue belohnen. Auf jeder Innenseite der Pfiff-Kronkorken findest du einen 9-stelligen Code. Der Code kann nach erfolgreicher Registrierung auf www.mohren.app eingelöst und Punkte gesammelt werden. Die gesammelten Punkte können gegen viele Sofortpreise und Lose für Monatsgewinne und den Hauptpreis eingelöst werden. Eine weitere Möglichkeit Punkte zu sammeln bieten die weiteren Features auf der App. Lokalrunde, Mohren Events und Kooperationen mit Medienpartnern ermöglichen weiteres sammeln von Pfiff Punkten. Jeder Code wird automatisch in mindestens einen Punkt umgewandelt, der für Prämien und Gewinnspiele eingelöst werden kann. Jeder Code kann nur einmal eingelöst werden und verliert danach automatisch seinen Wert."
            },
            {
                "question": "Wie kann ich beim Pfiff Punktefieber teilnehmen?",
                "answere": "Du hast dein vollendetes 16. Lebensjahr erreicht? Wohnst in Österreich? Und möchtest, dass wir deine Treue belohnen?  Dann kannst du dich auf www.mohren.app registrieren. Alle Daten werden vertraulich und mit größter Sorgfalt behandelt (siehe Datenschutzhinweis). Nach Anmeldung erhältst du eine E-Mail / SMS mit einem Link zur Bestätigung deiner Registrierung."
            },
            {
                "question": "Wie kann ich Punkte sammeln?",
                "answere": "Du kannst den 9-stelligen Code auf der Innenseite des Kronenkorkens unter www.mohren.app eingeben. Der Code wird in mindestens einen Punkt umgewandelt und deinem persönlichen Konto gutgeschrieben. Die Codes befinden sich auf der Innenseite der Kronenkorken des Mohren Pfiffs. Weiteres gibt es die Mohren-Lokalrunde, Mohren Events und weitere Medienpartner, bei denen du Punkte sammeln kannst. Über den genauen Ablauf wirst du über die App informiert."
            },
            {
                "question": "Wo finde ich die Codes?",
                "answere": "Die Codes befinden sich auf der Innenseite der Kronenkorken der Pfiff-Glasflaschen. In den Lokalen der Lokalrunde, Mohren Events und in verschiedenen Medien. (Diese Punkte werden über die APP bekannt gegeben.) Werden weitere Produkte für das Pfiff-Punktefieber freigegeben, informieren wir dich umgehend über Social Media, sowie über die App oder Website."
            },
            {
                "question": "Wofür kann ich meine Punkte einlösen?",
                "answere": "Du kannst deine gesammelten Punkte gegen attraktive Prämien und zeitlich sowie mengenmäßig begrenzte Sonderaktionen, wie zum Beispiel Gewinnspiele oder Sonder-Prämien, einlösen. Die Prämien sind in folgende 3 Kategorien gegliedert: 1.) Sofortpreise 2.) Lose für Monatspreise 3.) Lose für Hauptpreis. Wie viele Punkte du für das Einlösen einer Prämie benötigst, findest du neben der Prämie im Shop auf www.mohren.app. Jede Prämie ist verfügbar, solange der Vorrat reicht. Danach lassen wir uns eine neue Prämie für dich einfallen. Weiters bieten wir exklusive Tickets für verschiedenste Veranstaltungen in limitierter Form an oder Gewinnlose für die Monats bzw. Hauptpreise. Sofortpreise kannst du bei uns im Lädele mit deinem Abholcode abholen."
            },
            {
                "question": "Wie viele Punkte kann ich maximal sammeln?",
                "answere": "Die Punkteanzahl, die du sammeln kannst ist nicht beschränkt. Es gibt mehrere Möglichkeiten Punkte zu sammeln: Die Punkte können einerseits über die Pfiff-Kronkorken, welche mit einem 9-stelligen Code ausgestattet sind, gesammelt werden. Andererseits kann man auch Punkte über diverse Aktionen sammeln: über das Ausfüllen des Pfiff-Quiz, Codes in den Medien oder Besuch der Lokalrunde."
            },
            {
                "question": "Wie lange sind meine Punkte gültig?",
                "answere": "Die gesammelten Punkte verfallen am 29. September 2019. Sie bleiben ab der Eingabe auf deinem Konto gespeichert, sofern du sie nicht für Prämien oder sonstige Aktionen einlöst."
            },
            {
                "question": "An wen kann ich mich bei Fragen wenden?",
                "answere": "Bei Fragen kontaktiere unseren Kundenservice per Mai an punktefieber@mohrenbrauerei.at - Von Montag bis Donnerstag, von 08.00 bis 17.00 Uhr und Freitag, von 08.00 bis 12.00 Uhr."
            },
            {
                "question": "Wie erfahre ich wann es neue Prämien oder Aktionen gibt?",
                "answere": "Gerne informieren wir dich regelmäßig per www.mohren.app über Neuheiten, neue Prämien, Gewinnspiele und Aktionen."
            },
            {
                "question": "Welche Mohren-Produkte sind mit Codes ausgestattet?",
                "answere": "Das Mohren Pfiff. Nur in der Glasflasche."
            },
            {
                "question": "Wie hoch ist der Wert je Code?",
                "answere": "Jeder Code hat mindestens den Gegenwert von 1 Punkt. Höchstmögliche Punktezahl pro Kronkorken sind 5 Punkte. Die Punkte werden zufällig vergeben."
            }
        ]
    },
    {
        "category": "Mitgliedskonto",
        "questions": [
            {
                "question": "Wie kann ich mit meinem Mitgliedskonto Punkte einlösen?",
                "answere": "Du kannst über die www.mohren.app deine Punkte im Prämienshop einlösen. Alle deine ausgewählten Artikel werden im Warenkorb angezeigt."
            },
            {
                "question": "Wie erfahre ich meinen aktuellen Punktestand?",
                "answere": "Dein aktueller Punktestand wird dir nach Anmeldung immer rechts oben im Kronenkorken-Symbol angezeigt. Weiteres findest du unter Mein Profil alle Informationen rund um deine Punkte."
            },
            {
                "question": "Wo kann ich meine persönlichen Daten einsehen/aktualisieren?",
                "answere": "Deine persönlichen Daten kannst du im Menüpunkt „Mein Profil“ einsehen und bearbeiten."
            },
            {
                "question": "Wie werden meine persönlichen Daten behandelt?",
                "answere": "Deine Daten werden von uns vertraulich behandelt und nicht an Dritte weitergegeben. Alle Infos findest du in den Datenschutzbestimmungen."
            },
            {
                "question": "Wie kann ich meine Teilnahme beim Pfiff-Punktefieber beenden?",
                "answere": "Deine Teilnahme beim Pfiff-Punktefieber kann jederzeit beendet werden. Unter dem Menüpunkt Mein Profil kannst du dein Mitgliedskonto löschen. Mit der Löschung deines Kontos verfallen alle gesammelten Punkte unwiderruflich."
            },
            {
                "question": "Ist eine Kündigung meiner Teilnahme durch Mohren möglich?",
                "answere": "Ja, Mohren kann, bei Vorliegen eines wichtigen Grundes, z. B. bei Missbrauch oder Täuschungsversuch, die Teilnahme fristlos und mit sofortiger Wirkung kündigen.  Die Punkte, die in diesem Zeitrahmen gesammelt werden, kannst du selbstverständlich einlösen."
            }
        ]
    },
    {
        "category": "Einlösen der Prämien",
        "questions": [
            {
                "question": "Wie erhalte ich meine Prämien?",
                "answere": "Ein Versand der Prämien ist aus logistischen Gründen nicht möglich. Du kannst die Sofortprämien bei uns im Lädele abholen. Dazu benötigst du einen Abholcode."
            },
            {
                "question": "Wie erhalte ich meinen Abholcode?",
                "answere": "Beim einlösen der Punkte auf www.mohren.app bekommst du beim letzten Schritt der Bestellung einen Code / Strichcode. Diesen kannst du entweder ausdrucken oder digital auf dein Handy laden."
            },
            {
                "question": "Wie funktioniert das 14-tägige Widerrufsrecht?",
                "answere": "Du hast die Möglichkeit, deine Ware binnen 14 Tage zurückzubringen. Eine Barauszahlung ist nicht möglich. Du kannst jedoch eine andere Prämie mitnehmen, wenn diese verfügbar ist und du genügend Punkte gesammelt hast."
            },
            {
                "question": "Was mache ich, wenn eine Prämie defekt oder beschädigt ist?",
                "answere": "Im Falle einer Reklamation betreffend ein oder mehrere Artikeln, bitten wir dich uns unter punktefieber@mohrenbrauerei.at  zu kontaktieren. Sollte es sich um eine Fehlproduktion oder Beschädigung handeln, tauschen wir gerne die Ware innerhalb der ersten 14 Tage nach Abholung deiner Prämie um."
            },
            {
                "question": "Wo sehe ich, wenn eine vergriffene Prämie wieder erhältlich ist?",
                "answere": "Alle aktuellen Prämien kannst du in unserem Prämienshop einsehen. Prämien, die derzeit vergriffen sind, können nicht bestellt werden. Der Bestellbereich ist dann ausgegraut und kann nicht angeklickt werden."
            }
        ]
    },
    {
        "category": "Lokalrunde",
        "questions": [
            {
                "question": "Was ist die Lokalrunde?",
                "answere": "Die Lokalrunde findet einmal in der Woche statt. Die teilnehmenden Gastronomien werden in der App, sowie auf Social Media bekannt gegeben. Beim Konsumieren eines Mohren Biers in der aktuellen Gastronomie, werden dir 15 Punkte auf deinem Konto gutgeschrieben. Via Geo-Tracking kann dein Standort ermittelt werden. Die Punkte können nur direkt im Lokal eingelöst werden – Standortcheck via Geotracking."
            },
            {
                "question": "Muss ich ein Mohren Bier konsumieren?",
                "answere": "Ja. Der Gastronom übergibt dir mit der Rechnung einen Code, den du dann einmalig einlösen kannst"
            }
        ]
    },
    {
        "category": "Pfiffikuss",
        "questions": [
            {
                "question": "Was ist das Pfiffikuss?",
                "answere": "Das Pfiffikuss ist ein Quiz, bei dem du wöchentlich spannende Fragen über die Mohrenbrauerei und ihre Produkte beantworten kannst. Hier sammelst du zusätzliche Punkte."
            },
            {
                "question": "Wie oft kann ich beim Pfiffkuss mitspielen?",
                "answere": "Einmal in der Woche gibt es eine neue Frage, die du einmalig beantworten kannst."
            },
            {
                "question": "Wie viele Punkte bekomme ich pro richtige Antwort?",
                "answere": "Es werden dir für jede richtige Antwort 5 Punkte gutgeschrieben."
            }
        ]
    },
    {
        "category": "Mohren Pfiff-Events",
        "questions": [
            {
                "question": "Was sind Mohren-Pfiff-Event?",
                "answere": "Das sind Veranstaltungen, auf denen du durch deine Anwesenheit Punkte sammeln kannst. Einfach Punktecode auf der Veranstaltung finden und eingeben. Via Geo-Tracking ermitteln wir den Standort und schreiben dir dann automatisch Punkte gut."
            },
            {
                "question": "Wie oft kann ich ein Mohren-Pfiff-Event besuchen?",
                "answere": "Du kannst jede Veranstaltung sooft besuchen wie du möchtest, es werden dir jedoch nur einmal pro Veranstaltung Punkte gutgeschrieben."
            },
            {
                "question": "Wo erfahre ich welche Mohren Pfiff-Events stattfinden?",
                "answere": "Wir informieren dich auf ww.mohren.app und auf Social Media über die aktuellen Events."
            },
            {
                "question": "Leaderboard",
                "answere": "Unter Profil > Rangliste siehst du, wo du im Gesamtranking stehst. Hier zählen alle jemals gesammelten Punkte, nicht dein aktueller Punktestand. Es gibt eine Gesamtrangliste und einzelne Ranglisten der verschiedenen Gemeinden in Vorarlberg."
            }
        ]
    }
]

class FAQ extends Component<{}, {loggedin: boolean}> {
    constructor(props) {
        super(props);
        this.state = {
            loggedin: false
        }
    }
    
    componentWillMount() {
        window.scrollTo(0, 0);

        let token = window.localStorage.getItem('token');
        if(token && token.length > 0 && token != null && token != "null") {
            this.setState({loggedin: true});
        }
    }

    render() {
        return (
            <div className="faq">
                <TitleHeaderGreen title="FAQs" subtitle="Hier finden Sie Antworten für häufig gestellte Fragen." />
                <div className="faq-title">Hier die meistgestellten Fragen zu Mohren-Punktefieber?</div>
                <div className="faq-subtitle">Wir haben deine Antworten! Auf der folgenden Seite haben wir versucht immer wiederkehrende Fragen für dich aufzubereiten. Zu verschiedenen Themen kannst du kurze und schnelle Infos finden.<br /><br />Für alle anderen Fragen zum Gewinnspiel kontaktiere uns unter <a href="mailto:punktefieber@mohrenbrauerei.at" className="link">punktefieber@mohrenbrauerei.at</a> - wir werden uns umgehend mit dir in Verbindung setzen.</div>

                {faq.map((item: any, key) => {
                    return (
                        <div key={key} style={{ padding: 20 }}>
                            <div className="faq-question-title">
                                {item.category}
                            </div>
                            {item.questions.map((item, key) => {
                                return (
                                    <div className="textslide-seperator">
                                        <TextSlideDown item={item} key={key} />
                                    </div>
                                )
                            })}
                            <div className="textslide-seperator"></div>
                        </div>
                    )
                })}
                <DesktopFooter></DesktopFooter>

                {this.state.loggedin && <Header title="Pfiff-Shop" withIconAndBack={ true } backUrl={"/profil"}></Header>}
                {!this.state.loggedin && <Header title="Pfiff-Shop" logoOnly={ true }></Header>}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FAQ);