import React, { Component } from "react";
import Slider from "react-slick";
import ShopCardFullWidth from "../shop-card-full-width/ShopCardFullWidth";

class TicketPriceCarousel extends Component<{ items: any[], cardStyle?: any }, {}> {
    render() {
        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                  }
                }
            ]
        };
        const self = this;
        return (
            <Slider {...settings}>
                {
                    this.props.items && this.props.items.map(function (item, index) {
                        return (
                            <div key={ index } className="shop-item-overlapping-card">
                                <ShopCardFullWidth item={item} style={self.props.cardStyle}/>
                            </div>
                        );
                    })
                }
            </Slider>
        );
    }
}

export default TicketPriceCarousel;