
import React, { Component } from 'react';
import './TemplateTutorialPage.css';
import { connect } from 'react-redux';

import { push } from 'connected-react-router';
import FullWidthButton from '../../../components/full-width-button/Full-Width-Button';
import Bubble from '../../../components/Bubble/Bubble';
import AuthGuard from '../../../services/UserAuth.service';
import { userWatchedTutorial } from '../../../redux/actions/user.action';
import Confetti from 'react-dom-confetti';

class TemplateTutorialPage extends Component<{ home: any, onNext: any, checkAuth: any, closeModal: any; userSkippedTutorial: any; btnTitle?: string, btnFnc?: any, forward: any, btnDisabled?: boolean, index: number, top: any, title: string, subtitle: string, forwardUrl: string, backUrl: string, hideTutorial?: boolean }, {}> {

    componentDidMount() {
        this.props.checkAuth();
    }
    constructor(props: any) {
        super(props);
        this.left.bind(this);
        this.right.bind(this);
    }

    left(position, event, next) {
        if (next) {
            next();
        }
    }
    right(position, event, next) {
        if (next) {
            next();
        }

    }

    renderContent() {
        let btnText = "Weiter";
        if (this.props.btnTitle) {
            btnText = this.props.btnTitle;
        }
        return (<div style={{ maxWidth: '500px', width: '100%' }}>
            <div className="template-top" style={{ marginLeft: 15, marginRight: 15 }}>
                {this.props.top}
            </div>
            <div className="template-bottom">
                <div className="template-title-container">
                    <div className="template-title">{this.props.title}</div>
                    <div className="template-subtitle">{this.props.subtitle}</div>
                </div>
                <Bubble amount={5} current={this.props.index} />
                <div className="template-btn-container" onClick={() => {
                    if (this.props.hideTutorial) {
                        this.props.userSkippedTutorial();
                        this.props.closeModal();
                    }
                    this.props.onNext()
                }}>
                    <div style={{ marginLeft: 34, marginRight: 34, width: '100%' }}>
                        {!this.props.btnDisabled && !this.props.btnFnc && <FullWidthButton label={btnText} backgroundcolor="#ffd42e" fontcolor="#1a1a1a" />}
                        {this.props.btnDisabled && !this.props.btnFnc && <FullWidthButton label={btnText} backgroundcolor="rgba(255,255,255,0.5)" fontcolor="rgba(26, 26, 26, 0.2)" />}
                        {!this.props.btnDisabled && this.props.btnFnc && <FullWidthButton action={() => this.props.btnFnc} label={btnText} backgroundcolor="#ffd42e" fontcolor="#1a1a1a" />}
                        {this.props.btnDisabled && this.props.btnFnc && <FullWidthButton action={() => this.props.btnFnc} label={btnText} backgroundcolor="rgba(255,255,255,0.5)" fontcolor="rgba(26, 26, 26, 0.2)" />}
                    </div>
                </div>
                <div className="templatetutorial-cancle" onClick={() => { this.props.closeModal(); }}>Abbrechen</div>
            </div>
        </div>
        )
    }
    renderBrowserContent() {
        let btnText = "Weiter";
        if (this.props.btnTitle) {
            btnText = this.props.btnTitle;
        }
        return (<div style={{ maxWidth: '500px', width: '100%', maxHeight: 600, height: window.innerHeight > 600 ? window.innerHeight * 0.75 : window.innerHeight * 0.95, position: 'relative' }}>
            <div className="template-top" style={{ marginLeft: 34, marginRight: 34 }}>
                {this.props.top}
            </div>
            <div className="template-bottom">
                <div className="template-title-container">
                    <div className="template-title">{this.props.title}</div>
                    <div className="template-subtitle">{this.props.subtitle}</div>
                </div>
                <div style={{ position: 'absolute', bottom: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Bubble amount={5} current={this.props.index} />
                    <div className="template-btn-container" onClick={() => {
                        if (this.props.hideTutorial) {
                            this.props.userSkippedTutorial();
                            this.props.closeModal();
                        }
                        this.props.onNext()
                    }}>
                        <div style={{ marginLeft: 34, marginRight: 34, width: '100%' }}>
                            {!this.props.btnDisabled && !this.props.btnFnc && <FullWidthButton label={btnText} backgroundcolor="#ffd42e" fontcolor="#1a1a1a" />}
                            {this.props.btnDisabled && !this.props.btnFnc && <FullWidthButton label={btnText} backgroundcolor="rgba(255,255,255,0.5)" fontcolor="rgba(26, 26, 26, 0.2)" />}
                            {!this.props.btnDisabled && this.props.btnFnc && <FullWidthButton action={() => this.props.btnFnc} label={btnText} backgroundcolor="#ffd42e" fontcolor="#1a1a1a" />}
                            {this.props.btnDisabled && this.props.btnFnc && <FullWidthButton action={() => this.props.btnFnc} label={btnText} backgroundcolor="rgba(255,255,255,0.5)" fontcolor="rgba(26, 26, 26, 0.2)" />}
                        </div>
                    </div>
                    <div className="templatetutorial-cancle" onClick={() => { 
                        this.props.closeModal();
                         }}>Abbrechen</div>
                </div>
            </div>
        </div>
        )
    }
    renderSwiper() {
        return (
            <div>
                {this.renderBrowserContent()}
            </div>
        )
    }

    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', overflow: 'hidden', height: '100%' }}>
                {this.renderSwiper()}
                <Confetti active={this.props.index == 4} config={{
                    angle: 90,
                    spread: 45,
                    startVelocity: 25,
                    elementCount: 100,
                    dragFriction: 0.04,
                    duration: 3000,
                    delay: 0,
                    width: "10px",
                    height: "10px",
                    colors: ["#ffd42e"],
                    zIndex: 99
                }} />
            </div>
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
        isOpen: state.onboard.isOpen,
        username: state.user.user.name,

    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        home() {
            dispatch(push("/home"));
        },
        forward(url) {
            dispatch(push(url))
        },
        checkAuth() {
            AuthGuard(dispatch);
        },
        userSkippedTutorial() {
            dispatch(userWatchedTutorial())
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplateTutorialPage);