import { Action } from 'redux';
import { APPEND_CODE, REMOVE_CODE_SNIPPED, CODE_FOCUS, SET_CODEINPUT_VISIBILITY, CLEAR_CODE, ADD_CURRENTPOINTS, ANIMATE_POINTINPUT, START_SEND_CODE, STOP_SEND_CODE, SET_START_POINTS, START_COUNT_ANIMATION, ANIMATE_CLEAR, STOP_COUNT_ANIMATION, REDEEM_CODE_ERROR, SET_CURRENT_CODE_INDEX, CLEAR_REDEEM_CODE_ERROR, DISPLAY_MAP, SET_MAP_ERROR, RESEND_WITH_CORDS, SET_CORDS, SET_CURRENT_EVENT, MAP_LOCATION_ENABLED, CLEAR_COLLECTED_POINTS, OPEN_CODEINPUT, CURR_POINTS_ADDED, CLEAR_CURR_POINTS_ADDED, SET_CURR_POS } from '../actions/codeinput.action';
import { startEdit } from '../actions/user.action';

const code: string[] = [];
let CodeInputState = {
    loading: false,
    isInEdit: false,
    current: -1,
    code: ["", "", "", "", "", "", "", "", ""],
    codeLength: 8,
    done: false,
    isVisible: false,
    collectedPoints: 0,
    startPoints: 0,
    animate: false,
    countAmination: false,
    codeWrong: false,
    mapOpen: false,
    mapError: "",
    cords: {},
    resendWithCords: false,
    currEvent: { lat: 47, lng: 9 },
    currPosition: { lat: 0, lng: 0 },
    geolocationEnabled: false,
    codeModalOpen: false,
    currAddedPoints: 0
}

function getNewIndex(code: any) {
    for (let i = 0; i < code.length; i++) {
        if (code[i] == "") {
            return i;
        }
    }
    return code.length;
}

function checkDone(code: any) {
    for (let i = 0; i < code.length; i++) {
        if (code[i] == "") {
            return false;
        }
    }
    return true;
}

export default function codeinputreducer(state = CodeInputState, action: any) {
    if (action.type === APPEND_CODE) {
        let code = state.code;
        let input = action.code.toUpperCase();
        code[action.index] = input;
        return {
            ...state,
            code: code,
            current: getNewIndex(code),
            done: checkDone(code)
        }
    } else if (action.type === REMOVE_CODE_SNIPPED) {
        let code = state.code;

        let current = state.current - 1;
        if (current < 0) {
            current = 0;
        }
        code[current] = "";
        return {
            ...state,
            code: code,
            current: current,
            done: checkDone(code)
        }
    } else if (action.type === CODE_FOCUS) {
        let code = state.code;
        code[action.index] = "";
        return {
            ...state,
            current: action.index,
            code: code,
            done: checkDone(code)
        }
    } else if (action.type === SET_CODEINPUT_VISIBILITY) {
        return {
            ...state,
            isVisible: action.isVisible
        }
    } else if (action.type === CLEAR_CODE) {
        return {
            ...state,
            code: ["", "", "", "", "", "", "", "", ""],
            done: false
        }
    } else if (action.type === ADD_CURRENTPOINTS) {
        let newPoints = state.collectedPoints;
        newPoints += (action.count);
        return {
            ...state,
            collectedPoints: newPoints
        }
    } else if (action.type === ANIMATE_POINTINPUT) {
        return {
            ...state,
            animate: true
        }
    }
    else if (action.type === START_SEND_CODE) {
        return {
            ...state,
            loading: true
        }
    }
    else if (action.type === STOP_SEND_CODE) {
        return {
            ...state,
            loading: false
        }
    } else if (action.type === SET_START_POINTS) {
        return {
            ...state,
            startPoints: action.points
        }
    } else if (action.type === START_COUNT_ANIMATION) {
        return {
            ...state,
            countAmination: true,
            countAnimationPoints: action.points
        }
    } else if (action.type === ANIMATE_CLEAR) {
        return {
            ...state,
            animate: false
        }
    } else if (action.type === STOP_COUNT_ANIMATION) {
        return {
            ...state,
            countAmination: false
        }
    } else if (action.type === REDEEM_CODE_ERROR) {
        return {
            ...state,
            codeWrong: true
        }
    } else if (action.type === SET_CURRENT_CODE_INDEX) {
        return {
            ...state,
            current: action.index
        }
    } else if (action.type === CLEAR_REDEEM_CODE_ERROR) {
        return {
            ...state,
            codeWrong: false,
            done: false
        }
    } else if (action.type === DISPLAY_MAP) {
        return {
            ...state,
            mapOpen: action.open
        }
    } else if (action.type == SET_MAP_ERROR) {
        return {
            ...state,
            mapError: action.error
        }
    } else if (action.type == RESEND_WITH_CORDS) {
        return {
            ...state,
            resendWithCords: action.resend
        }
    } else if (action.type === SET_CORDS) {
        return {
            ...state,
            cords: action.cords
        }
    } else if (action.type === SET_CURRENT_EVENT) {
        return {
            ...state,
            currEvent: action.event
        }
    } else if (action.type === MAP_LOCATION_ENABLED) {
        return {
            ...state,
            geolocationEnabled: action.enabled
        }
    } else if (action.type === CLEAR_COLLECTED_POINTS) {
        return {
            ...state,
            collectedPoints: 0
        }
    } else if (action.type === OPEN_CODEINPUT) {
        return {
            ...state,
            codeModalOpen: action.isOpen,
            isVisible: action.isOpen
        }
    } else if (action.type === CURR_POINTS_ADDED) {
        return {
            ...state,
            currAddedPoints: action.points
        }
    } else if (action.type === CLEAR_CURR_POINTS_ADDED) {
        return {
            ...state,
            currAddedPoints: 0
        }
    } else if (action.type === SET_CURR_POS) {
        return Object.assign({}, state, { currPosition: { lat: action.lat, lng: action.lng } })
    }

    return state;
}



