import React, { Component } from 'react'
import { TitleHeaderGreen } from '../../components/title-header-green/TitleHeaderGreen';
import Header from '../../components/header/Header';
import './Events.css'
import { connect } from 'react-redux';
import EventCard from './Events-Card/EventCard';
import AuthGuard from '../../services/UserAuth.service';
import { loadEvents } from '../../redux/actions/events.action';
import { push } from 'react-router-redux';
import { setEventType } from '../../redux/actions/location.action';
import { setCurrEvent } from '../../redux/actions/codeinput.action';
import EventsCarousel from '../../components/EventsCarousel/EventsCarousel';
import DesktopFooter from '../../components/DesktopFooter/DesktopFooter';

class Events extends Component<{ checkAuth: any, specialEvent: any, lokalrunde: any, pfiffEvents: any, loadEvents: any, forwardToList: any, specialCodes: any }, {}> {
    componentWillMount() {
        this.props.checkAuth();
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        this.props.loadEvents();
    }

    render() {
        return (
            <div style={{ width: '100%', backgroundColor: '#007540' }}>
                <div className="events-overview-container">
                    <TitleHeaderGreen title="Pfiff-Events" subtitle="Mach mit bei einer Pfiff-Lokalrunde oder besuche einen Pfiff-Event, um zusätzliche Punkte zu sammeln." />
                    {this.props.specialEvent && <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <EventCard big={false} imageFullscreen={true} item={this.props.specialEvent} style={{ paddingBottom: '44%' }} />
                    </div>}
                    <div className="shop-divider" style={{ marginTop: 23, marginBottom: 12 }}></div>
                    <div style={{ paddingLeft: 18, paddingRight: 18 }}>
                        <div className="event-card-title">Lokalrunde</div>
                        { (this.props.lokalrunde && this.props.lokalrunde.length > 0) && <div className="event-card-subtitle" onClick={() => { this.props.forwardToList('local') }}>Alle anzeigen {">"}</div> }
                    </div>
                    { (this.props.lokalrunde && this.props.lokalrunde.length > 0) && <div className="shop-slider-container">
                        <EventsCarousel items={this.props.lokalrunde.slice(0, 3)} cardstyle={{ paddingBottom: '44%' }}></EventsCarousel>
                    </div> }
                    { !(this.props.lokalrunde && this.props.lokalrunde.length > 0) && <div className="no-events-at-the-moment">
                        Die Lokalrunde startet am Montag, den 6. Mai 2019.
                    </div> }
                    <div className="shop-divider" style={{ marginTop: 8, marginBottom: 12 }}></div>
                    <div style={{ paddingLeft: 18, paddingRight: 18 }}>
                        <div className="event-card-title">Pfiff-Events</div>
                        <div className="event-card-subtitle" onClick={() => { this.props.forwardToList('pfiffevent') }}>Alle anzeigen {">"}</div>
                    </div>
                    <div className="shop-slider-container">
                        <EventsCarousel items={this.props.pfiffEvents.slice(0, 3)} cardstyle={{ paddingBottom: '44%' }}></EventsCarousel>
                    </div>
                    <div className="shop-divider" style={{ marginTop: 8, marginBottom: 12 }}></div>
                    {this.props.specialCodes && this.props.specialCodes.length > 0 && <div>
                        <div style={{ paddingLeft: 18, paddingRight: 18 }}>
                            <div className="event-card-title">Sonderpunkte</div>
                            <div className="event-card-subtitle" onClick={() => { this.props.forwardToList('specialevents') }} >Alle anzeigen {">"}</div>
                        </div>
                        <div className="shop-slider-container">
                            <EventsCarousel items={this.props.specialCodes.slice(0, 3)} cardstyle={{ paddingBottom: '44%' }} hideLocation={true}></EventsCarousel>
                        </div></div>}
                    <DesktopFooter></DesktopFooter>
                </div>
                <Header withIcon={true} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        specialEvent: state.location.specialEvent,
        lokalrunde: state.location.lokalrunde,
        pfiffEvents: state.location.pfiffEvents,
        specialCodes: state.location.specialCodes
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        checkAuth() {
            AuthGuard(dispatch);
        },
        loadEvents() {
            dispatch(loadEvents());
        },
        forwardToDetails() {
            dispatch(push('/'))
        },
        forwardToList(type: string) {
            dispatch(setEventType(type));
            dispatch(push('/eventlist'));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Events);