import React, { Component } from 'react';
import './EventCard.css';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import eventdot from '../../../assets/event_dot.png';
import points from '../../../assets/15-Punkte.png';
import { setCurrentEvent } from '../../../redux/actions/location.action';
class EventCard extends Component<{ big: boolean, imageFullscreen: boolean, item: any, push: any, style?: any, format?: string, hideLocation?: boolean }, {}> {
    generateLocationDot() {
        return (
            <div className="event-location-dot">
                <img src={eventdot} className="event-location-dot-image" />
                <div className="event-location-dot-title">{this.props.item.subtitle}</div>
            </div>
        )
    }

    renderTag() {
        if (this.props.item.type == 'event') {
            return (
                <div className="event-card-tag-text">
                    Pfiff-Event
                </div>
            )
        } else if (this.props.item.type == '') { }
    }

    renderFullscreenText() {
        return (
            <div className="event-card-fullscreen">
                {this.renderTag()}
                <div className="event-card-fullscreen-text" style={{ color: this.props.item.card_color == 'white' ? 'white' : 'black' }}>{this.props.item.name}</div>
            </div>
        );
    }

    renderText() {
        return (
            <div className="event-card-fullscreen">
                <div className="event-card-text" style={{ color: this.props.item.card_color == 'white' ? 'white' : 'black' }}>{this.props.item.name}</div>
            </div>
        );
    }

    renderPoints() {
        return (
            <div className="event-location-points">
                <img src={points} className="event-location-points-image" />
            </div>
        )
    }

    renderPointsFullscreen() {

            return (
                <div className="event-location-points">
                    <img src={points} className="event-location-points-image" />
                </div>
            )

    }

    render() {
        return (
            <div className="event-card" style={this.props.style} onClick={() => {
                this.props.push("/eventdetails", this.props.item);
            }}>
                {this.props.big && <div className="event-card-big">
                    {this.props.imageFullscreen &&
                        <img src={this.props.item.preview_image} className="event-card-fullscree-image" />
                    }
                    {this.renderFullscreenText()}
                </div>}
                {!this.props.big && <div className="event-card-small">
                    {this.props.imageFullscreen && <img src={this.props.item.preview_image} className="event-card-fullscree-image" />}
                    {!this.props.imageFullscreen && <img src={this.props.item.preview_image} className="event-card-image" />}
                    {this.renderText()}
                </div>}
                {this.props.imageFullscreen && this.renderPointsFullscreen()}
                {!this.props.imageFullscreen && this.renderPoints()}
                {!this.props.hideLocation && this.generateLocationDot()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        push(route: string, event: any) {
            dispatch(setCurrentEvent(event))
            dispatch(push(route));
        }
    };
};

export default connect(
    null,
    mapDispatchToProps
)(EventCard);