import React, { Component } from 'react'
import './Full-Width-Button.css'

class FullWidthButton extends Component<{ backgroundcolor: any, fontcolor: any, label: string, action?: any, uppercase?: boolean }, {}> {
    componentDidMount() {
    }
    render() {
        if (this.props.action) {
            return (
                <button className="btn-basic" style={{ backgroundColor: this.props.backgroundcolor, color: this.props.fontcolor, textTransform: this.props.uppercase?'none': 'uppercase' }} onClick={() => { this.props.action() }}>{this.props.label}</button>
            )
        } else {
            return (
                <button className="btn-basic" style={{ backgroundColor: this.props.backgroundcolor, color: this.props.fontcolor }}>{this.props.label}</button>
            )
        }
    }
}

export default FullWidthButton; 