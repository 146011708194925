import React, { Component } from 'react';
import { connect } from 'react-redux';
import './EmailRegistration.css';

import Header from '../../components/header/Header';

class EmailRegistrationDone extends Component <{ emailForSignup: any },{ }> {
    render() {
        return (
            <div className="register-container">
                <div className="content">
                    <div className="register-container-form">
                        <div className="headertitle">Fast geschafft!</div>
                        <div className="headersubtitle">Checke bitte deine Mails. In Kürze senden wir einen Aktivierungslink für dein Benutzerkonto an { this.props.emailForSignup }. Hier gibt es inzwischen nichts mehr für dich zu tun.<br />—Deine Mohren Crew</div>
                        <div className="btn-emaillogin">
                            <a href="/">Weiter</a>
                        </div>
                        <div className="gifgif">
                            <img src="https://media.giphy.com/media/SEre9eirTBgdO/giphy.gif" />
                        </div>
                    </div>
                </div>
                <Header logoOnly={true} />
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        emailForSignup: state.user.emailForSignup
    };
};

export default connect(
    mapStateToProps,
    null
)(EmailRegistrationDone);