export const LOAD_LEADERBOARD = 'LOAD_LEADERBOARD';
export const loadLeaderBoard = () => {
    return {
        type: LOAD_LEADERBOARD
    }
}

export const DONE_LEADERBOARD = 'DONE_LEADERBOARD';
export const doneLoadingLeaderboard = () => {
    return {
        type: DONE_LEADERBOARD
    }
}

export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const setScrollPosition = (offset: number) => {
    return {
        type: SET_SCROLL_POSITION,
        offset
    }
}

export const UPDATE_LEADERBOARD = 'UPDATE_LEADERBOARD';
export const updateLeaderboard = () => {
    return {
        type: UPDATE_LEADERBOARD
    }
}