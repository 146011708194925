import { Action } from 'redux';
import { SET_SELECTED_ITEM, SET_TICKET_PRICES, LOAD_ITEMS_START, LOAD_ITEMS_STOP, SET_SHOP_LIST_TYPE, SET_INSTANT_PRICES, SET_ITEM_CODE, REMOVE_ITEM_CODE, SET_FEATURED_TICKET } from '../actions/shop.action';

let ShopState = {
    loading: false,
    isInEdit: false,
    featuredTicketPrice: {},
    instantPrices: [],
    ticketPrice: [],
    currentItem: {},
    shoplistType: "",
    itemcode: ""
}

export default function shopreducer(state = ShopState, action: any) {
    if (action.type === SET_SELECTED_ITEM) {
        state = { ...state, currentItem: action.item };
        return state;
    } else if (action.type === SET_FEATURED_TICKET) {
        state = { ...state, featuredTicketPrice: action.items };
        return state;
    } else if (action.type === SET_TICKET_PRICES) {
        state = { ...state, ticketPrice: action.items };
        return state;
    } else if (action.type === LOAD_ITEMS_START) {
        state = { ...state, loading: true };
        return state;
    } else if (action.type === LOAD_ITEMS_STOP) {
        return Object.assign({}, state, {
            loading: false
        });
    } else if (action.type === SET_SHOP_LIST_TYPE) {
        state = { ...state, shoplistType: action.itemtype };
        return state;
    } else if (action.type === SET_INSTANT_PRICES) {
        state = { ...state, instantPrices: action.items };
        return state;
    } else if (action.type === SET_ITEM_CODE) {
        return {
            ...state,
            itemcode: action.code
        }
    } else if (action.type === REMOVE_ITEM_CODE) {
        return {
            ...state,
            itemcode: ''
        }
    }
    return state;
}



