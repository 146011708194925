import { getHome } from "../../services/HttpHelper.service";

export const SET_ITEMS = 'SET_ITEMS';
export const setItems = (items: any) => {
    return {
        type: SET_ITEMS,
        items
    }
}

export const loadItems = () => {
    return (dispatch: any) => {
        getHome().then((resp) => {
            dispatch(setItems(resp.data));
        }).catch((err) => {})
    }
}
