import { Action } from 'redux';
import { LOAD_LEADERBOARD, DONE_LEADERBOARD, SET_SCROLL_POSITION, UPDATE_LEADERBOARD } from '../actions/leaderboard.action';

let LeaderBoardState = {
    loading: false,
    isInEdit: false,
    users: [
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" },
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" },
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" },
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" },
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" },
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" },
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" },
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" },
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" },
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" },
        { "name": "Peter", "score": 12345, "img": "https://static.doomworld.com/monthly_2017_10/bonerhat_original.jpg.536ab75b493a92c7c2fa688718256a7b.jpg" }

    ],
    scrollPosition: 0
}

export default function leaderboardreducer(state = LeaderBoardState, action: any) {
    if (action.type === LOAD_LEADERBOARD) {
        return {
            ...state,
            loading: true
        }
    } else if (action.type === DONE_LEADERBOARD) {
        return {
            ...state,
            loading: false
        }
    } else if (action.type === SET_SCROLL_POSITION) {
        return {
            ...state,
            scrollPosition: action.offset
        }
    } else if (action.type === UPDATE_LEADERBOARD) {
        return {
            ...state,
            scrollPosition: state.scrollPosition + 1
        }
    }
    return state;
}



