import React, { Component } from 'react'
import Modal from 'react-modal';
import './OnBoarding.css';
import FullWidthButton from '../../components/full-width-button/Full-Width-Button';
import cross from '../../assets/cross.png';
import { connect } from 'react-redux';
import { closeModal } from '../../redux/actions/onboard.action';
import { push } from 'connected-react-router';
import { userWatchedTutorial, isTutorial, disableTutorial } from '../../redux/actions/user.action';
import backgroundImg from '../../assets/onboarding.png';
import AuthGuard from '../../services/UserAuth.service';
import Overlay from '../../components/Overlay/Overlay';
import CollectPoints from './CollectPoints/CollectPoints';
import QuizEvent from './QuizEvent/QuizEvent';
import QuizRedeem from './QuizRedeem/QuizRedeem';
import OnBoardRanking from './OnBoardRanking/OnBoardRanking';
import OnBoardDone from './OnBoardDone/OnBoardDone';
import disableScroll from 'disable-scroll';

const customStyles = {
    content: {
        backgroundColor: 'transparent',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.6)',
        overflow: 'hidden',
    }
};
class OnBoarding extends Component<{ checkAuth: any, closeModal: any, isOpen: boolean, username: string, forward: any, userSkippedTutorial: any }, { currIndex: number }> {
    componentDidMount() {
        this.props.checkAuth();
        if (this.props.isOpen) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"     
        }

    }

    constructor(props: any) {
        super(props);
        this.state = {
            currIndex: 0
        }
        this.next.bind(this);
    }

    next() {
        this.setState({ currIndex: this.state.currIndex + 1 });
    }

    close() {
        document.body.style.overflow = "auto"
        this.props.closeModal()

    }

    render() {
        if(this.props.isOpen){
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
        return (
            <Modal
                isOpen={this.props.isOpen}
                style={customStyles}
                contentLabel="OnBoarding"
            >
                 { this.state.currIndex == 0 && <Overlay isOpen={this.state.currIndex == 0}
                    animation={true}
                    component={
                        <div style={{
                            backgroundColor: '#007540',
                            position: 'relative',
                            borderRadius: 8,
                            overflow: 'hidden',
                            height: '100%',
                            maxHeight: 500,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <div>
                                <div className="modal-content">
                                    <div style={{ margin: 13, marginTop: 50, marginBottom: 50, width: '100%', position: 'relative', maxHeight: 500 }}>
                                        <div className="onboard-center">
                                            <img src={backgroundImg} className="onboard-center-img" />
                                        </div>
                                        <div className="modal-center">

                                            <div className="modal-title">Willkommen </div>
                                            <div className="modal-title">{this.props.username}</div>
                                            <div className="modal-desc-text">Wie wärs mit einer Einführung, damit du einen Überblick über alles bekommen?</div>
                                        </div>
                                    </div>
                                    <div className="onboard-footer">
                                        <div className="onboard-footer-btn"><FullWidthButton backgroundcolor="#ffd42e" fontcolor="#1a1a1a" label="Einführung Starten" action={() => { this.next() }} uppercase={true} /></div>
                                        <div className="modal-skip-button" onClick={() => { this.close(); this.props.userSkippedTutorial() }}>Überspringen</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }></Overlay>}
                { this.state.currIndex == 1 && <Overlay isOpen={this.state.currIndex == 1} component={
                    <div style={{
                        backgroundColor: '#007540',
                        position: 'relative',
                        borderRadius: 8,
                        overflow: 'hidden',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <CollectPoints index={this.state.currIndex} onNext={() => { this.next() }} close={() => this.close()} />
                    </div>
                }></Overlay>}
                { this.state.currIndex == 2&&  <Overlay isOpen={this.state.currIndex == 2} component={
                    <div style={{
                        backgroundColor: '#007540',
                        position: 'relative',
                        borderRadius: 8,
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <QuizEvent index={this.state.currIndex} onNext={() => { this.next() }} close={() => this.close()} />
                    </div>
                }></Overlay>}
                { this.state.currIndex == 3 && <Overlay isOpen={this.state.currIndex == 3} component={
                    <div style={{
                        backgroundColor: '#007540',
                        position: 'relative',
                        borderRadius: 8,
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <QuizRedeem index={this.state.currIndex} onNext={() => { this.next() }} close={() => this.close()} />
                    </div>
                }></Overlay>}
                { this.state.currIndex == 4 && <Overlay isOpen={this.state.currIndex == 4} component={
                    <div style={{
                        backgroundColor: '#007540',
                        position: 'relative',
                        borderRadius: 8,
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <OnBoardRanking index={this.state.currIndex} onNext={() => { this.next() }} close={() => this.close()} />
                    </div>
                }></Overlay>}
                { this.state.currIndex == 5 && <Overlay isOpen={this.state.currIndex == 5} component={
                    <div style={{
                        backgroundColor: '#007540',
                        position: 'relative',
                        borderRadius: 8,
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <OnBoardDone index={this.state.currIndex} onNext={() => { this.props.closeModal() }} close={() => this.props.closeModal()} />
                    </div>
                }></Overlay>}

            </Modal>
        )
    }
}


const mapStateToProps = (state: any) => {
    if (!state.user.user) {
        return {
            isOpen: false,
            username: ""
        }
    }

    return {
        isOpen: state.onboard.isOpen,
        username: state.user.user.name
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeModal() {
            dispatch(disableTutorial());
            dispatch(closeModal());
        },
        forward() {
            dispatch(isTutorial());
            dispatch(push('/collectpoints'))
        },
        userSkippedTutorial() {
           
            dispatch(disableTutorial());
        },
        checkAuth() {
            AuthGuard(dispatch);
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnBoarding);