import { push } from "react-router-redux";
import { loginToServer, httpUpdateUser, updateUserImage, getLeaderBoardByFilter, getMe, emailLoginToServer, emailRegisterToServer, emailVerifyToServer, forgotMyPassword } from "../../services/HttpHelper.service";
import { START_SEND_CODE } from "./codeinput.action";
import { goBack } from "connected-react-router";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
const localstorage = window.localStorage;

export const UPDATE_USER_LOCAL = 'UPDATE_USER_LOCAL';
export const updateUserLocal = (user: any) => {
    return {
        type: UPDATE_USER_LOCAL,
        user
    }
}

export const START_EDIT = 'START_EDIT';
export const startEdit = () => {
    return {
        type: START_EDIT
    }
}

export const ANIMATE_POINTS = 'ANIMATE_POINTS';
export const animatePoints = () => {
    return {
        type: ANIMATE_POINTS
    }
}

export const USER_WATCHED_TUTORIAL = 'USER_WATCHED_TUTORIAL';
export const userWatchedTutorial = () => {
    return {
        type: USER_WATCHED_TUTORIAL
    }
}

export const STOP_EDIT = 'STOP_EDIT';
export const stopEdit = () => {
    return {
        type: STOP_EDIT
    }
}

export const SET_PLZ = 'SET_PLZ';
export const setPLZ = (plz: string) => {
    return {
        type: SET_PLZ,
        plz
    }
}

export const SET_NAME = 'SET_NAME';
export const setName = (name: string) => {
    return {
        type: SET_NAME,
        name
    }
}

export const SET_EMAIL = 'SET_EMAIL';
export const setEmail = (email: string) => {
    return {
        type: SET_EMAIL,
        email
    }
}


export const SET_PHONENUMBER = 'SET_PHONENUMBER';
export const setPhonenumber = (phonenumber: string) => {
    return {
        type: SET_PHONENUMBER,
        phonenumber
    }
}

export const ADD_POINTS = 'ADD_POINTS';
export const addPoints = (points: number) => {
    return {
        type: ADD_POINTS,
        points
    }
}


export const SET_USER = 'SET_USER';
export const setUser = (user: any) => {
    return {
        type: SET_USER,
        user
    }
}

export const REMOVE_USER = 'REMOVE_USER';
export const removeUser = () => {
    return {
        type: REMOVE_USER
    }
}

export const SET_AVATAR = 'SET_AVATAR';
export const setAvatar = (avatar: string) => {
    return {
        type: SET_AVATAR,
        avatar
    }
}

export const START_LOGIN = 'START_LOGIN';
export const startLogin = () => {
    return {
        type: START_LOGIN
    }
}

export const LOGIN_DONE = 'LOGIN_DONE';
export const stopLogin = () => {
    return {
        type: LOGIN_DONE
    }
}

export const CHECK_AGB = 'CHECK_AGB';
export const checkAGB = () => {
    return {
        type: CHECK_AGB
    }
}
export const UPDATE_POINTS = 'UPDATE_POINTS';
export const updatePoints = (points: any) => {
    return {
        type: UPDATE_POINTS,
        points
    }
}

export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE';
export const updateUserProfilImage = (image: any) => {
    return {
        type: UPDATE_USER_IMAGE,
        image
    }
}


export const SET_LEADERBOARD = 'SET_LEADERBOARD';
export const setLeaderBoard = (leaderboard: any) => {
    return {
        type: SET_LEADERBOARD,
        leaderboard
    }
}
export const IS_TUTORIAL = 'IS_TUTORIAL';
export const isTutorial = () => {
    return {
        type: IS_TUTORIAL
    }
}
export const IS_NOT_TUTORIAL = 'IS_NOT_TUTORIAL';
export const isNotTutorial = () => {
    return {
        type: IS_NOT_TUTORIAL
    }
}

export const SET_EMAIL_SIGNUP = 'SET_EMAIL_SIGNUP';
export const setEmailSignup = (email: string) => {
    return {
        type: SET_EMAIL_SIGNUP,
        email
    }
}

export const SET_ERROR = 'SET_ERROR';
export const setError = (error: string) => {
    return {
        type: SET_ERROR,
        error
    }
}

export const facebooklogin = (route: any) => {
    return (dispatch: any) => {
        dispatch(push("/facebooklogin"))
    }
}

export const emailRegister = (email: string, password: string, enable_ranking: boolean) => {
    return (dispatch: any) => {
        dispatch(startLogin());
        dispatch(setEmailSignup(email));
        emailRegisterToServer(email, password, enable_ranking).then((resp) => {
            dispatch(stopLogin());
            dispatch(push('/emailregistrationdone'));
        }).catch((err) => {
            dispatch(stopLogin());
            Swal.fire({
                text: 'Die angegebene Email-Adresse wird bereits verwendet!',
                type: 'error',
                confirmButtonColor: '#ffd42e',
                confirmButtonText: 'Ok'
            });
        })
    }
}

export const forgotPassword = (email: string) => {
    return (dispatch: any) => {
        dispatch(startLogin());
        forgotMyPassword(email).then((resp) => {
            dispatch(stopLogin());
            Swal.fire({
                text: 'Wir haben dir soeben ein E-Mail mit weiteren Anweisungen zum Zurücksetzen des Passworts geschickt.',
                type: 'error',
                confirmButtonColor: '#ffd42e',
                confirmButtonText: 'Ok'
            });
            dispatch(push('/emaillogin'));
        }).catch((err) => {
            dispatch(stopLogin());
            Swal.fire({
                text: 'Es ist ein Fehler aufgetreten!',
                type: 'error',
                confirmButtonColor: '#ffd42e',
                confirmButtonText: 'Ok'
            });
        })
    }
}

export const emailLogin = (email: string, password: string) => {
    return (dispatch: any) => {
        dispatch(startLogin());
        emailLoginToServer(email, password).then((resp) => {
            window.localStorage.setItem("token", resp.data.token);
            dispatch(setUser(resp.data));
            if (resp.data.first_login) {
                dispatch(push('/username'));
                dispatch(stopLogin())
            } else {
                dispatch(updateUserLocal(resp.data.user));
                if (resp.data.user.first_login) {
                    dispatch(push('/accountsetup'))
                    dispatch(stopLogin())
                } else {
                    dispatch(push('/home'));
                    dispatch(stopLogin())
                }

            }
        }).catch((err) => {
            dispatch(stopLogin());
            Swal.fire({
                text: 'Deine Email oder dein Passwort sind inkorrekt!',
                type: 'error',
                confirmButtonColor: '#ffd42e',
                confirmButtonText: 'Ok'
            });
        });
    }
}

export const emailVerification = (token: string) => {
    return (dispatch: any) => {
        emailVerifyToServer(token).then((resp) => {
            dispatch(push('/emaillogin'));
            dispatch(stopLogin());
        }).catch((err) => {
            dispatch(stopLogin());
            alert("Dein Aktivierungslink ist leider abgelaufen.");
            dispatch(push('/'));
        });
    }
}

export const login = (token: any, authmethod: string) => {
    return (dispatch: any) => {
        loginToServer(token, authmethod).then((resp) => {
            if (resp.data.token && resp.data.user) {
                window.localStorage.setItem("token", resp.data.token);
                window.localStorage.setItem("user", JSON.stringify(resp.data.user));

                dispatch(setUser(resp.data));
                if (authmethod === 'fb') {
                    if (resp.data.user.first_login) {
                        dispatch(push('/username'));
                    } else {
                        dispatch(push('/home'));
                    }
                } else {
                    dispatch(updateUserLocal(resp.data.user));
                    if (resp.data.user.first_login) {
                        dispatch(push('/accountsetup'))
                    } else {
                        dispatch(push('/home'));
                    }
                }
                dispatch(stopLogin());
            } else {

            }

        }).catch((err) => { })
    }
}

export const updateUser = (user, redirectToHome) => {
    return (dispatch: any) => {
        dispatch(startLogin())
        httpUpdateUser(user).then((resp: any) => {
            if (resp.data.id) {
                dispatch(updateUserLocal(resp.data));
                dispatch(stopLogin());
                if (redirectToHome) {
                    dispatch(push('/home'));
                } else {
                    dispatch(push('/profil'));
                }
            } else {
                dispatch(setError('Error'));
            }

        }).catch((err) => { })
    }
}

declare var FB: any;

export const logout = () => {
    return (dispatch: any) => {
        dispatch(removeUser());
        localstorage.removeItem("user");
        localstorage.removeItem("token");
        dispatch(push('/'));
    }
}

export const disableTutorial = () => {
    return (dispatch: any, getState: any) => {
        dispatch(startLogin());
        dispatch(userWatchedTutorial());
        httpUpdateUser(getState().user.user).then((result: any) => {
            if (result.data && result.data.success) {
                const url = result.data.success;
                let user = getState().user.user;
                user.image = url;
                httpUpdateUser(user).then((resp) => {
                    dispatch(updateUserLocal(resp.data));
                    if (getState().user.isTutorial) {
                        dispatch(stopLogin());
                    } else {
                        dispatch(stopLogin());
                    }
                }).catch((err) => { })

            } else {
                dispatch(setError("Anzeigename gibt es bereits."))
            }


        }).catch(err => { });
    }
}

export const uploadImage = (imageUrl: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(startLogin())
        updateUserImage(imageUrl).then((result: any) => {
            if (result.data && result.data.success) {
                const url = result.data.success;
                let user = getState().user.user;
                user.image = url;
                httpUpdateUser(user).then((resp) => {
                    dispatch(updateUserLocal(resp.data));
                    if (getState().user.isTutorial) {
                        dispatch(stopLogin());
                        // dispatch(push('/onboarddone'));
                    } else {
                        dispatch(stopLogin());
                        //   dispatch(goBack());
                    }
                }).catch((err) => { })

            } else {
                dispatch(setError("Anzeigename gibt es bereits."))
            }


        }).catch(err => { });
    }
}

export const uploadImageOnly = (imageUrl: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(startLogin())
        updateUserImage(imageUrl).then((result: any) => {
            if (result.data && result.data.success) {
                dispatch(updateUserProfilImage(result.data.success));
                dispatch(stopLogin());
            }
        }).catch(err => { });
    }
}

export const setFirstTimeLogin = (user) => {
    return (dispatch: any) => {
        user['first_login'] = false;
        httpUpdateUser(user).then((resp) => {
            dispatch(updateUserLocal(resp.data));
            dispatch(push('/home'))
        }).catch((err) => { })
    }
}

export const getLeaderBoard = (filter: string) => {
    return (dispatch: any) => {
        dispatch(startLogin());
        getLeaderBoardByFilter(filter).then((result) => {
            dispatch(setLeaderBoard(result.data));
            dispatch(stopLogin());
        }).catch(err => { });
    }
}

export const me = () => {
    return (dispatch: any) => {
        getMe().then(result => {
            dispatch(setUser(result.data));
            dispatch(updateUserLocal(result.data.user));
        }).catch(err => { });
    }
}
