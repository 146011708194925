import React, { Component } from 'react'
import './ShopDetail.css';
import Header from '../../../components/header/Header';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import FullWidthButton from '../../../components/full-width-button/Full-Width-Button';
import { push } from 'connected-react-router';
import partner from '../../../assets/shop/icon-partner.png';
import check from '../../../assets/shop/icon-check.png';
import info from '../../../assets/shop/icon-info.png';

import AuthGuard from '../../../services/UserAuth.service';
import Moment from 'react-moment';
import momentjs from 'moment';
import ticket from '../../../assets/icon-ticket.png';
import FullWidthButtonDeactivated from '../../../components/full-width-button-deactivated/Full-Width-Button-Deactivated';
import nextGray from '../../../assets/next_gray.png';
class ShopDetail extends Component<{ currentPrice: any, forward: any, checkAuth: any, points: number, home: any, navigateToLottery: any }, {}> {
    private myRef;
    constructor(props: any) {
        super(props);

    }
    componentWillMount() {
        this.props.checkAuth();
        if (!this.props.currentPrice.id) {
            this.props.home();
        }
        window.scrollTo(0, 0);

    }

    componentDidMount() {

    }

    properties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        indicators: true,
        arrows: true
    }

    calculateDaysLeft() {
        let now = new Date();

    }

    renderPriceContent() {
        if (this.props.currentPrice.type !== "instantprice") {
            return (
                <div className="shop-detail-ticket-container">
                    {(this.props.currentPrice && this.props.currentPrice.coop_name) && <div className="shop-detail-ticket-detail">
                        <img src={partner} className="shop-detail-image" style={{ width: 15, height: 18 }} />
                        <div className="shop-detail-text">Kooperation mit <a href={this.props.currentPrice.coop_link}>{this.props.currentPrice.coop_name}</a></div>
                    </div>}
                    <div className="shop-detail-ticket-detail">
                        <img src={check} className="shop-detail-image" style={{ width: 16, height: 11 }} />
                        {this.renderAvailability()}
                    </div>
                    <div className="shop-detail-ticket-detail">
                        <img src={info} className="shop-detail-image" style={{ width: 18, height: 18 }} />
                        <div className="shop-detail-text">Es wird nur ein Los erworben.
                            Die Verlosung findet am <Moment date={this.props.currentPrice.draw_date} format={'DD.MM'} /> statt.
                        </div>
                    </div>
                    <div className="shop-detail-ticket-description">
                        <div className="shop-detail-ticket-description-text">
                            {this.props.currentPrice.description && this.props.currentPrice.description.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br /></span>
                            })}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="shop-detail-ticket-container">
                    <div className="shop-detail-ticket-detail">
                        <img src={check} className="shop-detail-image" style={{ width: 16, height: 11 }} />
                        <div className="shop-detail-text">Verfügbar</div>
                    </div>
                    <div className="shop-detail-ticket-description">
                        <div className="shop-detail-ticket-description-text">
                            {this.props.currentPrice.description && this.props.currentPrice.description.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br /></span>
                            })}
                        </div>
                    </div>

                </div>
            )
        }
    }

    renderBadge() {
        if (this.props.currentPrice.type !== 'instantprice') {
            return (
                <div className="shop-detail-badge" style={{ backgroundColor: '#ffd42e' }}>
                    <div className="shop-detail-badge-text" style={{ color: '#000000' }}>Gewinnlos</div>
                </div>
            )
        } else {
            return (
                <div className="shop-detail-badge" style={{ backgroundColor: '#007540' }}>
                    <div className="shop-detail-badge-text">Sofortpreis</div>
                </div>
            )
        }

    }

    generateImages() {
        if (this.props.currentPrice.image_urls && this.props.currentPrice.image_urls.length > 0) {
            return this.props.currentPrice.image_urls.map((item, key) => {
                return <img src={item} key={key} />
            })
        } else {
            return (<div></div>)
        }
    }

    renderAvailability() {
        if (momentjs(this.props.currentPrice.start).toDate().getTime() >= new Date().getTime()) {
            return (<div className="shop-detail-text">Gewinnlose in  <Moment diff={Date()} unit="days">{this.props.currentPrice.start}</Moment> Tagen und <Moment diff={Date()} unit="hours">{this.props.currentPrice.start}</Moment> Stunden verfügbar</div>);
        }

        return (<div className="shop-detail-text">Gewinnlose noch  <Moment diff={Date()} unit="days">{this.props.currentPrice.end}</Moment> Tage verfügbar</div>);
    }

    renderBuyButton() {
        if (momentjs(this.props.currentPrice.start).toDate().getTime() >= new Date().getTime()) {
            return (<div style={{ marginLeft: 25 }}><FullWidthButton backgroundcolor="rgba(0,0,0,0.5)" fontcolor="#1a1a1a" label="Noch nicht verfügbar" /></div>);
        }

        if (this.props.points - this.props.currentPrice.cost >= 0) {
            let buyText = "Einlösen";
            if (this.props.currentPrice.type !== "instantprice") {
                buyText = "Los erwerben";
            }
            return (<div style={{ marginLeft: 25 }}><FullWidthButton backgroundcolor="#ffd42e" fontcolor="#1a1a1a" label={buyText} action={this.props.forward} /></div>);
        }

        return (<FullWidthButtonDeactivated backgroundcolor="rgba(150,150,150,0.21)" fontcolor="rgba(0,0,0,0.3)" label="Du hast nicht genügend Punkte, um diesen Artikel einzulösen." />);
    }

    renderTicketBought() {
        if (this.props.currentPrice.type === 'instantprice') {
            return (<div></div>)
        } else {
            if (this.props.currentPrice.tickets_bought <= 0) {
                return (<div></div>);
            }

            return (
                <div className="ticket-info" onClick={() => this.props.navigateToLottery()}>
                    <img src={ticket} style={{ height: 28, paddingLeft: 25 }} />
                    <div className="ticket-bought-text">Du hast bereits {this.props.currentPrice.tickets_bought} Gewinnlose für diesen Preis erworben</div>
                    <img src={nextGray} style={{ height: 28 }} />
                </div>
            )
        }
    }
    render() {
        return (
            <div className="shop-detail-container">
                <div style={{ position: 'relative', paddingTop: '90%' }}>
                    {false && <Carousel showArrows={false} showStatus={false} showThumbs={false} infiniteLoop={true} >
                        {this.generateImages()}
                    </Carousel>}
                    <img src={this.props.currentPrice.detail_image} className="shop-detail-title-image" />
                    <div className="shop-detail-bubble">
                        {this.props.currentPrice.cost}
                    </div>
                </div>
                <div className="shop-detail-title">
                    {this.props.currentPrice.name}
                </div>
                {this.renderBadge()}
                {this.renderPriceContent()}
                {this.renderTicketBought()}
                <div style={{ marginTop: 20, marginRight: 25 }}>
                    {this.renderBuyButton()}
                </div>
                <div style={{ height: 150 }}></div>
                <Header title="Pfiff-Shop" withIconAndBack={true}></Header>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentPrice: state.shop.currentItem,
        points: state.user.points
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        forward() {
            dispatch(push('/shopdetailbuy'))
        },
        checkAuth() {
            AuthGuard(dispatch);
        },
        home() {
            dispatch(push('/home'))
        },
        navigateToLottery() {
            dispatch(push('/lotteryprices'))
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopDetail);