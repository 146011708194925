
import React, { Component } from 'react';
import './OnBoardDone.css';
import { connect } from 'react-redux';
import TemplateTutorialPage from '../TemplateTutorialPage/TemplateTutorialPage';
import banner from '../../../assets/check.png';

class OnBoardDone extends Component<{ index: number, onNext: any, close: any }, {}> {
    renderTopComponent() {
        return (
            <div className="collectpoints-container">
                <img src={banner} className="collecpoints-banner" />
            </div>
        )
    }
    render() {
        return (
            <TemplateTutorialPage
                index={4} title="Du bist startklar!" subtitle="Alles klar soweit? Wenn du Fragen hast, unter Profil > FAQ findest du die wichtigsten Infos. Und jetzt: viel Erfolg beim Pfiff-Punktefieber! – Deine Mohrenbrauerei"
                forwardUrl="/redeempoints"
                backUrl=""
                top={this.renderTopComponent()}
                onNext={() => this.props.onNext()}
                closeModal={()=>{this.props.close(); document.body.style.overflow = "auto"}}
                btnFnc={()=>{this.props.close(); document.body.style.overflow = "auto"}}
                btnTitle="Fertig"
            />
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnBoardDone);