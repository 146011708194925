import React, { Component } from 'react';
import './Navbar.css';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

//import icons
import imghome from '../../assets/navbar/home.png';
import imghomeaktiv from '../../assets/navbar/home-aktiv.png';
import imgevents from '../../assets/navbar/events.png';
import imgeventsaktiv from '../../assets/navbar/event-aktiv.png';
import imgstore from '../../assets/navbar/store.png';
import imgstoreaktiv from '../../assets/navbar/store-aktiv.png';
import imgprofil from '../../assets/navbar/profil.png';
import imgprofilaktiv from '../../assets/navbar/profil-aktiv.png';
import imgcross from '../../assets/navbar/cross.png';
import { openCodeInput, focusCode, setCurrentCodeIndex } from '../../redux/actions/codeinput.action';
import { isIOS } from 'react-device-detect';

class Navbar extends Component<{ currentUser: any, currentRoute: string, push: any, openCodeInput: any }, {}> {

    checkIfIphoneX() {
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
        var ratio = window.devicePixelRatio || 1;

        // Define the users device screen dimensions 
        var screen = {
            width: window.screen.width * ratio,
            height: window.screen.height * ratio
        };

        // iPhone X Detection
        if (iOS && screen.width == 1125 && screen.height === 2436) {

            // Set a global variable now we've determined the iPhoneX is true
            return true;
        } else {
            return false;
        }
    }

    render() {
        if ((this.props.currentUser && this.props.currentUser.id) && window.localStorage.getItem('token')) {
            return (
                <div className="main-navbar" style={{ paddingBottom: this.checkIfIphoneX() ? 22 : 0 }}>
                    <div style={{ width: 600 }}>
                        <div className="navbar-container">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {this.props.currentRoute == '/home' && <div className="navbar-item-container-img" onClick={() => { this.props.push('/home') }}><img src={imghomeaktiv} className="navbar-icon" /></div>}
                                {this.props.currentRoute != '/home' && <div className="navbar-item-container-img" onClick={() => { this.props.push('/home') }}><img src={imghome} className="navbar-icon" /></div>}
                                {this.props.currentRoute == '/events' && <div className="navbar-item-container-img" onClick={() => { this.props.push('/events') }}><img src={imgeventsaktiv} className="navbar-icon" /></div>}
                                {this.props.currentRoute != '/events' && <div className="navbar-item-container-img" onClick={() => { this.props.push('/events') }}><img src={imgevents} className="navbar-icon" /></div>}
                                <div onClick={() => { this.props.openCodeInput() }} className="navbar-item-container"><div className="navbar-points"><img src={imgcross} style={{ width: 28 }} /></div></div>
                                {this.props.currentRoute == '/store' && <div className="navbar-item-container-img" onClick={() => { this.props.push('/store') }} >  <img src={imgstoreaktiv} className="navbar-icon" /></div>}
                                {this.props.currentRoute != '/store' && <div className="navbar-item-container-img" onClick={() => { this.props.push('/store') }}><img src={imgstore} className="navbar-icon" /></div>}
                                {(this.props.currentRoute == '/profil' || this.props.currentRoute == '/personalspace' || this.props.currentRoute == '/transaction' || this.props.currentRoute == '/leaderboard') && <div onClick={() => { this.props.push('/profil') }} className="navbar-item-container-img">  <img src={imgprofilaktiv} className="navbar-icon" /></div>}
                                {this.props.currentRoute != '/profil' && this.props.currentRoute != '/personalspace' && this.props.currentRoute != '/transaction' && this.props.currentRoute != '/leaderboard' && <div className="navbar-item-container-img" onClick={() => { this.props.push('/profil') }}><img src={imgprofil} className="navbar-icon" /></div>}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {this.props.currentRoute == '/home' && <div onClick={() => { this.props.push('/home') }} className="navbar-item-container"><div className="navbar-subtitle-aktiv">Home</div></div>}
                                {this.props.currentRoute != '/home' && <div onClick={() => { this.props.push('/home') }} className="navbar-item-container"><div className="navbar-subtitle">Home</div></div>}
                                {this.props.currentRoute == '/events' && <div onClick={() => { this.props.push('/events') }} className="navbar-item-container"><div className="navbar-subtitle-aktiv">Events</div></div>}
                                {this.props.currentRoute != '/events' && <div onClick={() => { this.props.push('/events') }} className="navbar-item-container"><div className="navbar-subtitle">Events</div></div>}
                                <div className="navbar-item-container" onClick={() => { this.props.openCodeInput() }}><div className="navbar-subtitle">Punkte sammeln</div></div>
                                {this.props.currentRoute == '/store' && <div onClick={() => { this.props.push('/store') }} className="navbar-item-container"><div className="navbar-subtitle-aktiv">s’Lädele</div></div>}
                                {this.props.currentRoute != '/store' && <div onClick={() => { this.props.push('/store') }} className="navbar-item-container"><div className="navbar-subtitle">s’Lädele</div></div>}
                                {(this.props.currentRoute == '/profil' || this.props.currentRoute == '/personalspace' || this.props.currentRoute == '/transaction' || this.props.currentRoute == '/leaderboard') && <div onClick={() => { this.props.push('/profil') }} className="navbar-item-container"><div className="navbar-subtitle-aktiv">Profil</div></div>}
                                {this.props.currentRoute != '/profil' && this.props.currentRoute != '/personalspace' && this.props.currentRoute != '/transaction' && this.props.currentRoute != '/leaderboard' && <div onClick={() => { this.props.push('/profil') }} className="navbar-item-container"><div className="navbar-subtitle">Profil</div></div>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<div></div>);
        }
    }
}
const mapStateToProps = (state: any) => {
    return {
        currentUser: state.user.user,
        currentRoute: state.router.location.pathname
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        push: (url: any) => {
            dispatch(push(url));
        },
        openCodeInput: () => {
            dispatch(openCodeInput(true));
            if (isIOS) {
                   
            } else {
                dispatch(setCurrentCodeIndex(0));
            }

        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navbar);