
import React, { Component } from 'react';
import './QuizEvent.css';
import { connect } from 'react-redux';
import TemplateTutorialPage from '../TemplateTutorialPage/TemplateTutorialPage';
import banner from '../../../assets/onboard2.png';

class QuizEvent extends Component<{ index: number, onNext: any, close: any }, {}> {
    componentDidMount() {
    }

    renderTopComponent() {
        return (
            <div className="collectpoints-container">
                <img src={banner} className="collecpoints-banner" />
            </div>
        )
    }
    render() {
        return (
            <TemplateTutorialPage
                index={1} title="Quiz- und Eventpunkte" subtitle="Weitere Punkte kannst du im wöchentlichen Pfiffikus-Quiz, in teilnehmenden Lokalen bei der Lokalrunde und bei Pfiff-Events ergattern."
                forwardUrl="/redeempoints"
                backUrl=""
                top={this.renderTopComponent()}
                onNext={() => this.props.onNext()}
                closeModal={()=>{this.props.close()}}
            />
        )
    }
}


const mapStateToProps = (state: any) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuizEvent);