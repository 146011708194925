import React, { Component } from 'react'
import './Shop-Detail-Confirmation.css';
import Header from '../../../components/header/Header';
import { connect } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { push } from 'connected-react-router';
import { TitleHeaderWhite } from '../../../components/title-header-white/TitleHeaderWhite';
import AuthGuard from '../../../services/UserAuth.service';
import { removeItemCode, loadItemsStop } from '../../../redux/actions/shop.action';

class ShopDetailBuy extends Component<{ currentPrice: any, forward: any, checkAuth: any, itemcode: any, home: any }, {}> {
    componentWillMount() {
        this.props.checkAuth();
        window.scrollTo(0, 0);
        if(!this.props.currentPrice.id) {
            this.props.home();
        }
    }

    render() {
        // Instant-price
        if (this.props.itemcode && this.props.itemcode !== "") {
            return (
                <div className="shop-detail-confirmation-container">
                    <TitleHeaderWhite title="Bestätigung" subtitle="Deine Punkte wurden erfolgreich eingelöst. Der Abholcode für deine Bestellung lautet wie folgt:" />
                    <div className="shop-detail-confirmation-code"><div className="shop-detail-confirmation-code-text">{this.props.itemcode}</div></div>
                    <div className="shop-detail-confirmation-text" style={{ marginTop: 34, marginBottom: 55 }}>Dieser Code wurde dir zusätzlich per E-Mail geschickt. Weitere Infos findest du in der Bestätigungsmail.</div>
                    <div className="shop-detail-buy-points-btn-yellow" onClick={() => { this.props.forward() }}>Zur Startseite</div>
                    <Header title="Pfiff-Shop" withIcon={true}></Header>
                </div>
            )
        }

        // Win-code
        return (
            <div className="shop-detail-confirmation-container">
                <TitleHeaderWhite title="Bestätigung" subtitle="Deine Punkte wurden erfolgreich eingelöst. Dein Los kannst du jederzeit in deinem Punktekonto einsehen. Bei einem Gewinn wirst du nach der Ziehung umgehend von uns informiert. Viel Glück!" />
                <div className="shop-detail-buy-points-btn-yellow" onClick={() => { this.props.forward() }}>Zur Startseite</div>
                <Header title="Pfiff-Shop" withIcon={true}></Header>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        currentPrice: state.shop.currentItem,
        itemcode: state.shop.itemcode
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        forward() {
            dispatch(removeItemCode())
            dispatch(loadItemsStop());
            dispatch(push('/home'))
           
        },
        checkAuth() {
            AuthGuard(dispatch);
        },
        home() {
            dispatch(push('/home'))
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopDetailBuy);