import React, { Component } from 'react'
import './Loading.css'
import { connect } from 'react-redux';
import Modal from 'react-modal';
import logo from '../../assets/mohren-logo.png';
import ReactLoading from 'react-loading';
import loading_green from '../../assets/mohren-loader-gruen.gif';
import loading_white from '../../assets/mohren-loader-weiss.gif';


class Loading extends Component<{ isVisible: boolean, show?: boolean, isBGWhite?: boolean }, {}> {
    private customStyles = {
        content: {
            backgroundColor: this.props.isBGWhite ? 'white' : 'rgb(0, 117, 64)',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
        }
    };
    componentDidMount() {
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isVisible || this.props.show}
                style={this.customStyles}
                contentLabel="Example Modal"
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    {!this.props.isBGWhite && <img src={loading_green} className="loading_green" />}
                    {this.props.isBGWhite && <img src={loading_white} className="loading_white" />}

                </div>

            </Modal>
        );


    }
}

const mapStateToProps = (state: any) => {
    return {
        isVisible: state.user.loading
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Loading);