import { SET_ANSWERE, SET_QUESTION_CORRECT, SET_QUESTION_DONE, CLEAR_QUESTION, SOLVE_QUESTION, LOAD_QUESTION, LOAD_QUESTION_DONE, SET_CURRENT_QUESTION, SET_CORRECT_ANSWERE } from '../actions/question.action';

let QuestionState = {
    loading: true,
    isInEdit: false,
    currentQuestion: {},
    answere: -1,
    answered: false,
    solveQuestion: false,
    done: false,
    correctAnswere: -1
}

export default function questionreducer(state = QuestionState, action: any) {
    if (action.type === SET_ANSWERE) {
        state = { ...state, answere: action.index, answered: true }
        return state;
    } else if (action.type === SET_QUESTION_CORRECT) {
        return Object.assign({}, state, { colorizeAnswere: true });
    } else if (action.type === SET_QUESTION_DONE) {
        return Object.assign({}, state, { done: true });
    } else if (action.type === CLEAR_QUESTION) {
        return Object.assign({}, state, { done: false, answere: -1, answered: false, solveQuestion: false });
    } else if (action.type === SOLVE_QUESTION) {
        return Object.assign({}, state, { solveQuestion: true });
    } else if (action.type === LOAD_QUESTION) {
        return Object.assign({}, state, { loading: true });
    } else if (action.type === LOAD_QUESTION_DONE) {
        return Object.assign({}, state, { loading: false });
    }  else if(action.type === SET_CURRENT_QUESTION) {
        return Object.assign({}, state, { currentQuestion: action.question });
    } else if(action.type === SET_CORRECT_ANSWERE) {
        return {
            ...state, 
            correctAnswere: (action.correctAnswere-1)
        }
    }
    return state;
}



