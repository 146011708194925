import React, { Component } from 'react'
import './Bubble.css'


class Bubble extends Component<{ amount: number, current: number }, {}> {
    private bubbles = new Array(this.props.amount).fill(false);
    componentDidMount() {
    }

    generateBubbles() {
        return this.bubbles.map((item, index) => {
            if (!item) {
                return (
                    <div key={index} className="bubble-inactive"></div>
                )
            } else {
                return (
                    <div key={index} className="bubble-active"></div>
                )
            }
        });
    }
    render() {
        this.bubbles[this.props.current] = true;
        return (
            <div className="bubbles-container">
                {this.generateBubbles()}
            </div>
        )
    }
}

export default Bubble;