export const SET_EVENT_TYPE = 'SET_EVENT_TYPE';
export const setEventType = (eventType: any) => {
    return {
        type: SET_EVENT_TYPE,
        eventType
    }
}

export const SET_CURR_EVENT = 'SET_CURR_EVENT';
export const setCurrentEvent = (currEvent: any) => {
    return {
        type: SET_CURR_EVENT,
        currEvent
    }
}