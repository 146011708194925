import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import usereducer from './user.reducer';
import leaderboardreducer from './leaderboard.reducer';
import transactionreducer from './transcation.reducer';
import shopreducer from './shop.reducer';
import locationreducer from './location.reducer';
import questionreducer from './question.reducer';
import onboardreducer from './onboard.reducer';
import codeinputreducer from './codeinput.reducer';
import navigationreducer from './navigation.reducer';
import homereducer from './home.reducer';

export default (history: any) => combineReducers({
    router: connectRouter(history),
    user: usereducer,
    leaderboard: leaderboardreducer,
    transaction: transactionreducer,
    shop: shopreducer,
    location: locationreducer,
    question: questionreducer,
    onboard: onboardreducer,
    codeinput: codeinputreducer,
    navigation: navigationreducer,
    home: homereducer
});