import React, { Component } from 'react'
import './ContestPrices.css';
import { connect } from 'react-redux';

import Header from '../../../components/header/Header';
import LeaderBoardEntry from '../../../components/LeaderBoardEntry/LeaderBoardEntry';
import TransaktionEntry from '../../../components/TransaktionEntry/TransaktionEntry';
import AuthGuard from '../../../services/UserAuth.service';
import Moment from 'react-moment';
import { loadMyTransaction } from '../../../redux/actions/transaction.action';
import ErrorPageTransactions from '../ErrorPageTransactions/ErrorPageTransactions';
import Loading from '../../../components/loading/Loading';
const filter = (item) => {
    return item.type == 'lotteryticket'
}


class ContestPrices extends Component<{ loading: boolean, transactions: any, checkAuth: any, loadTransactions: any }, { filter: string }> {

    componentWillMount() {
        this.props.checkAuth();
        this.props.loadTransactions();
        window.scrollTo(0, 0);
    }

    componentDidMount() {
    }
    constructor(props) {
        super(props);
        this.state = {
            filter: "Alle Transaktionen"
        }
    }
    checkDate(date1: string, date2: string) {
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);
        if (dateObj1.getDate() == dateObj2.getDate()) {
            if (dateObj1.getMonth() == dateObj2.getMonth()) {
                if (dateObj1.getFullYear() == dateObj2.getFullYear()) {
                    return false;
                }
            }
        }
        return true;
    }

    checkYesterday(date1: string, date2: string) {
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);
        if (dateObj1.getDate() + 1 == dateObj2.getDate()) {
            if (dateObj1.getMonth() == dateObj2.getMonth()) {
                if (dateObj1.getFullYear() == dateObj2.getFullYear()) {
                    return false;
                }
            }
        }
        return true;
    }

    private renderHeader(item: any) {
        return (
            <div className="transactions-title-container">
                {!this.checkDate(item.created_at, new Date().toDateString()) && <div className="transactions-title">Heute</div>}
                {!this.checkYesterday(item.created_at, new Date().toDateString()) && <div className="transactions-title">Gestern</div>}
                {this.checkDate(item.created_at, new Date().toDateString()) && this.checkYesterday(item.created_at, new Date().toDateString()) && <Moment format="DD.MM.YYYY" className="transactions-title">{item.created_at}</Moment>}
            </div>
        )
    }

    private renderTransactions() {
        let self = this;
        let lastItem = {
            created_at: ""
        };
        
        return this.props.transactions.filter(filter).map(function (item, index) {
            let showHeader = false;
            if (self.checkDate(item.created_at, lastItem.created_at)) {
                showHeader = true;
            }
            lastItem = item;
            return (
                <div key={index}>
                    {showHeader && self.renderHeader(item)}
                    <TransaktionEntry item={item} index={index + 1} />
                </div>
            )
        })

    }

    filterArray(filter: string) {
        this.setState({ filter: filter })
    }

    render() {
        return (
            <div className="transaction-space">
                {this.props.transactions.filter(filter).length == 0 && <ErrorPageTransactions />}
                <div className="transaction-content" style={{ marginBottom: 150 }}>
                    {this.renderTransactions()}
                </div>
                <Loading show={this.props.loading} isBGWhite={true} />
                <Header title="Transaktionen" withIconAndBack={true} ></Header>
            </div>
        );
    }
}


const mapStateToProps = (state: any) => {
    return {
        transactions: state.transaction.transactions,
        loading: state.transaction.loading
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        checkAuth() {
            AuthGuard(dispatch);
        },
        loadTransactions() {
            dispatch(loadMyTransaction())
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContestPrices);