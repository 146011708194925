import React, { Component } from 'react'
import { connect } from 'react-redux';
import './Question.css';
import { push } from 'react-router-redux';
import Header from '../../../components/header/Header';
import { setAnswere, setCorrect, questionDone, clearQuestion, loadCurrentQuestion } from '../../../redux/actions/question.action';
import FullWidthButton from '../../../components/full-width-button/Full-Width-Button';
import Answere from '../Answere/Answere';
import QuestionHeader from '../QuestionHeader/QuestionHeader';
import Confetti from 'react-dom-confetti';
import BounceLoader from 'react-spinners/BounceLoader';
import AuthGuard from '../../../services/UserAuth.service';
import logo from '../../../assets/mohren-logo.png';
import Loading from '../../../components/loading/Loading';

class Quiz extends Component<{ correctAnswere: number, getCurrentQuestion: any, loading: boolean, checkAuth: any, home: any, done: boolean, forward: any, question: any, setAnswere: any, answered: any, answere: number, isTimeToEvaluate: boolean, setCorrectAnswere: any, setQuestionDone: any, solveQuestion: boolean }, { isVisible: boolean }> {
    componentWillMount() {
        this.props.checkAuth();
        this.props.getCurrentQuestion();
        if (!this.props.question.question) {
            this.props.home();
        }
    }
    componentDidMount() {
        this.state = {
            isVisible: false
        }
    }

    render() {
        return (
            <div style={{}}>
                <Loading show={this.props.loading} />
                {!this.props.loading && <div><div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center' }}><Confetti active={this.props.solveQuestion && (this.props.answere == this.props.correctAnswere)} config={{
                    angle: 90,
                    spread: 45,
                    startVelocity: 25,
                    elementCount: 100,
                    dragFriction: 0.04,
                    duration: 3000,
                    delay: 0,
                    width: "10px",
                    height: "10px",
                    colors: ["#ffd42e"]
                }} /></div>
                    <div className="question-container">
                    <div>
                        <div className="question-question-header-container"><QuestionHeader />
                            {this.props.done &&
                                <div style={{ width: '80%' }}>
                                    <div style={{ marginLeft: 15, marginRight: 15 }}>
                                        <FullWidthButton label="Zur Startseite" action={() => { this.props.home() }} backgroundcolor="#ffd42e" fontcolor="#1a1a1a" />
                                    </div>
                                </div>
                            }
                        </div>
                        {!this.props.done && <div>
                            <div className="question-answeres" style={{ marginBottom: 15 }}>
                                <Answere index={0} marginDirection="right" />
                                <Answere index={1} />
                            </div>
                            <div className="question-answeres">
                                <Answere index={2} marginDirection="right" />
                                <Answere index={3} />
                            </div>
                        </div>}

                    </div>
                    </div>
                    <Header withIcon={true} />
                </div>}
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        question: state.question.currentQuestion,
        answered: state.question.answered,
        answere: state.question.answere,
        solveQuestion: state.question.solveQuestion,
        done: state.question.done,
        correctAnswere: state.question.correctAnswere,
        loading: state.question.loading
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        forward() {
            dispatch(push('/question'));
        },
        setAnswere(index: number) {
            dispatch(setAnswere(index));
        },
        setCorrectAnswere() {
            dispatch(setCorrect());
        },
        setQuestionDone() {
            dispatch(questionDone());
        },
        home() {
            dispatch(clearQuestion())
            dispatch(push('/home'))
        },
        checkAuth() {
            AuthGuard(dispatch)
        },
        getCurrentQuestion() {
            dispatch(loadCurrentQuestion())
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Quiz);