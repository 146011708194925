import React, { Component } from 'react'
import './Event-List.css';
import Header from '../../../components/header/Header';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import AuthGuard from '../../../services/UserAuth.service';
import { TitleHeaderGreen } from '../../../components/title-header-green/TitleHeaderGreen';
import EventCard from '../Events-Card/EventCard';

class EventList extends Component<{ forward: any, type: string, checkAuth: any, home: any, pfiffEvents: any, localevents: any, specialCodes: any }, {}> {
    componentWillMount() {
        this.props.checkAuth();
        window.scrollTo(0, 0);
    }
    componentDidMount() {
        window.moveTo(0, 0)
        if (this.props.type == 'none') {
            this.props.home();
        }
    }

    private renderItems() {
        if (this.props.type == "local") {
            return this.props.localevents.map((item: any, index) => {
                return (
                    <EventCard key={index} big={false} imageFullscreen={true} item={item} />
                )
            });
        }
        else if (this.props.type == "pfiffevent") {
            return this.props.pfiffEvents.map((item: any, index) => {
                return (
                    <EventCard key={index} big={false} imageFullscreen={true} item={item} />
                )
            });
        } else if (this.props.type == "specialevents") {
            return this.props.specialCodes.map((item: any, index) => {
                return (
                    <EventCard key={index} big={false} imageFullscreen={true} item={item} />
                )
            });
        }
    }
    private renderHeader() {
        if (this.props.type == "local") {
            return (
                <TitleHeaderGreen title={"Lokalrunde"} subtitle={""} />
            );
        } else if (this.props.type == "pfiffevent") {
            return (
                <TitleHeaderGreen title={"Pfiff-Events"} subtitle={""} />
            );
        } else if (this.props.type == "specialevents") {
            return (
                <TitleHeaderGreen title={"Sonderpunkte"} subtitle={""} />
            );
        }
    }

    render() {
        return (
            <div className="shop-list-container">
                {this.renderHeader()}
                <div style={{ padding: 20, paddingBottom: 100 }}>
                    {this.renderItems()}
                </div>

                <Header title="Pfiff-Shop" withIconAndBack={true} backUrl={'/events'}></Header>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        type: state.location.listType,
        localevents: state.location.lokalrunde,
        pfiffEvents: state.location.pfiffEvents,
        specialCodes: state.location.specialCodes
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        checkAuth() {
            AuthGuard(dispatch);
        },
        home() {
            dispatch(push('/home'));
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventList);