import React, { Component } from 'react';
import Checkbox from 'rc-checkbox';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import './EmailRegistration.css';

import TextInput from '../../components/TextInput/TextInput';
import headerlogo from '../../assets/mohren-logo.png';
import { emailRegister } from '../../redux/actions/user.action';
import Loading from '../../components/loading/Loading';
import Header from '../../components/header/Header';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

class EmailRegistration extends Component<{ register: any, loading: any, forwardEmailLogin: any }, {}> {
    state = {
        email: "",
        password: "",
        password_repeat: "",
        agb: false,
        enable_ranking: false
    };

    render() {
        return (
            <div className="register-container">
                <div className="content">
                    {
                        this.props.loading ?
                            <Loading />
                            :
                            <div className="register-container-form">
                                <div className="headertitle">Registrieren</div>
                                <TextInput placeholder="Email" type="email" value={this.state.email} onChange={(event: any) => {
                                    this.setState({
                                        email: event.target.value
                                    });
                                }}></TextInput>
                                <TextInput type="password" placeholder="Passwort" value={this.state.password} onChange={(event: any) => {
                                    this.setState({
                                        password: event.target.value
                                    });
                                }}></TextInput>
                                <TextInput type="password" placeholder="Passwort wiederholen" value={this.state.password_repeat} onChange={(event: any) => {
                                    this.setState({
                                        password_repeat: event.target.value
                                    });
                                }}></TextInput>
                                <div className="register-agb mb-0">
                                    <label>
                                        <Checkbox
                                            name="agb-checkbox"
                                            onChange={(e: any) => {
                                                this.setState({
                                                    agb: e.target.checked
                                                });
                                            }}
                                        /> Ich stimme den Geschäftsbedingungen und den Gewinnspielbedingungen zu.
                                    </label>
                                </div>
                                <div className="register-agb">
                                    <label>
                                        <Checkbox
                                            checked={this.state.enable_ranking}
                                            name="agb-checkbox"
                                            onChange={(e: any) => {
                                                this.setState({
                                                    enable_ranking: e.target.checked
                                                });
                                            }}
                                        /> Ich stimme zu, das mein Name bzw. Anzeigename in der öffentlichen Rangliste angezeigt wird.
                                    </label>
                                </div>
                                <div className="primary-button" onClick={() => {
                                    if (this.state.password !== this.state.password_repeat) {
                                        Swal.fire({
                                            text: 'Die Passwörter stimmen nicht überein.',
                                            type: 'error',
                                            confirmButtonColor: '#ffd42e',
                                            confirmButtonText: 'Ok'
                                        });
                                        this.setState({
                                            password_repeat: ""
                                        });
                                        return;
                                    }
                                    if (this.state.email === "") {
                                        Swal.fire({
                                            text: 'Bitte gib deine Email-Adresse ein!',
                                            type: 'error',
                                            confirmButtonColor: '#ffd42e',
                                            confirmButtonText: 'Ok'
                                        });
                                        return;
                                    }
                                    if (this.state.password === "") {
                                        Swal.fire({
                                            text: 'Bitte gib ein Passwort ein!',
                                            type: 'error',
                                            confirmButtonColor: '#ffd42e',
                                            confirmButtonText: 'Ok'
                                        });
                                        return;
                                    }
                                    if (this.state.agb === false) {
                                        Swal.fire({
                                            text: 'Bitte akzeptiere unsere AGB und Datenschutzbestimmungen!',
                                            type: 'error',
                                            confirmButtonColor: '#ffd42e',
                                            confirmButtonText: 'Ok'
                                        });
                                        return;
                                    }
                                    this.props.register(this.state.email, this.state.password, this.state.enable_ranking);
                                    this.setState({ agb: false })
                                }}>
                                    <div className="primary-button-text">Registrieren</div>
                                </div>

                                <div className="btn-emaillogin">
                                    Du bist bereits registriert?<br /><a href="#" onClick={() => { this.props.forwardEmailLogin() }}>Hier anmelden</a>
                                </div>
                            </div>
                    }
                </div>
                <Header withIconBackNoPoints={true} />
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.user.loading
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        register: (email: string, password: string, enable_ranking: boolean) => {
            dispatch(emailRegister(email, password, enable_ranking));
        },
        forwardEmailLogin() {
            dispatch(push('/emaillogin'));
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailRegistration);