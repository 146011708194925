import React, { Component } from "react";
import Slider from "react-slick";
import ShopCardFullWidth from "../shop-card-full-width/ShopCardFullWidth";
import ShopCard from "../shop-card/ShopCard";

class InstantPriceCarousel extends Component<{ items: any[] }, {}> {
    render() {
        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2.25,
                    slidesToScroll: 2
                  }
                }
            ]
        };

        return (
            <Slider {...settings}>
                {
                    this.props.items && this.props.items.map(function (item, index) {
                        return (
                            <div key={ index } className="shop-item-overlapping-card">
                                <ShopCard item={item} />
                            </div>
                        );
                    })
                }
            </Slider>
        );
    }
}

export default InstantPriceCarousel;