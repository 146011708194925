import React, { Component } from 'react'
import './CardHuge.css'
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import backgroundImage from '../../assets/tmp/verlauf.png';
import tshirt from '../../assets/tmp/bild_feuer.png';
import { setCurrentItem } from '../../redux/actions/shop.action';
import FullWidthButton from '../full-width-button/Full-Width-Button';

class CardHuge extends Component<{ title: string, subtitle: string, onClick: any, backgroundImage: any }, {}> {
    componentDidMount() {
    }
    render() {
        return (
            <div className="cardhuge-container">
                <div className="cardhuge-inner-container">
                    <img src={this.props.backgroundImage} className="cardhuge-image" />
                    <div className="cardHuge-text-container">
                        <div className="cardhuge-title">{this.props.title}</div>
                        <div className="cardhuge-subtitle">{this.props.subtitle}</div>
                        <FullWidthButton label="CODE EINGEBEN" backgroundcolor="#ffd42e" fontcolor="#1a1a1a" action={this.props.onClick}/>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {

    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        forward(item: any) {
            dispatch(setCurrentItem(item));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardHuge);