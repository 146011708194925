import React, { Component } from 'react'
import './ShopCardFullWidth.css'
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import backgroundImage from '../../assets/tmp/verlauf.png';
import tshirt from '../../assets/tmp/bild_feuer.png';
import { setCurrentItem } from '../../redux/actions/shop.action';

class ShopCardFullWidth extends Component<{ item: any, forward: any, style?:any }, {}> {

    componentDidMount() {
    }

    renderBadge() {
        if (this.props.item.type !== 'instantprice') {
            return (
                <div className="shop-detail-badge" style={{ backgroundColor: '#ffd42e', marginLeft: 0 }}>
                    <div className="shop-detail-badge-text" style={{ color: '#000000' }}>Gewinnlos</div>
                </div>
            )
        } else {
            return (
                <div className="shop-detail-badge" style={{ backgroundColor: '#007540', marginLeft: 0 }}>
                    <div className="shop-detail-badge-text">Sofortpreis</div>
                </div>
            )
        }

    }

    render() {
        let image = "";
        if (this.props.item && this.props.item.preview_image && this.props.item.preview_image) {
            image = this.props.item.preview_image;
        }
        return (
            <div className="shop-card-full-with-item" style={this.props.style} onClick={() => { this.props.forward(this.props.item) }}>
                <img src={image} className="shop-card-full-width-background" />
                <div className="shop-card-item-full-with-top-right">
                    {this.props.item.cost}
                </div>
                <div className="shop-card-full-with-item-title-container">
                    {this.renderBadge()}
                    <div className="shop-card-full-with-item-title" style={{ color: this.props.item.card_color == 'white' ? 'white' : 'black' }}>{this.props.item.name}</div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state: any) => {
    return {

    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        forward(item: any) {
            dispatch(setCurrentItem(item));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopCardFullWidth);