import React, { Component } from 'react'
import { connect } from 'react-redux';
import './Answere.css';
import { setAnswere, solveQuestion, questionDone, validateAnswere } from '../../../redux/actions/question.action';
import posed from 'react-pose';

const AnswereBox = posed.div({
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
})

class Answere extends Component<{ index: number, marginDirection?: string, question: any, validateAnswere: any, answered: boolean, answere: number, solveQuestion: boolean, correctAnswere: number }, {}> {
    componentDidMount() {

    }

    render() {
        let marginDirectionStyle = {};
        if (this.props.marginDirection == 'right') {
            marginDirectionStyle = { marginRight: 15 }
        }
        return (
            <div className="answere-container" style={marginDirectionStyle} onClick={() => { if (!this.props.answered) { this.props.validateAnswere(this.props.index) } }}>
                {!this.props.answered &&
                    <div className="answere-inner-container">

                        <div className="anwere-text">{this.props.question['answer' + (this.props.index + 1)]}</div>
                    </div>
                }
                {this.props.answered && !this.props.solveQuestion &&
                    <div style={{ width: '100%', height: '100%' }}>
                        {this.props.answere == this.props.index &&
                            <div className="answere-inner-container-selected">
                                <div className="anwere-text">{this.props.question['answer' + (this.props.index + 1)]}</div>
                            </div>
                        }
                        {this.props.answere != this.props.index &&
                            <div className="answere-inner-container" style={{ opacity: 0.5 }}>
                                <div className="anwere-text">{this.props.question['answer' + (this.props.index + 1)]}</div>
                            </div>
                        }
                    </div>
                }
                {
                    this.props.solveQuestion &&
                    <div style={{ width: '100%', height: '100%' }}>
                        {this.props.correctAnswere == this.props.index &&
                            <div className="answere-inner-container-selected">
                                <AnswereBox pose={this.props.solveQuestion ? 'visible' : 'hidden'} className="answere-correct">
                                    <div className="anwere-text-white">{this.props.question['answer' + (this.props.index + 1)]}</div>
                                </AnswereBox>
                                <div className="anwere-text">{this.props.question['answer' + (this.props.index + 1)]}</div>
                            </div>
                        }
                        {this.props.correctAnswere != this.props.index && this.props.index != this.props.answere &&
                            <div className="answere-inner-container" style={{ opacity: 0.5 }}>
                                <div className="anwere-text">{this.props.question['answer' + (this.props.index + 1)]}</div>
                            </div>
                        }
                        {this.props.correctAnswere != this.props.index && this.props.index == this.props.answere &&
                            <div className="answere-inner-container-wrong">
                                <div className="anwere-text">{this.props.question['answer' + (this.props.index + 1)]}</div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        question: state.question.currentQuestion,
        answered: state.question.answered,
        answere: state.question.answere,
        solveQuestion: state.question.solveQuestion,
        correctAnswere: state.question.correctAnswere
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        validateAnswere(index: number) {
            dispatch(validateAnswere(index));

        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Answere);