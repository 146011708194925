import React, { Component } from 'react';
import { connect } from 'react-redux';
import './EmailRegistration.css';

import headerlogo from '../../assets/mohren-logo.png';
import { emailVerification } from '../../redux/actions/user.action';
import Loading from 'react-loading';

class EmailVerification extends Component <{ emailVerification: any, loading: any, location: any },{ }> {
    componentDidMount() {
        let token = this.props.location.search;
        token = token.replace('?token=', '');
        this.props.emailVerification(token);
    }

    render() {
        return (
            <div className="register-container">
                <div className="public-header">
                    <img src={headerlogo} />
                </div>
                <div className="content">
                    <div className="register-container-form">
                        <Loading />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loading: state.user.loading
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
        emailVerification: (token: string) => {
            dispatch(emailVerification(token));
        },
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailVerification);