import { Action } from 'redux';
import { OPEN_MODAL, CLOSE_MODAL } from '../actions/onboard.action';


let OnBoardingState = {
    loading: false,
    isOpen: true
}

export default function onboardreducer(state = OnBoardingState, action: any) {
    if (action.type === OPEN_MODAL) {
        return Object.assign({}, state, { isOpen: true })
    } else if (action.type === CLOSE_MODAL) {
        return Object.assign({}, state, { isOpen: false })
    }
    return state;
}



