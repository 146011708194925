import { redeemCode, redeemCodeWithLatLng } from "../../services/HttpHelper.service";
import { addPoints, setError } from "./user.action";
declare var grecaptcha: any;

export const APPEND_CODE = 'APPEND_CODE';
export const appendCode = (code: string, index: number) => {
    return {
        type: APPEND_CODE,
        code,
        index
    }
}

export const REMOVE_CODE_SNIPPED = 'REMOVE_CODE_SNIPPED';
export const removeCodeSnipped = () => {
    return {
        type: REMOVE_CODE_SNIPPED
    }
}

export const DISPLAY_MAP = 'DISPLAY_MAP';
export const displayMap = (open: boolean) => {
    return {
        type: DISPLAY_MAP,
        open
    }
}

export const SET_CURRENT_CODE_INDEX = 'SET_CURRENT_CODE_INDEX';
export const setCurrentCodeIndex = (index) => {
    return {
        type: SET_CURRENT_CODE_INDEX,
        index
    }
}

export const CODE_FOCUS = 'CODE_FOCUS';
export const focusCode = (index: number) => {
    return {
        type: CODE_FOCUS,
        index
    }
}

export const ANIMATE_POINTINPUT = 'ANIMATE_POINTINPUT';
export const animatePointInputDone = () => {
    return {
        type: ANIMATE_POINTINPUT
    }
}

export const START_SEND_CODE = 'START_SEND_CODE';
export const startSendCode = () => {
    return {
        type: START_SEND_CODE
    }
}

export const STOP_SEND_CODE = 'STOP_SEND_CODE';
export const stopSendCode = () => {
    return {
        type: STOP_SEND_CODE
    }
}

export const SET_CODEINPUT_VISIBILITY = 'SET_CODEINPUT_VISIBILITY';
export const setVisibility = (isVisible: boolean) => {
    return {
        type: SET_CODEINPUT_VISIBILITY,
        isVisible
    }
}

export const CLEAR_CODE = 'CLEAR_CODE';
export const clearCode = () => {
    return {
        type: CLEAR_CODE
    }
}

export const ADD_CURRENTPOINTS = 'ADD_CURRENTPOINTS';
export const addCurrpoints = (count: number) => {
    return {
        type: ADD_CURRENTPOINTS,
        count
    }
}

export const ANIMATE_CLEAR = 'ANIMATE_CLEAR';
export const animateClear = () => {
    return {
        type: ANIMATE_CLEAR
    }
}


export const SET_START_POINTS = 'SET_START_POINTS';
export const setStartPoints = (points: number) => {
    return {
        type: SET_START_POINTS,
        points
    }
}

export const START_COUNT_ANIMATION = 'START_COUNT_ANIMATION';
export const startCountPointsAnimation = () => {
    return {
        type: START_COUNT_ANIMATION
    }
}
export const STOP_COUNT_ANIMATION = 'STOP_COUNT_ANIMATION';
export const stopCountPointsAnimation = () => {
    return {
        type: STOP_COUNT_ANIMATION
    }
}

export const REDEEM_CODE_ERROR = 'REDEEM_CODE_ERROR';
export const redeemCodeError = () => {
    return {
        type: REDEEM_CODE_ERROR
    }
}
export const CLEAR_REDEEM_CODE_ERROR = 'CLEAR_REDEEM_CODE_ERROR';
export const clearCodeError = () => {
    return {
        type: CLEAR_REDEEM_CODE_ERROR
    }
}
export const SET_MAP_ERROR = 'SET_MAP_ERROR';
export const setMapError = (error) => {
    return {
        type: SET_MAP_ERROR,
        error
    }
}

export const CLEAR_COLLECTED_POINTS = 'CLEAR_COLLECTED_POINTS';
export const clearCollectedPoints = () => {
    return {
        type: CLEAR_COLLECTED_POINTS
    }
}

export const SET_CORDS = 'SET_CORDS';
export const setCords = (cords) => {
    return {
        type: SET_CORDS,
        cords
    }
}

export const RESEND_WITH_CORDS = 'RESEND_WITH_CORDS';
export const resendWithCords = (resend) => {
    return {
        type: RESEND_WITH_CORDS,
        resend
    }
}
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT';
export const setCurrEvent = (event) => {
    return {
        type: SET_CURRENT_EVENT,
        event
    }
}

export const MAP_LOCATION_ENABLED = 'MAP_LOCATION_ENABLED';
export const mapLocationEnabled = (enabled) => {
    return {
        type: MAP_LOCATION_ENABLED,
        enabled
    }
}

export const OPEN_CODEINPUT = 'OPEN_CODEINPUT';
export const openCodeInput = (isOpen: boolean) => {
    return {
        type: OPEN_CODEINPUT,
        isOpen
    }
}

export const CURR_POINTS_ADDED = 'CURR_POINTS_ADDED';
export const currPointsAdded = (points: number) => {
    return {
        type: CURR_POINTS_ADDED,
        points
    }
}

export const CLEAR_CURR_POINTS_ADDED = 'CLEAR_CURR_POINTS_ADDED';
export const clearCurrPointsAdded = () => {
    return {
        type: CLEAR_CURR_POINTS_ADDED
    }
}

export const fetchCoordinates = (code: any, lat: any, lng: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(setCurrPos(lat, lng));
        dispatch(sendCode(code, {
            lat: lat,
            lng: lng
        }));
    }
}

export const SET_CURR_POS = 'SET_CURR_POS';
export const setCurrPos = (lat:number, lng: number) => {
    return {
        type: SET_CURR_POS,
        lat,
        lng
    }
}

const codeSendError = (dispatch: any) => {
    dispatch(resendWithCords(false));
    dispatch(stopSendCode());
    dispatch(redeemCodeError());
    dispatch(setCurrentCodeIndex(-1))
    setTimeout(() => {
        dispatch(clearCode())
        dispatch(setVisibility(true))
        dispatch(animateClear())
        dispatch(setCurrentCodeIndex(0))
    }, 1000)
}

const checkSendCodeResult = (result: any, dispatch: any) => {
    dispatch(stopSendCode());
    if (result.data.success == false) {
        if (result.data.type == "code_incorrect") {
            // Code not valid.
            dispatch(setMapError('code_incorrect'));
            dispatch(setCurrentCodeIndex(-1));
            setTimeout(() => {
                dispatch(setMapError(''));
                dispatch(clearCode())
                dispatch(setVisibility(true))
                dispatch(animateClear())
                dispatch(setCurrentCodeIndex(0))
                dispatch(clearCode());
            }, 1000);
        } else if (result.data.type == "recaptcha_incorrect") {
            // Code not valid.
            dispatch(setMapError('recaptcha_incorrect'));
            dispatch(setCurrentCodeIndex(-1));
            setTimeout(() => {
                dispatch(setMapError(''));
                dispatch(clearCode())
                dispatch(setVisibility(true))
                dispatch(animateClear())
                dispatch(setCurrentCodeIndex(0))
                dispatch(clearCode());
            }, 1000);
        } else if (result.data.type == "location_needed") {
            // It's a event with location checks.
            dispatch(setMapError('location_needed'));
            dispatch(resendWithCords(true));
        } else if (result.data.type == "already_checked_in") {
            // Code already used.
            dispatch(setMapError('already_checked_in'));
        } else if (result.data.type == "out_of_range") {
            // Code already used.
            dispatch(setMapError('out_of_range'));
            dispatch(setCurrEvent(result.data.event));
        } else {
            // Default error.
            codeSendError(dispatch);
        }
    } else {
        dispatch(startCountPointsAnimation());
        dispatch(addCurrpoints(result.data.points_added));
        dispatch(addPoints(result.data.points));
        dispatch(currPointsAdded(result.data.points_added));
        dispatch(setMapError('no_error'));

        setTimeout(() => {
            dispatch(setVisibility(false));
            setTimeout(() => {
                dispatch(clearCode());
                dispatch(setVisibility(true));
                dispatch(animateClear());
                dispatch(setCurrentCodeIndex(-1));
                dispatch(setMapError(''));
                dispatch(clearCurrPointsAdded());
            }, 1000);
        }, 1000);
    }
}

export const sendCode = (inputcode: any, location?: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(resendWithCords(false));

        /**
         * Get the code.
         */
        let code = "";
        for (let i = 0; i < inputcode.length; i++) {
            if (i == 2 || i == 5) {
                code += inputcode[i] + ";"
            } else {
                code += inputcode[i];
            }
        }


        grecaptcha.ready(function() {
            grecaptcha.execute('6LdDNqEUAAAAABH82phBBZchoJO0rQXVJM-SXUpk', {action: 'code_send'}).then(function(token) {
                // Check geo location.
                if (location && location.lat && location.lng) {
                    redeemCodeWithLatLng(code, location, token).then((result) => {
                        return checkSendCodeResult(result, dispatch);
                    }).catch(err => {
                        codeSendError(dispatch);
                    });
                } else {
                    /**
                     * Redeem code.
                     */
                    redeemCode(code, token).then((result) => {
                        return checkSendCodeResult(result, dispatch);
                    }).catch((err) => {
                        codeSendError(dispatch);
                    });
                }
             });
        });
    }
}