import React, { Component } from 'react'
import './LeaderBoardEntry.css'
import { connect } from 'react-redux';

//import medals
import gold from '../../assets/gold.png';
import silver from '../../assets/silver.png';
import bronze from '../../assets/bronze.png';

class LeaderBoardEntry extends Component<{ item?: any, rank: number, leaderboard: any, isMe?: boolean, me: any, scrollOffset: number }, {}> {
    componentDidMount() {
    }

    renderUser(currentUser: any) {
        return (
            <div className="user-container">
                <div className="leaderboard-index">{this.props.rank + 1}</div>
                <img src={currentUser.image} className="leaderboard-user-img" />
                <div className="leaderboard-user-name">{currentUser.name}</div>
                <div className="leaderboard-medal">
                    {this.props.rank == 0 && <img src={gold} style={{ height: 20 }} />}
                    {this.props.rank == 1 && <img src={silver} style={{ height: 20 }} />}
                    {this.props.rank == 2 && <img src={bronze} style={{ height: 20 }} />}
                    {this.props.rank > 2 && <div></div>}
                </div>
                <div className="leaderboard-user-score">{currentUser.total_points}</div>
            </div>
        )
    }

    checkStuff() {
        return (<div></div>)
    }

    render() {
        const currentUser = this.props.item;
        return (
            <div>
                {!this.props.isMe && currentUser.id != this.props.me.id &&
                    <div>
                        {this.props.rank % 2 == 0 && <div className="isEven">
                            {this.renderUser(currentUser)}
                        </div>}
                        {this.props.rank % 2 != 0 && <div>
                            {this.renderUser(currentUser)}
                        </div>}
                    </div>
                }
                {this.props.isMe &&
                    <div className="user-container" style={{ backgroundColor: '#007540', opacity: 0.9 }}>
                        <div className="leaderboard-index" style={{ color: 'white' }}>{this.props.leaderboard.rank}</div>
                        <img src={currentUser.image} className="leaderboard-user-img" />
                        <div className="leaderboard-user-name" style={{ color: 'white' }}>{currentUser.name}</div>
                        <div className="leaderboard-medal">
                            {this.props.rank == 0 && <img src={gold} style={{ height: 20 }} />}
                            {this.props.rank == 1 && <img src={silver} style={{ height: 20 }} />}
                            {this.props.rank == 2 && <img src={bronze} style={{ height: 20 }} />}
                            {this.props.rank > 2 && <div></div>}
                        </div>
                        <div className="leaderboard-user-score" style={{ color: 'white' }}>{currentUser.total_points}</div>
                    </div>}
                {!this.props.isMe && currentUser.id == this.props.me.id &&
                    <div className="user-container" style={{ backgroundColor: '#009150' }}>
                        <div className="leaderboard-index" style={{ color: 'white' }}>{this.props.rank + 1}</div>
                        <img src={currentUser.image} className="leaderboard-user-img" />
                        <div className="leaderboard-user-name" style={{ color: 'white' }}>{currentUser.name}</div>
                        <div className="leaderboard-medal">
                            {this.props.rank == 0 && <img src={gold} style={{ height: 20 }} />}
                            {this.props.rank == 1 && <img src={silver} style={{ height: 20 }} />}
                            {this.props.rank == 2 && <img src={bronze} style={{ height: 20 }} />}
                            {this.props.rank > 2 && <div></div>}
                        </div>
                        <div className="leaderboard-user-score" style={{ color: 'white' }}>{currentUser.total_points}</div>
                    </div>
                }
            </div>)
    }
}
const mapStateToProps = (state: any) => {
    return {
        leaderboard: state.user.leaderboard,
        me: state.user.user,
        scrollOffset: state.leaderboard.scrollPosition
    };
};


const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderBoardEntry);